import { Autocomplete, DefaultButton, Space } from '@Atoms';
import { acceptNumbers, stringWithOutSpace } from '@Masks';
import { PaperContainer } from '@Molecules';
import { Grid, TextField, useTheme } from '@mui/material';
import { ISocialNetworkReq, ISocialNetworkType } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CreateSocialNetworkProps {
	initialValues: ISocialNetworkReq;
	socialNetworkTypes?: ISocialNetworkType[];
	validate: (values: ISocialNetworkReq) => FormikErrors<ISocialNetworkReq>;
	handleSubmit: (values: ISocialNetworkReq, formikHelpers: FormikHelpers<ISocialNetworkReq>) => void
}

const CreateSocialNetworkPage: FC<CreateSocialNetworkProps> = (props) => {
	const {
		initialValues, socialNetworkTypes, validate, handleSubmit,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<Grid container sx={{ padding: theme.spacing(3) }} spacing={3}>
			<Grid item xs={12} md={12} xl={5} lg={5}>
				<PaperContainer>
					<Formik
						initialValues={initialValues}
						validate={validate}
						enableReinitialize
						onSubmit={handleSubmit}
					>
						{({
							values, errors, isSubmitting, setFieldValue,
						}) => (
							<Form>
								<Autocomplete
									disabled={isSubmitting}
									label={t('type')}
									placeholder=""
									defaultValue={values.typeId}
									error={Boolean(errors.typeId)}
									helperText={String(errors.typeId || '')}
									options={socialNetworkTypes || []}
									renderOption={(inputprops, item) => <li {...inputprops}>{item.name}</li>}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									getOptionLabel={(item) => item.name || ''}
									onChange={(_, item) => {
										setFieldValue('typeId', item);
									}}
								/>
								<Space height={2} />
								<TextField
									size="small"
									fullWidth
									id="userName"
									disabled={isSubmitting}
									label={t('userName')}
									multiline
									error={Boolean(errors.userName)}
									helperText={errors.userName}
									value={values.userName}
									onChange={(e) => setFieldValue('userName', stringWithOutSpace(e).target.value)}
								/>
								<Space height={2} />
								<TextField
									size="small"
									fullWidth
									id="pageId"
									disabled={isSubmitting}
									label={t('pageId')}
									multiline
									error={Boolean(errors.pageId)}
									helperText={errors.pageId}
									value={values.pageId}
									onChange={((event) => setFieldValue('pageId', stringWithOutSpace(acceptNumbers(event)).target.value))}
								/>
								<Space height={2} />
								<DefaultButton title={t('save')} type="submit" loading={isSubmitting} />
							</Form>
						)}
					</Formik>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default CreateSocialNetworkPage;
