import {
	DefaultButton, Image, Row, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Box,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
	useTheme,
} from '@mui/material';
import { IBalence } from '@Types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { profileImage } from '@Images';
import { FontSizes } from '@Constants';
import { acceptNumbers, currency } from '@Masks';
import { formatCurrency } from '@Helpers';

interface BalencePointsProps {
   userInfo?: IBalence;
   value: {value: string, type: string};
   handleChange: (value: string, key: string) => void;
   handleSubmit: () => void;
   error: string;
   loading: boolean;
   userImage?: string

}

const BalencePointsPage = (props: BalencePointsProps) => {
	const {
		userInfo, handleChange, handleSubmit, value, error, loading, userImage,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<Grid container sx={{ padding: theme.spacing(3) }} spacing={3}>
			<Grid item xs={12} md={12}>
				<Grid container>
					<Grid item xs={12} md={6}>
						<PaperContainer>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4}>
									<Box sx={{ height: 'auto', width: '90%' }}>
										<Image
											altImage={profileImage}
											src={userImage || ''}
											height="100%"
											width="100%"
											alt="user profile"
										/>
									</Box>
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography variant="h5">
										{t('user')}
										:
										{' '}
										{userInfo?.user?.name}
									</Typography>
									<Typography variant="h6" fontWeight={600}>
										{t('level')}
										:
										{' '}
										{userInfo?.level.name}
									</Typography>
									<Space height={2} />
									<Typography>
										{t('currentBalence')}
										:
										{' '}
										{formatCurrency(userInfo?.currentBalance ?? 0)}
									</Typography>
									<Typography>
										{t('balenceApproval')}
										:
										{' '}
										{formatCurrency(userInfo?.balanceOnApproval ?? 0)}
									</Typography>
									<Typography>
										{t('points')}
										:
										{' '}
										{userInfo?.score ?? 0}
									</Typography>
								</Grid>
							</Grid>
							<Space height={2} />
							<Row justify="flex-start">
								<TextField
									label={t('value')}
									disabled={loading}
									value={value.value}
									error={!!error}
									helperText={error}
									onChange={(event) => handleChange(value.type === '1' ? acceptNumbers(event).target.value : currency(event).target.value, 'value')}
								/>
								<span style={{ marginLeft: 20 }} />
								<FormControl component="fieldset" size="small" disabled={loading}>
									<RadioGroup
										aria-label="points"
										defaultValue={1}
										name="radio-buttons-group"
										onChange={(e, val) => {
											handleChange(val, 'type');
										}}
									>
										<FormControlLabel
											sx={{ fontSize: FontSizes.SM }}
											value={1}
											control={<Radio size="small" sx={{ padding: 0 }} />}
											label={t('points')}
										/>
										<FormControlLabel value={2} control={<Radio size="small" sx={{ padding: 0 }} />} label="Euros" />
									</RadioGroup>
								</FormControl>
							</Row>
							<Space height={2} />
							<DefaultButton title={t('save')} onClick={handleSubmit} loading={loading} />
						</PaperContainer>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default BalencePointsPage;
