import {
	Autocomplete, CountryItem, DefaultButton, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Divider, Grid, useTheme,
} from '@mui/material';
import { IQuestionReq } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { LANGUAGES } from '@Constants';

interface CreateQuestionProps {
   handleChange: (value: string | number, key: string) => void;
   initialValues: IQuestionReq;
   errors: IQuestionReq;
   handleSubmit: () => void;
   loading: boolean;
   edit?: boolean
}

const CreateQuestionPage = (props: CreateQuestionProps) => {
	const {
		handleChange, initialValues, errors, handleSubmit, loading, edit,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid container sx={{ padding: theme.spacing(3) }} spacing={3}>
				<Grid item xs={12} md={12} xl={5} lg={5}>
					<PaperContainer>
						<TextField
							size="small"
							fullWidth
							disabled={loading}
							label={t('question')}
							multiline
							error={Boolean(errors.question)}
							helperText={errors.question}
							value={initialValues.question}
							onChange={(event) => handleChange(event.target.value, 'question')}
						/>
						<Space height={2} />
						<TextField
							size="small"
							fullWidth
							disabled={loading}
							label={t('answer')}
							error={Boolean(errors.answer)}
							helperText={errors.answer}
							multiline
							inputProps={{ maxLength: 500 }}
							minRows={4}
							value={initialValues.answer}
							onChange={(event) => handleChange(event.target.value, 'answer')}
						/>
						<Space height={2} />
						<Autocomplete
							options={LANGUAGES}
							label={t('language')}
							placeholder={t('language')}
							error={Boolean(errors.languageId)}
							helperText={String(errors.languageId || '')}
							defaultValue={LANGUAGES.find((el) => el.id === initialValues.languageId)}
							getOptionLabel={(option) => option.name}
							renderOption={(autocompleteprops, option) => (
								<CountryItem autocompleteprops={autocompleteprops} option={option} />)}
							onChange={((_, value) => {
								handleChange(value?.id || 0, 'languageId');
							})}
						/>
						<Space height={2} />
						<Divider />
						<Space height={2} />
						<DefaultButton title={edit ? t('save') : t('create')} loading={loading} onClick={handleSubmit} />
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateQuestionPage;
