/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Autocomplete, DefaultButton, FileInput, Image, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Divider, Grid, useTheme,
} from '@mui/material';
import {
	Formik, Form, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import {
	IUserReq,
	ICountry,
	IStates,
	ICities,
} from '@Types';
import { useTranslation } from 'react-i18next';
import { phone, taxId } from '@Masks';
import { getImageURL } from '@Helpers';

export enum DROPDOWN_ACTION {
   COUNTRY = 'COUNTRY',
   STATE = 'STATE',
}

const COUNTRY_WITH_STATES = 1;

interface CreateUserProps {
   initialValues: IUserReq;
   edit?: boolean;
   countries?: ICountry[];
   states?: IStates[];
   cities?: ICities[];
   requestById: (action: string, id: number) => void;
   validateForm: (values: IUserReq) => Partial<IUserReq>;
   submitForm: (values: IUserReq, formikHelpers: FormikHelpers<IUserReq>) => void;
   addFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
   image?: File;
   checkNif: (nif: string, setFieldValue: any) => void
}

const CreateUserPage: FC<CreateUserProps> = (props) => {
	const {
		initialValues,
		countries,
		states,
		cities,
		requestById,
		validateForm,
		submitForm,
		edit,
		addFile,
		image,
		checkNif,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid container sx={{ padding: theme.spacing(3) }} spacing={2}>
				<Grid item xs={12} md={12} xl={4} lg={4}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							onSubmit={submitForm}
							validate={validateForm}
							validateOnBlur={false}
							validateOnMount={false}
							enableReinitialize
						>
							{({
								setFieldValue,
								errors,
								values,
								isSubmitting,
								handleChange,
								touched,
							}) => (
								<Form
									style={{ padding: theme.spacing(2) }}
									autoComplete="off"
								>
									<TextField
										name="name"
										label={t('name')}
										type="text"
										value={values.name}
										onChange={handleChange}
										error={Boolean(touched.name && errors.name)}
										helperText={touched.name && errors.name}
									/>
									<Space height={3} />
									<TextField
										label={t('email')}
										name="email"
										type="text"
										value={values.email}
										onChange={handleChange}
										error={Boolean(touched.email && errors.email)}
										helperText={touched.email && errors.email}
									/>
									<Space height={3} />
									<TextField
										label={t('contactNumber')}
										name="phone"
										value={values.phone}
										type="text"
										onChange={(e) => setFieldValue('phone', phone(e).target.value)}
										error={Boolean(touched.phone && errors.phone)}
										helperText={touched.phone && errors.phone}
									/>
									<Space height={3} />
									{!edit && (
										<>
											<TextField
												label={t('taxPayerNumber')}
												name="taxId"
												value={values.taxId}
												type="text"
												onBlur={() => {
													if (values.taxId.length === 10) {
														checkNif(values.taxId, setFieldValue);
													}
												}}
												onChange={(e) => {
													setFieldValue('taxId', taxId(e).target.value);
												}}
												error={Boolean(touched.taxId && errors.taxId)}
												helperText={touched.taxId && errors.taxId}
											/>
											<Space height={3} />
										</>
									)}

									<Autocomplete
										label={t('place')}
										placeholder=""
										disabled={isSubmitting}
										options={countries || []}
										error={Boolean(touched.country && errors.country)}
										helperText={touched.country && errors.country}
										getOptionLabel={(item) => item.name || ''}
										renderOption={(AutoCompleteprops, item) => (
											<li {...AutoCompleteprops}>{item.name}</li>
										)}
										defaultValue={values.country}
										isOptionEqualToValue={(item, option) => item.id === option.id}
										onChange={(_, item) => {
											setFieldValue('country', item);
											setFieldValue('state', undefined);
											setFieldValue('city', undefined);
											if (item) {
												requestById(DROPDOWN_ACTION.COUNTRY, item.id);
											}
										}}
									/>

									{values?.country?.id === COUNTRY_WITH_STATES && (
										<>
											<Space height={3} />
											<Autocomplete
												label={t('district')}
												placeholder=""
												disabled={isSubmitting}
												options={states || []}
												error={Boolean(errors.state)}
												helperText={errors.state}
												getOptionLabel={(item) => item.name || ''}
												renderOption={(AutoCompleteprops, item) => (
													<li {...AutoCompleteprops}>{item.name}</li>
												)}
												isOptionEqualToValue={(item, option) => item.id === option.id}
												defaultValue={values.state}
												onChange={(_, item) => {
													setFieldValue('state', item);
													setFieldValue('city', undefined);
													if (item) {
														requestById(DROPDOWN_ACTION.STATE, item?.id);
													}
												}}
											/>
										</>
									)}

									{values?.country?.id === COUNTRY_WITH_STATES && (
										<>
											<Space height={3} />
											<Autocomplete
												label={t('city')}
												placeholder=""
												disabled={isSubmitting}
												options={cities || []}
												error={Boolean(errors.city)}
												helperText={errors.city}
												getOptionLabel={(item) => item.name || ''}
												isOptionEqualToValue={(item, option) => item.id === option.id}
												renderOption={(AutoCompleteprops, item) => (
													<li {...AutoCompleteprops}>{item.name}</li>
												)}
												defaultValue={values.city}
												onChange={(_, item) => setFieldValue('city', item)}
											/>
										</>
									)}
									<Space height={2} />
									{edit && (
										<>
											<FileInput
												label={t('photo')}
												disabled={isSubmitting}
												placeholder={image?.name}
												onChange={(event) => {
													addFile(event);
													if (event.target.files) {
														if (event.target.files.length > 0) {
															setFieldValue('profileImage', getImageURL(event.target.files[0]));
														}
													}
												}}
											/>
											<Space height={2} />
											<Image
												src={getImageURL(initialValues.profileImage)}
												alt="User"
												height="150px"
												width="150px"
											/>
											<Space height={2} />
										</>
									)}
									<Divider />
									<Space height={2} />
									<DefaultButton title={t('save')} type="submit" loading={isSubmitting} />
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateUserPage;
