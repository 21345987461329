import { error } from '@Lottie';
import { Grid, useTheme } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';

const ErrorFallback = () => {
	const theme = useTheme();
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: error,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<Grid container spacing={3} sx={{ p: theme.spacing(3) }}>
			<Grid md={12} sm={12}>
				<div style={{
					backgroundColor: theme.palette.background.paper,
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					display: 'flex',
					flexWrap: 'wrap',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				>
					<Lottie
						options={defaultOptions}
						style={{ height: 400, width: 400, padding: 10 }}
					/>
					{/* <p>{t('splash')}</p> */}
				</div>
			</Grid>
		</Grid>
	);
};

export default ErrorFallback;
