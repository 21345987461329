import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { confirmEmail } from '@Requests';
import { useEffectCustom } from '@Hooks';
import ChangeEmailPage from './ChangeEmailPage';

interface ConfirnEmail {
	email: string;
	name: string
}

const ChangeEmail = () => {
	const { token } = useParams();
	const [expired, setExpired] = useState(false);
	const [data, setData] = useState({} as ConfirnEmail);
	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		const confirm = () => {
			setLoading(true);
			confirmEmail(token)
				.then((res) => setData(res.data))
				.catch(() => setExpired(true)).finally(() => setLoading(false));
		};
		confirm();
	}, [token]);

	return (
		<ChangeEmailPage
			data={data}
			expired={expired}
			loading={loading}
		/>
	);
};

export default ChangeEmail;
