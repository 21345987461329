import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { createQuestion, editQuestion, getQuestionById } from '@Requests';
import { AppRoutes } from '@Routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateQuestionPage from './CreateQuestionPage';

const CreateQuestion = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const { addToast } = useFeedback();

	const [initialValues, setInitialValues] = useState({ question: '', answer: '', languageId: 2 });
	const [errors, setErrors] = useState({ question: '', answer: '', languageId: '' });
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('edit')} ${t('question')}`);
		} else {
			setTitle(`${t('new')} ${t('question')}`);
		}
	}, [setTitle, id, t]);

	useEffectCustom(() => {
		const getQuestion = () => {
			if (id) {
				getQuestionById(id)
					.then(({ data }) => {
						setInitialValues({
							question: data.question,
							answer: data.answer,
							languageId: data.language.id,
						});
					})
					.catch((err) => { addToast({ message: err.title, error: true }); });
			}
		};
		getQuestion();
	}, [id]);

	const handleChange = (value: string | number, key: string) => {
		setInitialValues({ ...initialValues, [key]: value });
		setErrors({ ...errors, [key]: '' });
	};

	const handleSubmit = () => {
		if (!initialValues.question) {
			return setErrors({ ...errors, question: t('emptyField') });
		}
		if (!initialValues.answer) {
			return setErrors({ ...errors, answer: t('emptyField') });
		}
		if (!initialValues.languageId) {
			return setErrors({ ...errors, languageId: t('emptyField') });
		}
		setLoading(true);
		if (id) {
			editQuestion(id, initialValues)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.QUESTIONS);
				})
				.catch(({ response }) => {
					addToast({ message: response.data.message, error: true });
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			createQuestion(initialValues)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.QUESTIONS);
				})
				.catch(({ response }) => { addToast({ message: response.data.message, error: true }); })
				.finally(() => {
					setLoading(false);
				});
		}
		return null;
	};

	return (
		<CreateQuestionPage
			handleChange={handleChange}
			initialValues={initialValues}
			errors={errors}
			handleSubmit={handleSubmit}
			loading={loading}
			edit={!!id}
		/>
	);
};

export default CreateQuestion;
