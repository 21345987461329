import { useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getChainStores, chainStoreStatus } from '@Requests';
import { useRequest } from '@Api';
import { IChainStore, IListDataControl, IPaginationTable } from '@Types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import ListChainStorePage from './ListChainStorePage';

const ListChainStore = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const chainStores = useRequest(([params, signal]) => getChainStores(params, signal));
	const changeStatus = useRequest(([params]) => chainStoreStatus(params));

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => {
		setTitle(t('networks'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const listControl: IListDataControl = {
				page: pagination.page,
				search,
				size: pagination.size,
			};
			chainStores.execute(listControl, abortCtrl.signal);
		};
		getData();
		return () => abortCtrl.abort();
	}, [search, pagination]);

	useEffect(() => {
		if (changeStatus.data) {
			const index = chainStores.data?.data.findIndex((el) => el.id === changeStatus.data?.id);
			chainStores.setData((prev) => {
				const mPrev = prev;
				if (mPrev) {
					if (index !== undefined && changeStatus.data) {
						mPrev.data[index] = changeStatus.data;
					}
				}
				return mPrev;
			});
		}
	}, [changeStatus.data, chainStores]);

	const handleListAction = (action: string, item?: IChainStore) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.CREATE_CHAIN_STORE);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_CHAIN_STORE.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			changeStatus.execute(item?.id);
			break;

		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListChainStorePage
			chainStores={chainStores.data}
			handleListAction={handleListAction}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
		/>
	);
};

export default ListChainStore;
