import { FontSizes } from '@Constants';
import {
	Box, Divider, Stack, Typography, useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface SummaryCardProps {
   title: string;
   value?: number;
   badge?: string
}

const badgeSx = {
	fontSize: FontSizes.SM,
	fontWeight: 'bold',
	bgcolor: 'primary.main',
	pt: '3px',
	pb: '3px',
	pl: '5px',
	pr: '5px',
	borderRadius: '3px',
	color: 'white',
};

const SummaryCard: FC<SummaryCardProps> = ({ title, value, badge }) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<Box sx={{ backgroundColor: theme.palette.background.paper }}>
			<Stack direction="row" justifyContent="space-between" sx={{ padding: theme.spacing(2) }}>
				<Typography variant="subtitle2">{title}</Typography>
				<Box
					component="span"
					sx={badgeSx}
				>
					{badge}
				</Box>
			</Stack>
			<Divider />
			<Box sx={{ padding: theme.spacing(2) }}>
				<Typography variant="h4">{value ?? 0}</Typography>
				<Typography variant="caption">{t('total')}</Typography>
			</Box>
		</Box>
	);
};

export default SummaryCard;
