import { useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@Api';
import { getAllTickets } from '@Requests';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import { IPaginationTable } from '@Types';
import TicketsPage from './TicketsPage';

const Tickets = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const tickets = useRequest(([params]) => getAllTickets(params));

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => {
		setTitle(t('tickets'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			tickets.execute(listDataControl);
		};
		getData();
	}, [pagination, search]);

	const handleEdit = (ticketId: number) => {
		navigate(AppRoutes.EDIT_TICKETS.formatMap({ id: ticketId }));
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<TicketsPage
			setPagination={setTablePagination}
			setSearch={setTableSearch}
			tickets={tickets.data}
			onEditClick={handleEdit}
		/>
	);
};

export default Tickets;
