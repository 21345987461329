import {
	StatusLabel, Dropdown, DateLabel, TableItem,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IOrder, IPagination, IPaginationTable } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '@Helpers';
import { AppRoutes } from '@Routes';

export interface OrderListPageProps {
   data: IPagination<IOrder[]> | undefined;
   onPageChange: (pagination: IPaginationTable) => void;
   onSearch: (query: string) => void;
   onCancelOrder: (orderId: string) => void;
}

enum ORDER_STATUS {
	CONFIRMED = 2,
	UNDER_ANALYSIS = 4,
	PROCESSING = 1
}

const OrderListPage = (props: OrderListPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		data, onPageChange, onSearch, onCancelOrder,
	} = props;
	const navigate = useNavigate();

	const getListAction = (statusId: number) => {
		switch (statusId) {
		case ORDER_STATUS.PROCESSING:
			return [t('orderDetials'), t('cancel')];
		case ORDER_STATUS.CONFIRMED:
			return [t('orderDetials'), t('cancel')];
		case ORDER_STATUS.UNDER_ANALYSIS:
			return [t('orderDetials'), t('makeCorrection'), t('cancel')];
		default:
			return [t('orderDetials')];
		}
	};

	const handleListAction = (action: string, order?: IOrder) => {
		switch (action) {
		case t('orderDetials'): {
			navigate(`/order/details/${order?.id}`);
			break;
		}
		case t('cancel'): {
			onCancelOrder(order?.id ?? '');
			break;
		}
		case t('makeCorrection'): {
			navigate(AppRoutes.ORDER_FIX.formatMap({ id: order?.id }));
			break;
		}
		default:
			break;
		}
	};

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={2}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={data?.data || []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={data?.total}
						>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('registration')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn headerText={t('client')} render={(item) => `${item.client.name || ''}`} />
							<TableColumn headerText={`${t('phone')} / ${t('email')}`} render={(item) => <TableItem title={item.client?.phone || ''} subTitle={item.client.email || ''} />} />
							<TableColumn headerText={t('finalValue')} render={(item) => formatCurrency(item.grossAmount - item.usedBalance - item.discount)} />
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
							<TableColumn
								headerText={t('')}
								render={(order) => (
									<Dropdown
										data={getListAction(order.status.id)}
										label={t('action')}
										onChange={(action) => handleListAction(action, order)}
										renderItem={(item) => ({ optionLabel: item, optionValue: item })}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default OrderListPage;
