import { Box, useTheme } from '@mui/material';
import React, { FC, ReactNode } from 'react';

interface PaperContainerProps {
   children: ReactNode;
}

const PaperContainer: FC<PaperContainerProps> = ({ children }) => {
	const theme = useTheme();
	return (
		<Box sx={{ backgroundColor: theme.palette.background.paper, padding: theme.spacing(2) }}>
			{children}
		</Box>
	);
};

export default PaperContainer;
