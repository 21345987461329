import {
	DateLabel, DefaultButton, Dropdown, Image, StatusLabel,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { ICoupon, IPagination, IPaginationTable } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { formatCurrency } from '@Helpers';

export interface CouponsListProps {
   data: IPagination<ICoupon[]> | undefined;
   onPageChange: (pagination: IPaginationTable) => void;
   onSearch: (query: string) => void;
   handleListAction: (action: string, coupon?: ICoupon) => void;
}

const CouponsListPage = (props: CouponsListProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		data, onPageChange, onSearch, handleListAction,
	} = props;

	const listActions = [t('changeStatus'), t('detail'), t('defineAudience'), t('sendToSpecificUser')];

	return (
		<div>
			<Grid
				sx={{ padding: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={data?.data || []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={data?.total}
						>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('title')} render={(item) => `${item.title}`} />
							<TableColumn headerText={t('qtd')} render={(item) => `${item.maxUsage}`} />
							<TableColumn headerText={t('cost')} render={(item) => `${formatCurrency(item.cost)}`} />
							<TableColumn headerText={t('bonus')} render={(item) => `${item.bonus}`} />
							<TableColumn headerText={t('categories')} render={(item) => `${item.category.name}`} />
							<TableColumn headerText={t('start')} render={(item) => <DateLabel date={item.startDate} showTime />} />
							<TableColumn headerText={t('end')} render={(item) => <DateLabel date={item.endDate} showTime />} />
							<TableColumn headerText={t('registration')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn padding="none" headerOptions={{ padding: 'none' }} headerText={t('status')} render={(item) => <StatusLabel width="5em" status={item.status} />} />
							<TableColumn
								headerText=""
								render={(item) => <Image height={50} width={120} src={item.banner} alt={`coupons-item-image-${item.id}`} />}
							/>
							<TableColumn
								padding="none"
								headerText={t('')}
								render={(coupon) => (
									<Dropdown
										data={listActions}
										label={t('action')}
										onChange={(action) => handleListAction(action, coupon)}
										renderItem={(item) => ({ optionLabel: item, optionValue: item })}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default CouponsListPage;
