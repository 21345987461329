import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface DefaultButtonProps extends LoadingButtonProps {
	title: string
}

const DefaultButton: React.FC<DefaultButtonProps> = (props) => {
	const { title } = props;
	return (
		<LoadingButton
			color="primary"
			size="small"
			variant="contained"
			disableElevation
			sx={{ textTransform: 'none' }}
			{...props}
		>
			{title}
		</LoadingButton>
	);
};

export default DefaultButton;
