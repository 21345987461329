import { Grid, useTheme } from '@mui/material';
import {
	DateLabel, DefaultButton, Dropdown, StatusLabel,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IOrganization, IPagination, IPaginationTable } from '@Types';

interface ListPartnersProps {
	partners?: IPagination<IOrganization[]>;
	setPagination: (pagination: IPaginationTable) => void;
	setSearch: (searchQuery: string) => void;
	handleListAction: (action: string, item?: IOrganization) => void
}

const ListPartnersPage: React.FC<ListPartnersProps> = (props) => {
	const {
		partners, handleListAction, setPagination, setSearch,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={partners?.data || []}
							total={partners?.total}
							onSearching={(searchQuery) => setSearch(searchQuery)}
							onChange={(page, size) => setPagination({ page, size })}
							pagination
						>
							<TableColumn headerText="ID" render={(item) => item.id} />
							<TableColumn headerText={t('name')} render={(item) => item.officialName} />
							<TableColumn headerText={t('network')} render={() => ''} />
							<TableColumn headerText={t('email')} render={(item) => item.email} />
							<TableColumn headerText={t('registeration')} render={(item) => <DateLabel date={item.registration} />} />
							<TableColumn headerText={t('phone')} render={(item) => item.phone} />
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
							<TableColumn
								align="right"
								render={(item) => (
									<Dropdown
										variant="contained"
										label={t('action')}
										data={[t('changeStatus'), t('details'), t('services'), t('costCenter'), t('qrCode')]}
										renderItem={(dropdownItem) => (
											{ optionValue: dropdownItem, optionLabel: dropdownItem }
										)}
										onChange={(optionLabel) => { handleListAction(optionLabel, item); }}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ListPartnersPage;
