/* eslint-disable react/no-unstable-nested-components */
import {
	Route,
	Routes,
	BrowserRouter,
	Navigate,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { AppRoutes } from '@Routes';
import {
	PageNotFound,
	Login,
	Dashboard,
	Main,
	ListUsers,
	PromoteToAdm,
	ChangePassword,
	CreateUser,
	CouponList,
	CreateCoupon,
	RequestedCoupons,
	DefineAudience,
	OrderList,
	OrderDetail,
	OrderFix,
	Administrators,
	LinkToCostCenter,
	SendMessage,
	Transactions,
	RatingsList,
	CreateRating,
	BalencePoints,
	RefferalDetails,
	ReferralsList,
	UserTags,
	UserVehicles,
	ArticlesList,
	CreateArticle,
	Questions,
	Terms,
	CreateQuestion,
	CreateTerm,
	ListTags,
	CreateTag,
	ListChainStore,
	CreateChainStore,
	ListOrganizations,
	CreateOrganization,
	ListServices,
	CreateService,
	ListPartners,
	CreatePartner,
	ListCards,
	CreateCard,
	UsersReport,
	CouponsReport,
	SalesReport,
	IssueCard,
	ListCostCenter,
	CreateCostCenter,
	Tickets,
	EditTicket,
	ResetPassword,
	ChangeEmail,
	TransactionReport,
	ReferralReport,
	OrderReport,
	ForgotPassword,
	SocialNetwork,
	CreateSocialNetwork,
	CouponQuantityReport,

} from '@Pages';
import { useAuth } from '@Providers';
import React from 'react';
import { useEffectCustom } from '@Hooks';

const AppRouter = () => {
	const RequireAuth = ({ children }: { children: JSX.Element }) => {
		const { user } = useAuth();
		const location = useLocation();
		const navigate = useNavigate();
		useEffectCustom(() => {
			if (user) {
				if (location.pathname === '/') {
					navigate(AppRoutes.DASHBOARD);
				}
			}
		}, []);

		if (!user) {
			return <Navigate to="/login" state={{ from: location }} replace />;
		}

		return children;
	};
	return (
		<BrowserRouter>
			<Routes>
				<Route path={AppRoutes.LOGIN} element={<Login />} />
				<Route path={AppRoutes.FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route path={AppRoutes.CHANGE_PASSWORD_LINK} element={<ResetPassword />} />
				<Route path={AppRoutes.CHANGE_EMAIL_LINK} element={<ChangeEmail />} />
				<Route
					path={AppRoutes.MAIN}
					element={(
						<RequireAuth>
							<Main />
						</RequireAuth>
					)}
				>

					<Route path={AppRoutes.DASHBOARD} element={<Dashboard />} />
					<Route path={AppRoutes.LIST_USERS} element={<ListUsers />} />
					<Route path={AppRoutes.NEW_USER} element={<CreateUser />} />
					<Route path={AppRoutes.EDIT_ACTIVE_USER} element={<CreateUser />} />
					<Route path={AppRoutes.EDIT_USER} element={<CreateUser />} />
					<Route path={AppRoutes.CHANGE_PASSWORD} element={<ChangePassword />} />
					<Route path={AppRoutes.LINK_TO_CC} element={<LinkToCostCenter />} />
					<Route path={AppRoutes.SEND_MESSAGE} element={<SendMessage />} />
					<Route path={AppRoutes.BALENCE_POINTS} element={<BalencePoints />} />
					<Route path={AppRoutes.USER_TAGS} element={<UserTags />} />
					<Route path={AppRoutes.USER_VEHICLES} element={<UserVehicles />} />
					<Route path={AppRoutes.LIST_COUPONS} element={<CouponList />} />
					<Route path={AppRoutes.EDIT_COUPON} element={<CreateCoupon />} />
					<Route path={AppRoutes.NEW_COUPON} element={<CreateCoupon />} />
					<Route path={AppRoutes.PROMOTE_ADM} element={<PromoteToAdm />} />
					<Route path={AppRoutes.COUPON_REQUESTS} element={<RequestedCoupons />} />
					<Route path={AppRoutes.DEFINE_COUPON_AUDIENCE} element={<DefineAudience />} />
					<Route path={AppRoutes.LIST_ORDERS} element={<OrderList />} />
					<Route path={AppRoutes.ORDER_DETAIL} element={<OrderDetail />} />
					<Route path={AppRoutes.ORDER_FIX} element={<OrderFix />} />
					<Route path={AppRoutes.LIST_TICKETS} element={<Tickets />} />
					<Route path={AppRoutes.EDIT_TICKETS} element={<EditTicket />} />
					<Route path={AppRoutes.ADMINISTRATORS} element={<Administrators />} />
					<Route path={AppRoutes.LIST_TRANSACTIONS} element={<Transactions />} />
					<Route path={AppRoutes.LIST_RATINGS} element={<RatingsList />} />
					<Route path={AppRoutes.NEW_RATING} element={<CreateRating />} />
					<Route path={AppRoutes.EDIT_RATING} element={<CreateRating />} />
					<Route path={AppRoutes.LIST_REFERRALS} element={<ReferralsList />} />
					<Route path={AppRoutes.REFERRAL_DETAILS} element={<RefferalDetails />} />
					<Route path={AppRoutes.LIST_ARTICLES} element={<ArticlesList />} />
					<Route path={AppRoutes.NEW_ARTICLE} element={<CreateArticle />} />
					<Route path={AppRoutes.EDIT_ARTICLE} element={<CreateArticle />} />
					<Route path={AppRoutes.TERMS} element={<Terms />} />
					<Route path={AppRoutes.EDIT_TERM} element={<CreateTerm />} />
					<Route path={AppRoutes.NEW_TERM} element={<CreateTerm />} />
					<Route path={AppRoutes.QUESTIONS} element={<Questions />} />
					<Route path={AppRoutes.EDIT_QUESTION} element={<CreateQuestion />} />
					<Route path={AppRoutes.CREATE_QEUSTION} element={<CreateQuestion />} />
					<Route path={AppRoutes.TAGS} element={<ListTags />} />
					<Route path={AppRoutes.CREATE_TAG} element={<CreateTag />} />
					<Route path={AppRoutes.EDIT_TAGS} element={<CreateTag />} />
					<Route path={AppRoutes.SOCIAL_NETWORK} element={<SocialNetwork />} />
					<Route path={AppRoutes.EDIT_SOCIAL_NETWORK} element={<CreateSocialNetwork />} />
					<Route path={AppRoutes.CREATE_SOCIAL_NETWORK} element={<CreateSocialNetwork />} />
					<Route path={AppRoutes.CHAIN_STORES} element={<ListChainStore />} />
					<Route path={AppRoutes.EDIT_CHAIN_STORE} element={<CreateChainStore />} />
					<Route path={AppRoutes.CREATE_CHAIN_STORE} element={<CreateChainStore />} />
					<Route path={AppRoutes.ORGANIZATIONS} element={<ListOrganizations />} />
					<Route path={AppRoutes.CREATE_ORGANIZATION} element={<CreateOrganization />} />
					<Route path={AppRoutes.EDIT_ORGANIZATION} element={<CreateOrganization />} />
					<Route path={AppRoutes.COST_CENTERS_ORGANIZATION} element={<ListCostCenter />} />
					<Route path={AppRoutes.COST_CENTERS_PARTNER} element={<ListCostCenter />} />
					<Route path={AppRoutes.CREATE_COST_CENTER_ORGANIZATION} element={<CreateCostCenter />} />
					<Route path={AppRoutes.CREATE_COST_CENTER_PARTNER} element={<CreateCostCenter />} />
					<Route path={AppRoutes.EDIT_COST_CENTER_PARTNER} element={<CreateCostCenter />} />
					<Route path={AppRoutes.EDIT_COST_CENTER_ORGANIZATION} element={<CreateCostCenter />} />
					<Route path={AppRoutes.SERVICES} element={<ListServices />} />
					<Route path={AppRoutes.SERVICES_ORGANIZATION} element={<ListServices />} />
					<Route path={AppRoutes.SERVICES_PARTNERS} element={<ListServices />} />
					<Route path={AppRoutes.EDIT_SERVICE} element={<CreateService />} />
					<Route path={AppRoutes.CREATE_SERVICES_ORGANIZATION} element={<CreateService />} />
					<Route path={AppRoutes.CREATE_SERVICES_PARTNER} element={<CreateService />} />
					<Route path={AppRoutes.CREATE_SERVICE} element={<CreateService />} />
					<Route path={AppRoutes.PARTNERS} element={<ListPartners />} />
					<Route path={AppRoutes.CREATE_PARTNER} element={<CreatePartner />} />
					<Route path={AppRoutes.EDIT_PARTNER} element={<CreatePartner />} />
					<Route path={AppRoutes.CARDS} element={<ListCards />} />
					<Route path={AppRoutes.CREATE_CARD} element={<CreateCard />} />
					<Route path={AppRoutes.EDIT_CARD} element={<CreateCard />} />
					<Route path={AppRoutes.ISSUE_CARD} element={<IssueCard />} />
					<Route path={AppRoutes.USERS_REPORT} element={<UsersReport />} />
					<Route path={AppRoutes.COUPON_QUANTITY_PER_TYPE} element={<CouponQuantityReport />} />
					<Route path={AppRoutes.COUPONS_REPORT} element={<CouponsReport />} />
					<Route path={AppRoutes.SALES_REPORT} element={<SalesReport />} />
					<Route path={AppRoutes.TRANSACTION_REPORT} element={<TransactionReport />} />
					<Route path={AppRoutes.REFERRAL_REPORT} element={<ReferralReport />} />
					<Route path={AppRoutes.ORDER_REPORT} element={<OrderReport />} />

				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRouter;
