import { Row } from '@Atoms';
import { Typography } from '@mui/material';
import React from 'react';

interface ReferralListProps {
	icon?: React.ReactNode;
	title?: string;
	value?: string;
	status?: React.ReactNode
}

const ReferralListItem: React.FC<ReferralListProps> = (props) => {
	const {
		icon, title, value, status,
	} = props;
	return 	(
		<Row justify="flex-start">
			{icon}
			{title && (
				<Typography variant="body2" sx={{ ml: icon ? 1 : 0, mr: 1 }}>
					{` ${title}: `}
					{' '}
				</Typography>
			)}
			<Typography variant="body2" sx={{ mr: 1 }}>
				{' '}
				{value}
			</Typography>
			{status}
		</Row>
	);
};

export default ReferralListItem;
