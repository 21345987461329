import { DefaultButton, Space, TextField } from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	useTheme,
	Grid,
	Divider,
} from '@mui/material';
import { ITagReq } from '@Types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CreateTagProps {
	handleSubmit?: () => void;
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	tag: ITagReq;
	errors: Partial<ITagReq>;
	loading?: boolean
}

const CreateTagPage: React.FC<CreateTagProps> = (props) => {
	const {
		handleSubmit,
		onChange,
		tag,
		errors,
		loading,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Grid container spacing={2}>
							<Grid item xs={12} md={4}>
								<TextField
									label={t('name')}
									name="name"
									onChange={onChange}
									value={tag.name}
									error={!!errors.name}
									helperText={errors.name}
									disabled={loading}
								/>
							</Grid>
							<Grid item xs={12} md={8}>
								<TextField
									label={t('description')}
									name="description"
									onChange={onChange}
									value={tag.description}
									error={!!errors.description}
									helperText={errors.description}
									disabled={loading}
								/>
							</Grid>
						</Grid>
						<Space height={2} />
						<Divider />
						<Space height={2} />
						<DefaultButton
							title={t('save')}
							onClick={() => handleSubmit?.call(0)}
							loading={loading}
						/>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateTagPage;
