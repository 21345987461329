import { useRequest } from '@Api';
import { useFeedback, useTitleBar } from '@Providers';
import {
	addService, editService, getOrganizations, getServiceById, getOrganizationById, getPartnerById,
} from '@Requests';
import { IServiceReq, IOrganization } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikErrors, FormikHelpers } from 'formik';
import { AppRoutes } from '@Routes';
import { putDecimalPoints } from '@Masks';
import { useEffectCustom } from '@Hooks';
import CreateServicePage, { PRIZE_TYPE } from './CreateServicePage';

const CreateService = () => {
	const { id, partnerId, organizationId } = useParams();
	const { setTitle } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const organizations = useRequest(([params, signal]) => getOrganizations(params, signal));

	const [initialValues, setInitialValues] = useState({
		name: '',
		cost: '',
		prize: '',
		prizeType: { name: t('absoluteValue'), id: PRIZE_TYPE.ABSOLUTE },
		description: '',
		organizations: [] as IOrganization[],
	} as IServiceReq);
	const [isEdit, setEdit] = useState(false);

	const REWARD_OPTIONS = [
		{ name: t('absoluteValue'), id: PRIZE_TYPE.ABSOLUTE }, { name: t('percentageValue'), id: PRIZE_TYPE.PERCENTAGE },
	];

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getAllOrganizations = async () => {
			const listDataControl = {
				page: 1,
				size: 1000,
				search: '',
			};
			await organizations.execute(listDataControl, abortCtrl.signal);
			if (id) {
				getServiceById(id ?? '')
					.then(({ data }) => setInitialValues({
						...initialValues,
						name: data.name,
						cost: putDecimalPoints(data.cost),
						prize: putDecimalPoints(data.prize),
						prizeType: REWARD_OPTIONS.find((el) => el.id === data.prizeType),
						description: data.description,
						organizations: data.organizations,
					}))
					.catch(({ response }) => addToast({ message: response.data.message, error: true }));
			}
			if (partnerId) {
				getPartnerById(partnerId).then(({ data }) => setInitialValues({
					...initialValues,
					organizations: [data]
					,
				}));
			}
			if (organizationId) {
				getOrganizationById(organizationId).then(({ data }) => {
					setInitialValues({
						...initialValues,
						organizations: [data],
					});
				});
			}
		};
		getAllOrganizations();
		return () => abortCtrl.abort();
	}, [id]);

	useEffect(() => {
		if (id) {
			setTitle(`${t('service')} ${t('detail')}`);
		} else {
			setTitle(`${t('new')} ${t('service')}`);
			setEdit(true);
		}
	}, [id, setTitle, t]);

	const validate = (values: IServiceReq) => {
		const errors: FormikErrors<IServiceReq> = {};
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.cost) {
			errors.cost = t('emptyField');
		}
		if (!values.prize) {
			errors.prize = t('emptyField');
		}
		return errors;
	};

	const handleSubmit = (values: IServiceReq, helpers: FormikHelpers<IServiceReq>) => {
		if (id) {
			editService(id, values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.SERVICES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => helpers.setSubmitting(false));
		} else {
			addService(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.SERVICES);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => helpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('service')}`);
		setEdit(true);
	};

	const handleBackClick = () => {
		navigate(AppRoutes.SERVICES);
	};

	return (
		<CreateServicePage
			organizations={organizations.data?.data}
			reward={REWARD_OPTIONS}
			initialValues={initialValues}
			validate={validate}
			onSubmit={handleSubmit}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
			handleBackClick={handleBackClick}
		/>
	);
};

export default CreateService;
