import {
	StatusLabel,
	Dropdown,
	DateLabel,
	TableItem,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Grid,
	IconButton,
	Tooltip,
	useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IPagination, IPaginationTable, ITransaction } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
	ErrorOutline,
	Person,
	Phone,
	Login,
	Logout,
	InfoOutlined,
} from '@mui/icons-material';

import { formatCurrency } from '@Helpers';

export interface TransactionsListPageProps {
   data: IPagination<ITransaction[]> | undefined;
   onPageChange: (pagination: IPaginationTable) => void;
   onSearch: (query: string) => void;
   handleListAction: (action: string, itemId?: number) => void
}

const TransactionsListPage = (props: TransactionsListPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		data, onPageChange, onSearch, handleListAction,
	} = props;

	const listActions = [t('confirm'), t('cancel')];

	const iconSx = (color?: string, fontSize?: number) => ({ fontSize: fontSize || 12, mr: '5px', color });

	const getTitleIcon = (itemType: { id: number, name: string }) => {
		let titleIcon = null;
		if (itemType.id === 1) {
			titleIcon = <Login sx={iconSx(theme.palette.primary.main, 16)} />;
		} else if (itemType.id === 2) {
			titleIcon = <Logout sx={iconSx(theme.palette.error.main, 16)} />;
		} else if (itemType.name === 'Informative') {
			titleIcon = <InfoOutlined sx={iconSx(theme.palette.primary.main, 16)} />;
		}
		return titleIcon;
	};

	return (
		<div>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={data?.data || []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={data?.total}
						>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item?.entryDate} showTime />} />
							<TableColumn
								headerText={t('description')}
								render={(item) => (
									<TableItem
										title={item?.operationName}
										subTitle={`${t('description')}: ${item?.description}${item?.note ? `\n${t('Note')}: ${item?.note}` : ''}`}
										endIcon={item.order?.id && (
											<Tooltip title={t('orderDetials') || ''} placement="top">
												<IconButton
													sx={{ p: 0, ml: 1 }}
													onClick={() => {
														handleListAction(t('orderDetials'), item.order?.id);
													}}
												>
													<ErrorOutline sx={iconSx(theme.palette.success.main, 18)} />
												</IconButton>
											</Tooltip>
										)}
									/>
								)}
							/>

							<TableColumn
								headerText={t('user')}
								render={(item) => (
									<TableItem
										title={item.user?.name}
										titleIcon={<Person sx={iconSx()} />}
										subTitleIcon={<Phone sx={iconSx()} />}
										subTitle={item.user?.phone}
									/>
								)}
							/>
							<TableColumn
								headerText={t('card')}
								render={(item) => (
									<TableItem
										title={item.cardUser?.name}
										subTitle={item.cardUser?.cardType}
									/>
								)}
							/>

							<TableColumn
								headerText={t('value')}
								render={(item) => {
									let color = 'black';
									if (item.type.name === 'Incoming' && item.amount !== 0) {
										color = 'blue';
									} else if (item.type.name === 'Outcoming' && item.amount !== 0) {
										color = 'red';
									}
									const amount = item.type.name === 'Informative' ? Math.abs(item.amount) : item.amount;
									return <span style={{ color }}>{formatCurrency(amount)}</span>;
								}}
							/>

							<TableColumn
								headerText="Discount"
								render={(item) => {
									const discount = item.type.name === 'Informative' ? Math.abs(item.discount || 0) : item.discount || 0;
									return `${formatCurrency(discount)}`;
								}}
							/>

							<TableColumn
								headerText={t('type')}
								render={(item) => (
									<TableItem
										title={item.type?.name}
										titleIcon={getTitleIcon(item.type)}
									/>
								)}
							/>

							<TableColumn
								headerText={t('status')}
								render={(item) => (
									<div>
										<StatusLabel status={item.status} />
										{item.statusDate && <DateLabel date={item.statusDate} showTime />}
									</div>
								)}
							/>

							<TableColumn
								headerText={t('')}
								render={(transaction: ITransaction) => (
									<Dropdown
										disabled={transaction.status?.id !== 1}
										variant={transaction.status?.id !== 1 ? 'outlined' : 'contained'}
										data={listActions}
										label={t('action')}
										onChange={(action) => handleListAction(action, transaction?.id)}
										renderItem={(item) => ({ optionLabel: item, optionValue: item })}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default TransactionsListPage;
