import { Box } from '@mui/material';
import React from 'react';

interface Country {
		id: number,
		isoCode: string,
		name: string,
		phoneCode: string,
		shortName: string,
		language: string,
	}

interface CountryItemProps {
	option: Country;
	autocompleteprops: React.HTMLAttributes<HTMLLIElement>
}

const CountryItem: React.FC<CountryItemProps> = (props) => {
	const { option, autocompleteprops } = props;
	return (
		<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...autocompleteprops}>
			<img
				loading="lazy"
				width="20"
				src={`https://flagcdn.com/w20/${option.shortName.toLowerCase()}.png`}
				srcSet={`https://flagcdn.com/w40/${option.shortName.toLowerCase()}.png 2x`}
				alt=""
			/>
			{option.name}
			{' '}
			(
			{option.language}
			)
		</Box>
	);
};

export default CountryItem;
