import {
	Space,
	StatusLabel,
	TextField,
	DefaultButton,
	DefaultSelect,
	DateLabel,
	Row,
} from '@Atoms';
import { FontSizes } from '@Constants';
import { useEffectCustom } from '@Hooks';
import { currency } from '@Masks';
import { PaperContainer } from '@Molecules';
import {
	Email,
	Person,
	Phone,
	ShoppingCart,
	Store,
} from '@mui/icons-material';
import {
	Box,
	Divider,
	Grid,
	LinearProgress,
	Theme,
	Typography,
	useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { INameAndId, IReferral } from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReferralListItem from './ReferralListItem';

export interface RefferalDetailsPageProps {
   sellerLoading: boolean;
   serviceValueLoading: boolean;
   referral: IReferral | undefined;
   onSellerSaveClick: () => void;
   sellerError: string;
   onServiceValueSave: () => void;
   sellers?: INameAndId[];
   onChange: (key: string, value: string | number) => void;
   values: {sellerId: number | string, serviceValue: string}
}

interface StatusVisualizer {
   progressValue: number;
   color: 'info' | 'success' | 'error' | 'inherit' | 'primary' | 'secondary' | 'warning' | undefined;
}

const getStatusVisualizer = (theme: Theme, id: number): StatusVisualizer => {
	switch (id) {
	case 1:
		return { progressValue: 20, color: 'info' };
	case 2:
		return { progressValue: 40, color: 'info' };
	case 3:
		return { progressValue: 60, color: 'info' };
	case 4:
		return { progressValue: 80, color: 'info' };
	case 5:
		return { progressValue: 100, color: 'success' };
	case 6:
		return { progressValue: 0, color: 'error' };
	default:
		return { progressValue: 0, color: 'error' };
	}
};

const iconSx = {
	fontSize: FontSizes.LG,
};
const labelSx = (theme: Theme) => ({
	ml: 1,
	backgroundColor: theme.palette.primary.main,
	pl: 1,
	pr: 1,
	borderRadius: 1,
});
const RefferalDetailsPage = (props: RefferalDetailsPageProps) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const {
		referral,
		onSellerSaveClick,
		sellerError,
		sellers,
		onChange,
		values,
		onServiceValueSave,
		sellerLoading,
		serviceValueLoading,
	} = props;
	const [statusVisualizer, setStatusVisualizer] = useState<StatusVisualizer>();

	useEffectCustom(() => {
		if (referral) {
			setStatusVisualizer(getStatusVisualizer(theme, referral.status.id));
		}
	}, [referral]);

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={2}>
				<Grid item xs={12} md={12} lg={12}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Typography variant="subtitle1">{`${t('referred')} ${t('info')}`}</Typography>
						</Box>
						<Divider />
						<Box sx={{ padding: theme.spacing(1) }}>
							<ReferralListItem
								icon={<Person sx={iconSx} />}
								title={t('name')}
								value={referral?.name}
								status={<StatusLabel status={referral?.status} width="10rem" />}
							/>
							<ReferralListItem
								icon={<Email sx={iconSx} />}
								title={t('email')}
								value={referral?.email}
							/>
							<ReferralListItem
								icon={<Phone sx={iconSx} />}
								title={t('phone')}
								value={referral?.phone}
							/>
							<ReferralListItem
								title={`${t('referred')} ${t('by')}`}
								value={`${referral?.fromUser.name || ''}(${referral?.relationship.name || ''})`}
							/>
							<Space height={2} />
							{statusVisualizer && (
								<LinearProgress
									variant="determinate"
									sx={{ height: 8 }}
									value={statusVisualizer?.progressValue}
									color={statusVisualizer?.color}
								/>
							)}
						</Box>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Typography variant="subtitle1">{`${t('company')} ${t('info')}`}</Typography>
						</Box>
						<Divider />
						<Box sx={{ padding: theme.spacing(1) }}>
							<ReferralListItem
								icon={<Store style={iconSx} />}
								title={t('company')}
								value={referral?.organization.fantasyName}
							/>
							<ReferralListItem
								icon={<ShoppingCart style={iconSx} />}
								title={`${t('product')}/${t('service')}: `}
								value={referral?.service.name}
							/>
							<ReferralListItem
								value={referral?.service.description}
							/>
							<Space height={2} />
							<Grid container spacing={2}>
								<Grid item xs={12} md={3}>
									<TextField
										value={values.serviceValue}
										label={t('serviceValue')}
										onChange={(event) => onChange('serviceValue', currency(event).target.value)}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<DefaultButton title={t('save')} onClick={onServiceValueSave} loading={serviceValueLoading} />
								</Grid>
							</Grid>
						</Box>
						<Space height={2} />
						<Divider />
						<Space height={2} />
						<Grid container spacing={2}>
							<Grid item xs={12} md={8}>
								<DefaultSelect
									options={sellers || []}
									value={values.sellerId}
									label={t('responsibleEmployee')}
									error={!!sellerError}
									helperText={sellerError}
									renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
									onChange={(event) => {
										onChange('sellerId', event.target.value);
									}}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DefaultButton title={t('save')} onClick={onSellerSaveClick} loading={sellerLoading} />
							</Grid>
						</Grid>

					</PaperContainer>
				</Grid>
				{referral?.note && (
					<Grid item xs={12} md={12} lg={12}>
						<PaperContainer>
							<Box>
								<Typography variant="subtitle1">{`${t('referral')} ${t('notes')}`}</Typography>
							</Box>
							<Divider />
							<Space height={1} />
							<Typography variant="body2">{`${referral?.note})`}</Typography>
						</PaperContainer>
					</Grid>
				)}
				<Grid item xs={12}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Row justify="flex-start">
								<Typography variant="subtitle1">{`${t('history')}`}</Typography>
								<Typography
									sx={labelSx(theme)}
									color="white"
								>
									{referral?.history?.length}
								</Typography>
							</Row>
						</Box>
						<Divider />
						<DataTable data={referral?.history || []} search={false}>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn headerText={t('responsible')} render={(item) => `${item.user?.name}`} />
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} width="10rem" />} />
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={6}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Row justify="flex-start">
								<Typography variant="subtitle1">{`${t('comments')}`}</Typography>
								<Typography
									sx={labelSx(theme)}
									color="white"
								>
									{referral?.notes?.length}
								</Typography>
							</Row>
						</Box>
						<Divider />
						{referral?.notes && (
							<DataTable data={referral?.notes || []} search={false}>
								<TableColumn headerText="ID" render={(item) => `${item.id}`} />
								<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.registration} showTime />} />
								<TableColumn headerText={t('description')} render={(item) => `${item.description}`} />
								<TableColumn
									headerText={t('user')}
									render={(item) => item.user.name}
								/>
							</DataTable>
						)}
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={6}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Row justify="flex-start">
								<Typography variant="subtitle1">{`${t('schedules')}`}</Typography>
								<Typography
									sx={labelSx(theme)}
									color="white"
								>
									{referral?.appointments.length}
								</Typography>
							</Row>

						</Box>
						<Divider />
						{referral?.appointments && (
							<DataTable data={referral?.appointments || []} search={false}>
								<TableColumn headerText="ID" render={(item) => `${item.id}`} />
								<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.datetime} showTime />} />
								<TableColumn headerText={t('description')} render={(item) => `${item.description}`} />
								<TableColumn
									headerText={t('status')}
									render={(item) => <StatusLabel status={item.status} />}
								/>
							</DataTable>
						)}
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default RefferalDetailsPage;
