import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';
import { Grid, useTheme } from '@mui/material';
import React from 'react';
import { IPaginationTable, IService } from '@Types';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatPercentage } from '@Helpers';
import { DefaultButton } from '@Atoms';
import { Edit } from '@mui/icons-material';

enum PrizeType {
	percentage = 'PERCENTAGE'
}

interface ListServicesProps {
	services?: IService[];
	handleListAction: (action: string, service?: IService) => void;
	pagination: boolean;
	total?: number;
	setPagination: (params: IPaginationTable) => void;
	setSearch: (query: string) => void
}

const ListServicesPage:React.FC<ListServicesProps> = (props) => {
	const {
		services, handleListAction, pagination, total, setPagination, setSearch,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={services || []}
							onChange={(page, size) => setPagination({ page, size })}
							onSearching={setSearch}
							search={false}
							pagination={pagination}
							total={total}
						>
							<TableColumn headerText="ID" render={(item) => item.id} />
							<TableColumn headerText={t('name')} render={(item) => item.name} />
							<TableColumn headerText={t('value')} render={(item) => formatCurrency(item.cost)} />
							<TableColumn
								headerText={t('reward')}
								render={(item) => (
									item.prizeType === PrizeType.percentage
										? formatPercentage(item.prize)
										: formatCurrency(item.prize)
								)}
							/>
							<TableColumn headerText={t('image')} render={() => ''} />
							<TableColumn
								align="right"
								headerOptions={{ align: 'right' }}
								headerText={t('edit')}
								render={(item) => (
									<DefaultButton
										onClick={() => handleListAction(t('edit'), item)}
										title={t('edit')}
										startIcon={<Edit />}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ListServicesPage;
