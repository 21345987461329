import { PaperContainer } from '@Molecules';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Divider, Grid, useTheme,
} from '@mui/material';
import { DefaultButton, Space, TextField } from '@Atoms';
import {
	Formik,
	Form,
	FormikHelpers,
} from 'formik';
import { PasswordValues } from '.';

interface ChangePasswordProps {
   initialValues: PasswordValues;
   submit: (values: PasswordValues, formikHelpers: FormikHelpers<PasswordValues>) => void;
   validate: (values: PasswordValues) => Partial<PasswordValues>;
   loading: boolean
}

const ChangePasswordPage: FC<ChangePasswordProps> = (props) => {
	const {
		initialValues, submit, validate, loading,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							onSubmit={submit}
							validate={validate}
						>
							{({
								values, handleChange, touched, errors,
							}) => (
								<Form autoComplete="off">
									<TextField
										name="currentPassword"
										type="password"
										placeholder={t('typeYourPassword')}
										label={t('currentPassword')}
										value={values.currentPassword}
										error={touched.currentPassword && Boolean(errors.currentPassword)}
										helperText={touched.currentPassword && errors.currentPassword}
										onChange={handleChange}
										disabled={loading}
									/>
									<Space height={2} />
									<TextField
										name="newPassword"
										type="password"
										placeholder={t('typeNewPassword')}
										value={values.newPassword}
										error={touched.newPassword && Boolean(errors.newPassword)}
										helperText={touched.newPassword && errors.newPassword}
										label={t('newPassword')}
										onChange={handleChange}
										disabled={loading}
									/>

									<Space height={2} />
									<TextField
										name="confirmPassword"
										type="password"
										placeholder={t('confirmYourPassword')}
										value={values.confirmPassword}
										error={touched.confirmPassword && Boolean(errors.confirmPassword)}
										helperText={touched.confirmPassword && errors.confirmPassword}
										label={t('confirmPassword')}
										onChange={handleChange}
										disabled={loading}
									/>

									<Space height={2} />

									<Divider />

									<Space height={2} />

									<DefaultButton title={t('save')} loading={loading} type="submit" />

								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ChangePasswordPage;
