import {
	DefaultButton,
	Dropdown,
	StatusLabel,
	TableItem,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IPagination, IPaginationTable, IQuestion } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface QuestionPageProps {
   questions?: IPagination<IQuestion[]>;
   handleListAction: (action: string, question?: IQuestion) => void;
   onSearching: (query: string) => void;
   onPagination: (pagination: IPaginationTable) => void;
}

const QuestionPage = (props: QuestionPageProps) => {
	const {
		questions, handleListAction, onSearching, onPagination,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const listActions = [t('changeStatus'), t('edit')];

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={questions?.data || []}
							pagination
							total={questions?.total}
							onSearching={onSearching}
							onChange={(page, size) => onPagination({ page, size })}
						>
							<TableColumn headerText="ID" render={(question) => question.id} />
							<TableColumn headerText={t('question')} render={(question) => <TableItem title={question.question} />} />
							<TableColumn headerText={t('answer')} render={(question) => <TableItem title={question.answer} />} />
							<TableColumn headerText={t('language')} render={(question) => <TableItem title={question.language.name} />} />
							<TableColumn headerText={t('status')} render={(question) => <StatusLabel status={question.status} />} />
							<TableColumn
								headerOptions={{ align: 'right' }}
								render={(question) => (
									<Dropdown
										variant="contained"
										label={t('action')}
										data={listActions}
										onChange={(action) => handleListAction(action, question)}
										renderItem={(item) => ({ optionLabel: item, optionValue: item })}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default QuestionPage;
