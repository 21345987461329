import {
	Row, Space, StatusLabel, Image, DefaultButton,
} from '@Atoms';
import { putDecimalPoints } from '@Masks';
import { PaperContainer } from '@Molecules';
import {
	Avatar,
	Box, Button, Divider, Grid, Stack, Typography, useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IOrder } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { formatCurrency, formatDate, formatPercentage } from '@Helpers';
import { FontSizes } from '@Constants';

export interface OrderDetailPageProps {
   order: IOrder | undefined;
}

const OrderDetailPage = (props: OrderDetailPageProps) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const { order } = props;
	const navigate = useNavigate();
	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={6} xl={3} lg={6}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Typography variant="subtitle1">{t('costCenterInfo')}</Typography>
						</Box>
						<Divider />
						<Box sx={{ padding: theme.spacing(1) }}>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('company')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{order?.costCenter.officialName}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('costCenter')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{order?.costCenter.name}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('phone')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{order?.costCenter.phone}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('email')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{order?.costCenter.email}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('address')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{order?.costCenter.address}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('location')}: `}
									{' '}
								</Typography>
								<Typography variant="caption" sx={{ textAlign: 'right' }}>{order?.costCenter.city}</Typography>
							</Row>
						</Box>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={6} xl={3} lg={6}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Typography variant="subtitle1">{`${t('orderInfo')} - #${order?.id}`}</Typography>
						</Box>
						<Divider />
						<Box sx={{ padding: theme.spacing(1) }}>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('status')}: `}
									{' '}
								</Typography>
								<StatusLabel status={order?.status} justify="flex-end" />
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('orderValue')}: `}
									{' '}
								</Typography>
								<Typography variant="body2">{`${putDecimalPoints(order?.grossAmount ?? 0)}€`}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('appliedDiscount')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{`${putDecimalPoints(order?.discount ?? 0)}€`}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('balanceUsed')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{`${putDecimalPoints(order?.usedBalance ?? 0)}€`}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('invoice')}: `}
									{' '}
								</Typography>
								<Typography variant="caption">{`${order?.serie?.toUpperCase() || ''}${order?.invoiceNumber || ''} / ${formatDate(order?.invoiceDate)}`}</Typography>
							</Row>
						</Box>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={6} xl={3} lg={6}>
					<PaperContainer>
						<Stack sx={{ padding: theme.spacing(1) }} direction="row" alignItems="center">
							<Avatar
								src={order?.client?.profileImage}
								sx={{ height: 30, width: 30 }}
							>
								{order?.client.name ? order?.client?.name[0] : ''}
							</Avatar>
							<Typography sx={{ ml: 1 }} variant="subtitle1">{`${t('client')}: ${order?.client.name ?? ''}`}</Typography>
						</Stack>
						<Divider />
						<Box sx={{ padding: theme.spacing(1) }}>
							<Row justify="space-between">
								<Typography variant="caption" color="red">
									{`${t('currentBalance')}: `}
									{' '}
								</Typography>
								<Typography variant="caption" color="red">{`${formatCurrency(order?.currentBalance || 0)}`}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('phone')}: `}
									{' '}
								</Typography>
								<Typography variant="body2">{order?.client.phone ?? ''}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="caption">
									{`${t('email')}: `}
								</Typography>
								<Typography variant="caption">{order?.client.email ?? ''}</Typography>
							</Row>
							<Space height={1} />
							<Divider />
							<Space height={1} />
							<Row justify="space-between">
								<DefaultButton
									variant="outlined"
									title={t('addBalance')}
									onClick={() => navigate(
										AppRoutes.BALENCE_POINTS.formatMap({ id: order?.client.id }),
									)}
								/>
								<DefaultButton
									variant="outlined"
									sx={{ ml: 1 }}
									title={t('sendMessage')}
									onClick={() => navigate(
										AppRoutes.SEND_MESSAGE.formatMap({ id: order?.client.id }),
									)}
								/>
							</Row>
						</Box>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={6} xl={3} lg={6}>
					<PaperContainer>
						<Stack sx={{ padding: theme.spacing(1) }} direction="row" alignItems="center">
							<Avatar
								src={order?.seller?.profileImage}
								sx={{ height: 30, width: 30 }}
							>
								{order?.seller?.name ? order?.seller?.name[0] : ''}
							</Avatar>
							<Typography sx={{ ml: 1 }} variant="subtitle1">{`${t('seller')}: ${order?.seller.name ?? ''}`}</Typography>
						</Stack>
						<Divider />
						<Box sx={{ padding: theme.spacing(1) }}>
							<Row justify="space-between">
								<Typography variant="body2">
									{`${t('phone')}: `}
									{' '}
								</Typography>
								<Typography variant="body2">{order?.seller?.phone ?? ''}</Typography>
							</Row>
							<Row justify="space-between">
								<Typography variant="body2">
									{`${t('email')}: `}
									{' '}
								</Typography>
								<Typography variant="body2">{order?.seller?.email ?? ''}</Typography>
							</Row>
						</Box>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Typography variant="subtitle1">{`${t('generatedTransaction')}`}</Typography>
						</Box>
						<Grid container sx={{ padding: theme.spacing(1) }} spacing={2}>
							<Grid item xs={12} md={12}>
								<DataTable search={false} data={order?.transactions || []}>
									<TableColumn headerText={t('description')} render={(item) => `${item.description ?? ''}`} />
									<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
									<TableColumn headerText={t('value')} render={(item) => `${formatCurrency(item.amount ?? '')}`} />
								</DataTable>
							</Grid>
						</Grid>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Box sx={{ padding: theme.spacing(1) }}>
							<Typography variant="subtitle1">{`${t('couponsUsedInPurchase')}`}</Typography>
						</Box>
						<Divider />
						<Space height={2} />
						<Grid container sx={{ padding: theme.spacing(1) }} spacing={2}>
							{order?.couponUsers.map((item) => (
								<Grid item xs={12} md={6} lg={4}>
									<Box style={{ border: '1px solid grey', borderRadius: '5px' }}>
										<Image
											height={200}
											width="100%"
											src={item.banner}
											alt={`coupon-item-${item.id}`}
										/>
										<Box sx={{ padding: theme.spacing(2) }}>
											<Typography variant="body2">{item.title}</Typography>
											<Space height={1} />
											<Typography color="grey" variant="caption">
												{item.description}
											</Typography>
											<Row justify="flex-start">
												<Typography variant="body2" sx={{ mr: 1 }}>
													{`${t('type')}: `}
													{' '}
												</Typography>
												<span style={{
													backgroundColor: theme.palette.background.default,
													color: 'white',
													paddingLeft: 6,
													paddingRight: 6,
													paddingTop: 4,
													paddingBottom: 4,
													fontSize: FontSizes.SM,
													borderRadius: '0.25rem',
													fontWeight: 'bold',
												}}
												>
													{item.category}
												</span>
											</Row>
											<Row justify="flex-start">
												<Typography variant="body2">
													{`${t('bonus')}: `}
												</Typography>
												<Typography sx={{ ml: 1 }} variant="body2">
													{item.isPercentage
														? formatPercentage(item.amount)
														: formatCurrency(item.amount)}

												</Typography>
											</Row>
											<Space height={2} />
											<Divider />
											<Space height={1} />
											<Button
												size="small"
												variant="outlined"
												id="basic-button1"
												onClick={() => {
													navigate(`/coupon/edit/${item.couponId}`);
												}}
											>
												{t('accessCoupon')}
											</Button>
										</Box>
									</Box>
								</Grid>
							))}
						</Grid>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default OrderDetailPage;
