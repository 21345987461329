import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface DescriptionProps extends TypographyProps {
	text: string
}

const Description: React.FC<DescriptionProps> = (props) => {
	const {
		text, variant = 'caption', fontWeight = 'light',
	} = props;
	return <Typography {...props} variant={variant} fontWeight={fontWeight}>{text}</Typography>;
};

export default Description;
