import {
	ApiResponse,
	IPagination,
	ITag,
	IListDataControl,
	ITagReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getTags = async (props: IListDataControl):
Promise<ApiResponse<IPagination<ITag[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.TAGS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<ITag[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getTagById = async (tagId: string): Promise<ApiResponse<ITag>> => {
	const resp = await reqGet({
		url: Endpoint.TAG_BY_ID.format(tagId),
	});
	const data = resp.data as ApiResponse<ITag>;
	data.statusCode = resp.status;
	return data;
};

export const addTag = async (tag: ITagReq): Promise<ApiResponse<ITag>> => {
	const resp = await reqPost({
		url: Endpoint.ADD_TAG,
		data: { ...tag },
	});
	const data = resp.data as ApiResponse<ITag>;
	data.statusCode = resp.status;
	return data;
};

export const editTag = async (tagId: string, tag: ITagReq): Promise<ApiResponse<ITag>> => {
	const resp = await reqPost({
		url: Endpoint.TAG_BY_ID.format(tagId),
		data: { ...tag },
	});
	const data = resp.data as ApiResponse<ITag>;
	data.statusCode = resp.status;
	return data;
};
