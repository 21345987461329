import { Footer } from '@Atoms';
import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import { Box } from '@mui/material';
import { Sidebar } from '@Organisms';
import { useAuth, useTitleBar } from '@Providers';
import { logout } from '@Requests';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { ErrorBoundary } from '@Error';
import { withSplashScreen } from '@Hocs';

const Main = () => {
	const { setCurrentUser } = useAuth();
	const { setGlobalLoading } = useTitleBar();

	const logoutUser = () => {
		setGlobalLoading(true);
		logout()
			.then(() => {
				LocalStorageWrapper.remove(LocalStorageKeys.USER);
				LocalStorageWrapper.remove(LocalStorageKeys.REFRESH_TOKEN);
				setCurrentUser(null);
			})
			.catch((err) => {
				throw err;
			})
			.finally(() => setGlobalLoading(false));
	};
	return (
		<Box sx={{ display: 'flex' }}>
			<Sidebar logoutUser={logoutUser} />
			<Box component="main" sx={{ flexGrow: 1, marginTop: 8 }}>
				<Box sx={{ minHeight: '93vh' }}>
					<ErrorBoundary>
						<Outlet />
					</ErrorBoundary>
				</Box>
				<Footer />
			</Box>
		</Box>
	);
};

export default withSplashScreen(Main);
