import React, { useEffect, useState } from 'react';
import { getDashboardData } from '@Requests';
import { IDashboardSummary } from '@Types';
import { useFeedback, useTitleBar } from '@Providers';
import { useTranslation } from 'react-i18next';
import { useEffectCustom } from '@Hooks';
import DashboardPage from './DashboardPage';

const Dashboard = () => {
	const [data, setData] = useState<IDashboardSummary>();
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();
	const abortCtrl = new AbortController();

	useEffect(() => {
		setTitle(t('dashboard'));
	}, [t, setTitle]);

	useEffectCustom(() => {
		const getData = () => {
			setGlobalLoading(true);
			getDashboardData(abortCtrl.signal)
				.then((res) => {
					setData(res.data);
				})
				.catch(({ response }) => { addToast({ message: response.data.message, error: true }); })
				.finally(() => setGlobalLoading(false));
		};
		getData();
		return () => {
			abortCtrl.abort();
		};
	}, []);

	return <DashboardPage data={data} />;
};

export default Dashboard;
