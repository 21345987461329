import { useRequest } from '@Api';
import { exportMultiTabbed } from '@Export';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { getOrganizations, getSalesReport, getPartnerSellers } from '@Requests';
import {
	IListDataControl,
	INameAndId,
	IOrganization,
	ISaleReportParams,
} from '@Types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SalesReportPage from './SalesReportPage';

const FIRST_DAY_OF_CURRENT_MONTH = new Date(new Date().setDate(1)); // get 1st day of the month

const SalesReport = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');

	const organizations = useRequest(([params, signal]) => getOrganizations(params, signal));
	const salesReport = useRequest(([params]) => getSalesReport(params));
	const sellers = useRequest(() => getPartnerSellers());

	const [salesInputValues, setSalesInputValues] = useState<ISaleReportParams>({
		initialDate: FIRST_DAY_OF_CURRENT_MONTH,
		finalDate: new Date(),
		statusId: 2,
	});

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const listDataControl: IListDataControl = {
				page: 1,
				size: 1000000,
				search: '',
			};
			organizations.execute(listDataControl, abortCtrl.signal);
			sellers.execute();
		};
		getData();
		return () => abortCtrl.abort();
	}, []);

	useEffect(() => {
		setTitle(`${t('reports')} - ${t('sales')}`);
	}, [setTitle, t]);

	const handleChange = (key: string, value: Date |
		IOrganization | INameAndId | number | undefined | null) => {
		setSalesInputValues({ ...salesInputValues, [key]: value });
	};

	const getSales = () => {
		salesReport.execute(salesInputValues);
	};
	const exportExcel = () => {
		const header = ['ID', t('date'), t('seller'), t('client'), t('invoice'), t('baseValue'), t('discount'), t('balenceUsed'), t('finalValue'), t('refund'), t('status')];
		exportMultiTabbed(`${t('sales')}-${moment(new Date()).format('DD-MM-YYYY HH:mm')}.xlsx`, header, salesReport.data ?? []);
	};

	return (
		<SalesReportPage
			organizations={organizations.data?.data}
			sellers={sellers.data}
			salesReport={salesReport.data}
			onFilterClick={getSales}
			onChange={handleChange}
			initialValues={salesInputValues}
			loading={salesReport.loading}
			exportExcel={exportExcel}
		/>
	);
};

export default SalesReport;
