import {
	DefaultButton,
	Image as ImageComponent,
	Space,
	TextField,
	Autocomplete,
	Description,
} from '@Atoms';
import { placeholderCard } from '@Images';
import { PaperContainer } from '@Molecules';
import {
	Box,
	Grid, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IOrganization, INameAndId } from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import { ICardReq } from 'data/models/card.model';
import { currency } from '@Masks';
import { getImageURL } from '@Helpers';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { ArrowBack, Edit } from '@mui/icons-material';

// const CARD_HEIGHT = 334;
// const CARD_WIDTH = 680;

/** LIMIT LESS CARD has no limit */
const LIMIT_LESS = 3;
/** EXTERNAL CARD has no category and limit */
const EXTERNAL = 2;

interface CardPageProps {
	organizations?: IOrganization[];
	cardTypes?: INameAndId[];
	billingTypes?: INameAndId[];
	categories?: INameAndId[];
	card: ICardReq;
	validate: (values: ICardReq) => FormikErrors<ICardReq>;
	onSubmit: (values: ICardReq, helpers: FormikHelpers<ICardReq>) => void;
	readOnly: boolean;
	isEdit: boolean;
	handleEditClick: () => void
}

const CreateCardPage: React.FC<CardPageProps> = (props) => {
	const {
		organizations,
		cardTypes,
		billingTypes,
		categories,
		card,
		validate,
		onSubmit,
		readOnly,
		isEdit,
		handleEditClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const imageRef = React.useRef<HTMLInputElement | null>(null);

	return (
		<div>

			<Grid container sx={{ padding: theme.spacing(3) }} spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton
						title={t('back')}
						startIcon={<ArrowBack />}
						onClick={() => navigate(AppRoutes.CARDS)}
					/>
				</Grid>
				<Grid item md={12} xs={12}>
					<PaperContainer>
						<Formik
							initialValues={card}
							onSubmit={onSubmit}
							validate={validate}
							validateOnBlur={false}
							validateOnMount={false}
							enableReinitialize
						>
							{({
								values, handleChange, setFieldValue, errors, isSubmitting, touched,
							}) => (
								<Form autoComplete="off">
									<input
										type="file"
										name="image"
										accept="image/png, image/jpeg"
										ref={imageRef}
										style={{ display: 'none' }}
										disabled={readOnly || !isEdit}
										onChange={(e) => {
											const ev = e.currentTarget.files;
											if (ev && ev.length > 0) {
												setFieldValue('cardImage', ev[0]);
											}
										}}
									/>

									<Grid container spacing={2}>
										<Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
											<TextField
												label={t('name')}
												name="name"
												value={values.name}
												onChange={handleChange}
												error={Boolean(touched.name && errors.name)}
												helperText={touched.name && errors.name}
												disabled={isSubmitting || readOnly || !isEdit}
											/>
											<Space height={2} />
											<TextField
												label={t('description')}
												name="description"
												value={values.description}
												onChange={handleChange}
												error={Boolean(touched.description && errors.description)}
												helperText={touched.description && errors.description}
												disabled={isSubmitting || readOnly || !isEdit}
											/>
											<Space height={2} />
											<TextField
												label="Webhook URI"
												name="webhookUri"
												error={Boolean(touched.webhookUri && errors.webhookUri)}
												helperText={touched.webhookUri && errors.webhookUri}
												value={values.webhookUri}
												onChange={handleChange}
												disabled={isSubmitting || readOnly || !isEdit}
											/>
											<Space height={2} />
											<TextField
												label="Webhook Headers"
												name="webhookHeaders"
												value={values.webhookHeaders}
												onChange={handleChange}
												error={Boolean(touched.webhookHeaders && errors.webhookHeaders)}
												helperText={touched.webhookHeaders && errors.webhookHeaders}
												disabled={isSubmitting || readOnly || !isEdit}
											/>
											<Description text={t('webhookHelper')} />
										</Grid>
										<Grid item lg={6} xl={6} md={12} xs={12} sm={12}>
											<Box
												onClick={() => imageRef.current?.click()}
												aria-hidden
												sx={{
													height: 167,
													width: 340,
													'&:hover': {
														cursor: 'pointer',
													},
												}}
											>
												<ImageComponent
													src={values.cardImage ? getImageURL(values.cardImage) : placeholderCard}
													height={167}
													width={340}
													alt="card placeholder"
													style={{ borderRadius: 8 }}
												/>
											</Box>
											<Typography variant="caption" color="GrayText">{t('imageDiemensions')}</Typography>
											<Description sx={{ ml: 2 }} text={errors.cardImage || ''} color="red" />
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item lg={3} xl={3} md={6} xs={12} sm={12}>
											<Autocomplete
												label={t('type')}
												placeholder=""
												options={cardTypes || []}
												error={Boolean(touched.cardTypeId && errors.cardTypeId)}
												helperText={touched.cardTypeId && String(errors.cardTypeId || '')}
												defaultValue={values.cardTypeId}
												getOptionLabel={(item) => item.name || ''}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderOption={(AutoCompleteprops, item) => (
													<li {...AutoCompleteprops}>{item.name}</li>
												)}
												onChange={(_, item) => {
													setFieldValue('cardTypeId', item);
												}}
												disabled={isSubmitting || readOnly || !isEdit}
											/>
										</Grid>
									</Grid>
									<Space height={2} />

									<Grid container spacing={2}>
										<Grid item lg={3} xl={3} md={4} sm={12} xs={12}>
											<Autocomplete
												label={t('invoiceType')}
												placeholder=""
												options={billingTypes || []}
												error={Boolean(touched.billingTypeId && errors.billingTypeId)}
												helperText={touched.billingTypeId && String(errors.billingTypeId || '')}
												getOptionLabel={(item) => item.name || ''}
												defaultValue={values.billingTypeId}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderOption={(AutoCompleteprops, item) => (
													<li {...AutoCompleteprops}>{item.name}</li>
												)}
												onChange={(_, item) => setFieldValue('billingTypeId', item)}
												disabled={isSubmitting || readOnly || !isEdit}
											/>
										</Grid>
										{values?.cardTypeId?.id !== EXTERNAL && (
											<>
												<Grid item lg={3} xl={3} md={4} sm={12} xs={12}>
													<Autocomplete
														label={t('category')}
														placeholder=""
														options={categories || []}
														defaultValue={values.categoryId}
														error={Boolean(touched.categoryId && errors.categoryId)}
														helperText={touched.categoryId && String(errors.categoryId || '')}
														getOptionLabel={(item) => item.name || ''}
														isOptionEqualToValue={(option, value) => option.id === value.id}
														renderOption={(AutoCompleteprops, item) => (
															<li {...AutoCompleteprops}>{item.name}</li>
														)}
														onChange={(_, item) => {
															setFieldValue('categoryId', item);
														}}
														disabled={isSubmitting || readOnly || !isEdit}
													/>
												</Grid>
												<Grid item lg={3} xl={3} md={4} sm={12} xs={12}>
													{values?.categoryId?.id !== LIMIT_LESS && (
														<TextField
															label={t('limitEuro')}
															value={values.cardLimit}
															name="cardLimit"
															onChange={(e) => handleChange(currency(e))}
															error={Boolean(touched.cardLimit && errors.cardLimit)}
															helperText={touched.cardLimit && errors.cardLimit}
															disabled={isSubmitting || readOnly || !isEdit}
														/>
													)}
												</Grid>
											</>
										)}
									</Grid>
									<Space height={2} />
									<Grid container spacing={2}>
										<Grid item lg={4} xl={4} md={6} sm={12} xs={12}>
											<Autocomplete
												label={t('belongingOrganization')}
												placeholder=""
												options={organizations || []}
												error={Boolean(touched.organizationId && errors.organizationId)}
												helperText={touched.organizationId && String(errors.organizationId || '')}
												defaultValue={
													organizations?.find((el) => el.id === values.organizationId?.id)
												}
												getOptionLabel={(item) => item.officialName || ''}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderOption={(AutoCompleteprops, item) => (
													<li {...AutoCompleteprops}>{item.officialName}</li>
												)}
												onChange={(_, item) => setFieldValue('organizationId', item)}
												disabled={isSubmitting || readOnly || !isEdit}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									{isEdit && <DefaultButton title={t('save')} type="submit" disabled={readOnly} loading={isSubmitting} />}
									{!isEdit && <DefaultButton title={t('edit')} startIcon={<Edit />} onClick={handleEditClick} disabled />}
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateCardPage;
