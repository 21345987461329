import { Checkbox as MuiCheckbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { FC } from 'react';

interface CheckboxProps {
	label: string;
	checked: boolean;
	disabled?: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({
	label, checked, onChange, disabled,
}) => (
	<FormGroup>
		<FormControlLabel
			disabled={disabled}
			control={<MuiCheckbox onChange={onChange} checked={checked} />}
			label={label}
		/>
	</FormGroup>
);

export default Checkbox;
