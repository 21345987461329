import {
	normalize,
	normalizePhone,
	replaceComma,
} from '@Masks';
import {
	ApiResponse,
	IBarcodeRes,
	IListDataControl,
	IOrganization,
	IOrganizationReq,
	IPagination,
	IService,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getPartners = async (params: IListDataControl):
	Promise<ApiResponse<IPagination<IOrganization[]>>> => {
	const { page, size, search } = params;
	const resp = await reqGet({
		url: Endpoint.PARTNERS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<IOrganization[]>>;
	data.statusCode = resp.status;
	return data;
};

export const getPartnerById = async (partnerId: string): Promise<ApiResponse<IOrganization>> => {
	const resp = await reqGet({
		url: Endpoint.PARTNER_ID.format(partnerId),
	});
	const data = resp.data as ApiResponse<IOrganization>;
	data.statusCode = resp.status;
	return data;
};

export const getPartnerSevices = async (partnerId: string):
	Promise<ApiResponse<IPagination<IService[]>>> => {
	const resp = await reqGet({
		url: Endpoint.PARTNER_SERVICES.format(partnerId),
	});
	const data = resp.data as ApiResponse<IPagination<IService[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const addPartner = async (partner: IOrganizationReq):
	Promise<ApiResponse<IOrganization[]>> => {
	const partnerToUpload = {
		...partner,
		chainStoreId: partner.chainStoreId.id,
		countryId: partner.countryId?.id,
		cityId: partner.countryId.id === 1 ? partner.cityId?.id : 1,
		stateId: partner.countryId.id === 1 ? partner.stateId?.id : 1,
		taxId: normalize(partner.taxId),
		longitude: replaceComma(partner.longitude),
		latitude: replaceComma(partner.latitude),
		postalCode: normalize(partner.postalCode),
		phone: normalizePhone(partner.phone),
	};
	const resp = await reqPost({
		url: Endpoint.CREATE_PARTNER,
		data: { ...partnerToUpload },
	});
	const data = resp.data as ApiResponse<IOrganization[]>;
	data.statusCode = resp?.status;
	return data;
};

export const editPartner = async (partnerId: string, partner: IOrganizationReq):
	Promise<ApiResponse<IOrganization>> => {
	const partnerToUpload = {
		...partner,
		cityId: partner.countryId.id === 1 ? partner.cityId?.id : 1,
		countryId: partner.countryId?.id,
		stateId: partner.countryId.id === 1 ? partner.stateId?.id : 1,
		taxId: normalize(partner.taxId),
		longitude: replaceComma(partner.longitude),
		latitude: replaceComma(partner.latitude),
		postalCode: normalize(partner.postalCode),
		phone: normalizePhone(partner.phone),
		chainStoreId: partner.chainStoreId.id,
	};
	const resp = await reqPost({
		url: Endpoint.PARTNER_ID.format(partnerId),
		data: { ...partnerToUpload },
	});
	const data = resp.data as ApiResponse<IOrganization>;
	data.statusCode = resp?.status;
	return data;
};

export const changePartnerStatus = async (partnerId: number):
	Promise<ApiResponse<IOrganization>> => {
	const resp = await reqPost({
		url: Endpoint.PARTNER_STATUS.format(partnerId),
	});
	const data = resp.data as ApiResponse<IOrganization>;
	data.statusCode = resp?.status;
	return data;
};

export const getPartnerBarCode = async (partnerId: number): Promise<ApiResponse<IBarcodeRes>> => {
	const resp = await reqGet({
		url: Endpoint.PARTNER_BARCODE.format(partnerId),
	});
	const data = resp.data as ApiResponse<IBarcodeRes>;
	data.statusCode = resp.status;
	return data;
};
