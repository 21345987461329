import { ApiResponse, IRating } from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getRatings = async (): Promise<ApiResponse<IRating[]>> => {
	const resp = await reqGet({
		url: Endpoint.GET_RATINGS,
	});
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const getRatingById = async (id?: string): Promise<ApiResponse<IRating>> => {
	const resp = await reqGet({
		url: Endpoint.EDIT_RATING.format(id),
	});
	const data = resp.data as ApiResponse<IRating>;
	data.statusCode = resp?.status;
	return data;
};

export const editRating = async (model: IRating): Promise<ApiResponse<IRating>> => {
	const resp = await reqPost({
		url: Endpoint.EDIT_RATING.format(model.id),
		data: { ratingId: model.id, ...model },
	});
	const data = resp.data as ApiResponse<IRating>;
	data.statusCode = resp?.status;
	return data;
};
