export const getHashCode = (src: string) => {
	let hash = 0;
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < src.length; i++) {
		const char = src.charCodeAt(i);
		// eslint-disable-next-line no-bitwise
		hash = ((hash << 5) - hash) + char;
		// eslint-disable-next-line no-bitwise
		hash &= hash;
	}
	return hash;
};

export const getFingerprint = (): number => {
	const canvas = document.getElementById('myCanvas') as HTMLCanvasElement;
	if (!canvas) return getHashCode(`_${Math.random().toString(36).slice(2, 9)}`);
	const ctx = canvas.getContext('2d');
	if (!ctx) return getHashCode(`_${Math.random().toString(36).slice(2, 9)}`);

	ctx.fillStyle = 'rgb(255,0,255)';
	ctx.beginPath();
	ctx.rect(20, 20, 150, 100);
	ctx.fill();
	ctx.stroke();
	ctx.closePath();
	ctx.beginPath();
	ctx.fillStyle = 'rgb(0,255,255)';
	ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
	ctx.fill();
	ctx.stroke();
	ctx.closePath();

	const txt = 'abz190#$%^@£éú';
	ctx.textBaseline = 'top';
	ctx.font = '17px "Arial 17"';
	ctx.textBaseline = 'alphabetic';
	ctx.fillStyle = 'rgb(255,5,5)';
	ctx.rotate(0.03);
	ctx.fillText(txt, 4, 17);
	ctx.fillStyle = 'rgb(155,255,5)';
	ctx.shadowBlur = 8;
	ctx.shadowColor = 'red';
	ctx.fillRect(20, 12, 100, 5);

	// hashing function
	const src = canvas.toDataURL();
	return getHashCode(src);
};
