import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { SelectChangeEvent } from '@mui/material';
import { useFeedback, useTitleBar } from '@Providers';
import {
	addUserTag, deleteUserTag, getTags, getUserTags,
} from '@Requests';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import UserTagsPage from './TagsPage';

const UserTags = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();

	const { id } = useParams<{ id: string }>();

	const tags = useRequest(([params]) => getTags(params));
	const userTags = useRequest(([params]) => getUserTags(params));
	const [selectedTag, setSelectedTag] = useState<number>(1);
	const [selectedTagError, setSelectedTagError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		setTitle(t('tags'));
		tags.execute({ page: 1, size: 100, search: '' });
		userTags.execute(id);
	}, []);

	const handleChange = (event: SelectChangeEvent<string>) => {
		const value: number = typeof event.target.value === 'string' ? parseInt(event.target.value, 10) : event.target.value;
		setSelectedTag(value);
		if (value) {
			setSelectedTagError('');
		}
	};

	const handleSubmit = () => {
		if (!selectedTag) {
			return setSelectedTagError(t('selectTag'));
		}
		setLoading(true);
		addUserTag(id, selectedTag)
			.then((res) => {
				setSelectedTag(1);
				userTags.setData((prev) => {
					const mPrev = prev;
					mPrev?.push(res.data);
					return mPrev;
				});
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => setLoading(false));
		return null;
	};
	const handleDelete = (tagId: number) => {
		deleteUserTag(id, tagId)
			.then(() => {
				userTags.setData((prev) => {
					if (prev) {
						const mPrev = [...prev];
						if (mPrev) {
							const index = mPrev.findIndex((el) => el.id === tagId);
							mPrev.splice(index, 1);
						}
						return mPrev;
					}
					return prev;
				});
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			});
	};

	return (
		<UserTagsPage
			tags={tags.data?.data}
			selectedTag={selectedTag}
			selectedTagError={selectedTagError}
			handleChange={handleChange}
			loading={loading}
			handleSubmit={handleSubmit}
			userTags={userTags.data}
			handleDelete={handleDelete}
		/>
	);
};

export default UserTags;
