import {
	DateLabel, StatusLabel, Space, TableItem, DefaultButton,
} from '@Atoms';
import { FontSizes } from '@Constants';
import { PaperContainer } from '@Molecules';
import { PersonAddAlt1, Phone } from '@mui/icons-material';
import {
	Divider, Grid, Tab, Tabs, useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import {
	IPagination, IPaginationTable, IReferral, IStatus,
} from '@Types';
import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ReferralsListPageProps {
   referrals: IPagination<IReferral[]> | undefined;
   onPageChange: (pagination: IPaginationTable) => void;
   onSearch: (query: string) => void;
   onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
   selectedTab: number;
   resetPagination: MutableRefObject<() => void>
}

const ReferralsListPage = (props: ReferralsListPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		referrals, onPageChange, onSearch, onTabChange, selectedTab, resetPagination,
	} = props;
	const navigate = useNavigate();

	const generateStatus = (referral: IReferral): IStatus => {
		const { seller } = referral;
		const status: IStatus = {
			id: 1,
			name: seller ? seller.name : t('noRelationship'),
			localizableKey: '',
			styleIdentifier: '',
			registration: '',
			color: seller ? 'grey' : 'red',
		};
		return status;
	};

	const showDetails = (referral: IReferral) => {
		navigate('/referrals/{0}/details'.format(referral.id));
	};

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={2}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Tabs onChange={onTabChange} value={selectedTab} aria-label="Tabs where each tab needs to be selected manually">
							<Tab label={t('new')} />
							<Tab label={t('contacted')} />
							<Tab label={t('scheduledVisit')} />
							<Tab label={t('proposalSent')} />
							<Tab label={t('finished')} />
							<Tab label={t('canceled')} />
							<Tab label={t('pending')} />
						</Tabs>
						<Divider />
						<Space height={2} />
						<DataTable
							data={referrals?.data ?? []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={referrals?.total}
							resetPagination={resetPagination}
						>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('registration')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn
								headerText={t('referrer')}
								render={(item) => (
									<TableItem
										title={item.fromUser?.name}
										subTitle={`${item.relationship?.name} / ${item.fromUser?.phone}`}
									/>
								)}
							/>
							<TableColumn
								headerText={t('referred')}
								render={(item) => (
									<TableItem
										title={item.name}
										subTitle={item.phone}
										subTitleIcon={<Phone sx={{ fontSize: FontSizes.SM }} />}
									/>
								)}
							/>
							<TableColumn
								headerText={`${t('responsible')}/${t('status')}`}
								render={(item) => (
									<TableItem title={item.status.name} subTitle=" " subTitleIcon={<StatusLabel status={generateStatus(item)} />} />
								)}
							/>
							<TableColumn
								headerText={t('details')}
								render={(item) => (
									<DefaultButton
										startIcon={<PersonAddAlt1 />}
										title={t('details')}
										onClick={() => showDetails(item)}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default ReferralsListPage;
