import { DateLabel, DefaultButton } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IPagination, IPaginationTable, ITerm } from '@Types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';

export interface TermsListPageProps {
   terms: IPagination<ITerm[]> | undefined;
   onPageChange: (pagination: IPaginationTable) => void;
   onSearch: (query: string) => void;
}

const TermsListPage = (props: TermsListPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { terms, onPageChange, onSearch } = props;
	const navigate = useNavigate();

	const handleListAction = (action: string, item?: ITerm) => {
		switch (action) {
		case t('new'): {
			navigate('/terms/create');
			break;
		}
		case t('edit'): {
			navigate('/terms/{0}/edit'.format(item?.id));
			break; }
		default:
			break;
		}
	};

	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={terms?.data || []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={terms?.total}
						>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('type')} render={(item) => `${item.category.name}`} />
							<TableColumn headerText={t('version')} render={(item) => `${item.version}`} />
							<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.registration} showTime />} />
							<TableColumn
								headerText={t('')}
								render={(article) => (
									<DefaultButton
										title={t('edit')}
										onClick={() => handleListAction(t('edit'), article)}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default TermsListPage;
