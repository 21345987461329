import { IOrder, IOrderReq } from '@Types';
import React from 'react';
import {
	Grid, useTheme, Typography, Stack, Divider,
} from '@mui/material';
import { PaperContainer } from '@Molecules';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@Helpers';
import {
	DefaultButton, Space, StatusLabel, TextField,
} from '@Atoms';
import { acceptNumbers } from '@Masks';

interface OrderFixProps{
	order?: IOrder;
	initialValues: IOrderReq;
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
	onSubmit: () => void;
	loading: boolean
}

const OrderFixPage: React.FC<OrderFixProps> = (props) => {
	const {
		order, initialValues, onChange, onSubmit, loading,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={2}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Typography variant="h6">
							{t('client')}
							:
							{' '}
							{order?.client?.name}
						</Typography>
						<Typography variant="h6">
							{t('purchaseMadeAt')}
							:
							{' '}
							{order?.costCenter?.fantasyName}
							{' '}
							(
							{order?.costCenter?.name}
							)
						</Typography>
						<Typography variant="h6">
							{t('grossAmount')}
							:
							{' '}
							{formatCurrency(order?.grossAmount || 0)}
						</Typography>
						<Stack direction="row" justifyContent="flex-start" alignItems="center">
							<Typography variant="h6" sx={{ mr: 1 }}>
								{t('status')}
								:
								{' '}
							</Typography>
							<StatusLabel status={order?.status} />
						</Stack>
						<Space height={1} />
						<Divider />
						<Space height={3} />
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<TextField
									name="invoiceNumber"
									label={t('billNumber')}
									inputProps={{ maxLength: 12 }}
									value={initialValues.invoiceNumber}
									onChange={(e) => onChange(acceptNumbers(e))}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									name="year"
									label={t('year')}
									inputProps={{ maxLength: 4 }}
									value={initialValues.year}
									onChange={(e) => onChange(acceptNumbers(e))}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									name="serie"
									label={t('series')}
									value={initialValues.serie}
									onChange={onChange}
								/>
							</Grid>
						</Grid>
						<Space height={3} />
						<Divider />
						<Space height={3} />
						<DefaultButton title={t('save')} onClick={onSubmit} loading={loading} />
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default OrderFixPage;
