import { DateLabel, StatusLabel } from '@Atoms';
import { DataTable, TableColumn } from '@Organisms';
import { ITicket } from '@Types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HistoryProps {
	ticket?: ITicket
}

const HistoryTable: React.FC<HistoryProps> = (props) => {
	const { ticket } = props;
	const { t } = useTranslation('translations');
	return (
		<DataTable data={ticket?.statusHistory || []} search={false}>
			<TableColumn headerText={t('user')} render={(item) => item.user.name} />
			<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.registration} showTime />} />
			<TableColumn headerText={t('status')} headerOptions={{ align: 'right' }} align="right" render={(item) => <StatusLabel status={item.status} justify="flex-end" />} />
		</DataTable>
	);
};

export default HistoryTable;
