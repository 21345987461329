import { useRequest } from '@Api';
import { useTitleBar } from '@Providers';
import { getTags } from '@Requests';
import { IListDataControl, IPaginationTable, ITag } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import ListTagsPage from './ListTagsPage';

const ListTags = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const tags = useRequest(([params]) => getTags(params));

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => { setTitle(t('tags')); }, [setTitle, t]);

	useEffectCustom(() => {
		const listControl: IListDataControl = {
			page: pagination.page,
			size: pagination.size,
			search,
		};
		tags.execute(listControl);
	}, [pagination.page, pagination.size, search]);

	const handleButtonClick = (action: string, tag?: ITag) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.CREATE_TAG);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_TAGS.formatMap({ id: tag?.id }));
			break;
		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListTagsPage
			tags={tags.data}
			handleListAction={handleButtonClick}
			onSearch={setTableSearch}
			onPagination={setTablePagination}
		/>
	);
};

export default ListTags;
