import {
	Autocomplete, DefaultButton, DefaultSelect, Space, TextField,
} from '@Atoms';
import { DROPDOWN_ACTION } from '@Constants';
import {
	latitude, longitude, phone, postCode, taxId,
} from '@Masks';
import { PaperContainer } from '@Molecules';
import { ArrowBack, Edit } from '@mui/icons-material';
import { Divider, Grid, useTheme } from '@mui/material';
import {
	IChainStore,
	ICities, ICountry, IOrganizationReq, IStates,
} from '@Types';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CreatePartnerProps{
	partner: IOrganizationReq;
	countries?: ICountry[];
	states?: IStates[];
	cities?: ICities[];
	isEdit?: boolean;
	chainStores?: IChainStore[];
	requestById: (action: string, id: number) => void;
	validate: (partner: IOrganizationReq) => FormikErrors<IOrganizationReq>
	submit: (partner: IOrganizationReq, formikActions: FormikHelpers<IOrganizationReq>) => void;
	handleEditClick: () => void;
	handleBackClick: () => void
}

const ESTABLISHMENT = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const CreatePartnerPage: React.FC<CreatePartnerProps> = (props) => {
	const {
		partner,
		countries,
		states,
		cities,
		isEdit,
		chainStores,
		requestById,
		validate,
		submit,
		handleEditClick,
		handleBackClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('back')} startIcon={<ArrowBack />} onClick={handleBackClick} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Formik
							initialValues={partner}
							validate={validate}
							enableReinitialize
							validateOnBlur={false}
							validateOnMount={false}
							onSubmit={(values, helpers) => {
								submit(values, helpers);
							}}
						>
							{({
								handleChange,
								setFieldValue,
								errors,
								isSubmitting,
								values,
								touched,
							}) => (
								<Form autoComplete="off">
									<Grid container spacing={3}>
										<Grid item xs={12} md={3}>
											<Autocomplete
												disabled={isSubmitting || !isEdit}
												label={t('network')}
												placeholder=""
												defaultValue={values.chainStoreId || null}
												options={chainStores || []}
												renderOption={(inputprops, item) => <li {...inputprops}>{item.name}</li>}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												getOptionLabel={(item) => item.name || ''}
												onChange={(_, item) => {
													setFieldValue('chainStoreId', item);
												}}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												disabled={isSubmitting || !isEdit}
												label={t('socialReason')}
												name="officialName"
												value={values.officialName}
												onChange={handleChange}
												error={Boolean(touched.officialName && errors.officialName)}
												helperText={touched.officialName && errors.officialName}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('Abbreviation')}
												name="fantasyName"
												value={values.fantasyName}
												onChange={handleChange}
												error={Boolean(touched.fantasyName && errors.fantasyName)}
												helperText={touched.fantasyName && errors.fantasyName}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('taxPayerNumber')}
												name="taxId"
												value={values.taxId}
												onChange={(event) => setFieldValue('taxId', taxId(event).target.value)}
												error={Boolean(touched.taxId && errors.taxId)}
												helperText={touched.taxId && errors.taxId}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={3}>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('alternativeName')}
												name="alternativeName"
												value={values.alternativeName}
												onChange={handleChange}
												error={Boolean(touched.alternativeName && errors.alternativeName)}
												helperText={touched.alternativeName && errors.alternativeName}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<DefaultSelect
												options={ESTABLISHMENT || []}
												label={t('establishment')}
												value={values.establishmentNumber}
												error={Boolean(touched.establishmentNumber && errors.establishmentNumber)}
												helperText={errors.establishmentNumber}
												disabled={isSubmitting || !isEdit}
												renderItem={(item) => ({ optionLabel: item.toString(), optionValue: item })}
												onChange={(event) => {
													setFieldValue('establishmentNumber', event.target.value);
												}}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('email')}
												name="email"
												value={values.email}
												onChange={handleChange}
												error={Boolean(touched.email && errors.email)}
												helperText={touched.email && errors.email}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('contactNumber')}
												name="phone"
												value={values.phone}
												onChange={(event) => setFieldValue('phone', phone(event).target.value)}
												error={Boolean(touched.phone && errors.phone)}
												helperText={touched.phone && errors.phone}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={3}>

										<Grid item xs={12} md={3}>
											<Autocomplete
												label={t('place')}
												placeholder=""
												options={countries || []}
												defaultValue={values.countryId || null}
												renderOption={(inputprops, item) => <li {...inputprops}>{item.name}</li>}
												getOptionLabel={(item) => item.name || ''}
												disabled={isSubmitting || !isEdit}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												onChange={(_, item) => {
													setFieldValue('countryId', item || null);
													setFieldValue('stateId', undefined);
													setFieldValue('cityId', undefined);
													if (item) {
														requestById(DROPDOWN_ACTION.COUNTRY, item.id);
													}
												}}
											/>
										</Grid>

										<Grid item xs={12} md={3}>
											<Autocomplete
												label={t('district')}
												placeholder=""
												defaultValue={values.stateId || null}
												isOptionEqualToValue={(option, value) => option?.id === value?.id}
												options={states || []}
												renderOption={(inputprops, item) => <li {...inputprops}>{item.name}</li>}
												getOptionLabel={(item) => item.name || ''}
												disabled={isSubmitting || !isEdit}
												onChange={(_, item) => {
													setFieldValue('stateId', item);
													setFieldValue('cityId', undefined);
													if (item) {
														requestById(DROPDOWN_ACTION.STATE, item?.id);
													}
												}}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<Autocomplete
												label={t('city')}
												placeholder=""
												defaultValue={values.cityId || null}
												isOptionEqualToValue={(option, value) => option?.id === value?.id}
												options={cities || []}
												renderOption={(inputprops, item) => <li {...inputprops}>{item.name}</li>}
												getOptionLabel={(item) => item.name || ''}
												onChange={(_, item) => setFieldValue('cityId', item)}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('neighborhood')}
												name="neighborhood"
												value={values.neighborhood}
												onChange={handleChange}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>

									</Grid>
									<Space height={2} />
									<Grid container spacing={3}>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('address')}
												name="address"
												value={values.address}
												onChange={handleChange}
												error={Boolean(touched.address && errors.address)}
												helperText={touched.address && errors.address}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('complement')}
												helperText={t('complementLegend')}
												name="addressComplement"
												value={values.addressComplement}
												onChange={handleChange}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('postalCode')}
												name="postalCode"
												value={values.postalCode}
												onChange={(event) => setFieldValue('postalCode', postCode(event).target.value)}
												error={Boolean(touched.postalCode && errors.postalCode)}
												helperText={touched.postalCode && errors.postalCode}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('latitude')}
												name="latitude"
												value={values.latitude}
												onChange={(event) => setFieldValue('latitude', latitude(event).target.value)}
												error={Boolean(touched.latitude && errors.latitude)}
												helperText={touched.latitude && errors.latitude}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
									</Grid>
									<Grid container spacing={3}>
										<Grid item xs={12} md={3}>
											<TextField
												label={t('longitude')}
												name="longitude"
												value={values.longitude}
												onChange={(event) => setFieldValue('longitude', longitude(event).target.value)}
												error={Boolean(touched.longitude && errors.longitude)}
												helperText={touched.longitude && errors.longitude}
												disabled={isSubmitting || !isEdit}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Divider />
									<Space height={2} />
									{isEdit && <DefaultButton title={t('save')} type="submit" loading={isSubmitting} />}
									{!isEdit && (
										<DefaultButton
											title={t('edit')}
											startIcon={<Edit />}
											onClick={handleEditClick}
										/>
									)}
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreatePartnerPage;
