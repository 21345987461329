import { IPagination, IPaginationTable, ITicket } from '@Types';
import { Grid, useTheme } from '@mui/material';
import { PaperContainer } from '@Molecules';
import {
	DateLabel, Dropdown, StatusLabel, TableItem,
} from '@Atoms';
import { DataTable, TableColumn } from '@Organisms';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface TicketsPageProps{
	tickets?: IPagination<ITicket[]>;
	setPagination: (pagination: IPaginationTable) => void;
	setSearch: (searchQuery: string) => void;
	onEditClick: (ticketId: number) => void
}

const TicketsPage:React.FC<TicketsPageProps> = (props) => {
	const {
		tickets, setPagination, setSearch, onEditClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<Grid sx={{ padding: theme.spacing(3) }} container spacing={2}>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<DataTable
						data={tickets?.data || []}
						pagination
						total={tickets?.total}
						onSearching={setSearch}
						onChange={(page, size) => setPagination({ page, size })}
					>
						<TableColumn headerText="ID" render={(item) => item.id} />
						<TableColumn headerText={t('user')} render={(item) => item.user?.name} />
						<TableColumn headerText={t('subject')} render={(item) => item.subject?.name} />
						<TableColumn headerText={t('message')} render={(item) => <TableItem title={item.note} width="10rem" lineClamp={1} />} />
						<TableColumn headerText={t('organization')} render={(item) => item.organization?.name} />
						<TableColumn headerText={t('registrationIn')} render={(item) => <DateLabel date={item.registration} showTime />} />
						<TableColumn headerText={t('action')} render={(item) => <StatusLabel status={item.status} />} />
						<TableColumn
							headerText={t('detail')}
							render={(item) => (
								<Dropdown
									data={[t('details')]}
									label={t('action')}
									onChange={() => onEditClick(item.id)}
									renderItem={(dropdownItem) => ({
										optionLabel: dropdownItem,
										optionValue: dropdownItem,
									})}
								/>
							)}
						/>
					</DataTable>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default TicketsPage;
