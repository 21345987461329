import { ApiResponse, IFileResponse } from '@Types';
import Endpoint from '../endpoints.api';
import request from '../request.api';

export const uploadFile = async (file: FormData): Promise<ApiResponse<IFileResponse>> => {
	const resp = await request({ url: Endpoint.FILE_UPLOAD, method: 'POST', data: file });
	const { data } = resp;
	data.statusCode = resp.status;
	return data;
};
