import { useRequest } from '@Api';
import { useFeedback, useTitleBar } from '@Providers';
import { changeQuestionStatus, getQuestions } from '@Requests';
import { AppRoutes } from '@Routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IPaginationTable, IQuestion } from '@Types';
import { useEffectCustom } from '@Hooks';
import QuestionPage from './QuestionPage';

const Questions = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const navigate = useNavigate();

	const { execute, data } = useRequest(([params]) => getQuestions(params));

	useEffect(() => {
		setTitle(t('questions'));
	}, [setTitle, t]);

	const getData = () => {
		execute({ page: pagination.page, size: pagination.size, search });
	};

	useEffectCustom(() => {
		getData();
	}, [pagination.page, pagination.size, search]);

	const questionStatus = (questionId: number) => {
		changeQuestionStatus(questionId)
			.then(() => {
				getData();
			})
			.catch(({ response }) => { addToast({ message: response.data.message, error: true }); });
	};

	const handleListAction = (action: string, question?: IQuestion) => {
		switch (action) {
		case t('edit'):
			navigate(AppRoutes.EDIT_QUESTION.formatMap({ id: question?.id }));
			break;
		case t('changeStatus'):
			if (question) {
				questionStatus(question.id);
			}
			break;
		case t('new'):
			navigate(AppRoutes.CREATE_QEUSTION);
			break;

		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<QuestionPage
			questions={data}
			handleListAction={handleListAction}
			onSearching={setTableSearch}
			onPagination={setTablePagination}
		/>
	);
};

export default Questions;
