import { useRequest } from '@Api';
import { CARD_STATUSES } from '@Constants';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { changeCardStatus, getAllCards } from '@Requests';
import { AppRoutes } from '@Routes';
import { ICard, IPaginationTable } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ListCardsPage from './ListCardsPage';

const ListCards = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const cards = useRequest(([params, signal]) => getAllCards(params, signal));
	const changeStatus = useRequest(([param1, param2]) => changeCardStatus(param1, param2));

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => {
		setTitle(t('cards'));
	}, [t, setTitle]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const listDataContol = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			cards.execute(listDataContol);
		};
		getData();
		return abortCtrl.abort();
	}, [pagination, search]);

	useEffectCustom(() => {
		const { data } = changeStatus;
		if (data) {
			cards.setData((prev) => {
				const mPrev = prev;
				const index = mPrev?.data.findIndex((el) => el.id === data?.id);
				if (index !== undefined && mPrev) {
					mPrev.data[index] = data;
				}
				return mPrev;
			});
		}
	}, [changeStatus.data]);

	const cardStatus = (card: ICard|undefined) => {
		let tempId = CARD_STATUSES.ACTIVATED;
		if (card?.cardStatus.id === CARD_STATUSES.ACTIVATED) {
			tempId = CARD_STATUSES.BLOCKED_BY_COMPANY;
		}
		changeStatus.execute(card?.id, tempId);
	};

	const handleListAction = (action: string, card?: ICard) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.CREATE_CARD);
			break;
		case t('details'):
			navigate(AppRoutes.EDIT_CARD.formatMap({ id: card?.id }));
			break;
		case t('issue'):
			navigate(AppRoutes.ISSUE_CARD.formatMap({ id: card?.id }));
			break;
		case t('changeStatus'):
			cardStatus(card);
			break;

		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListCardsPage
			listCardAction={handleListAction}
			cards={cards.data}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
		/>
	);
};

export default ListCards;
