import { useFeedback } from '@Providers';
import React from 'react';
import Dialog from '../dialog/Dialog';

type IconType = 'error' | 'success' | undefined

const ErrorDialog = () => {
	const { removeToast, openDailog, dialogProps } = useFeedback();
	const [icon, setIcon] = React.useState<IconType>();

	React.useEffect(() => {
		if (dialogProps?.error) {
			setIcon('error');
		} else if (openDailog) {
			setIcon('success');
		}
	}, [dialogProps?.error, openDailog]);

	return (
		<Dialog
			handleClose={removeToast}
			open={openDailog}
			icon={icon}
			title={dialogProps?.title}
			description={dialogProps?.message}
		/>
	);
};

export default ErrorDialog;
