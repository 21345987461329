import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useTitleBar } from '@Providers';
import { getOrganizationSevices, getPartnerSevices, getServices } from '@Requests';
import { AppRoutes } from '@Routes';
import { IPaginationTable, IService } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import ListServicesPage from './ListServicesPage';

const ListServices = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { organizationId } = useParams();
	const { partnerId } = useParams();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const services = useRequest(([params]) => getServices(params));
	const organizationServices = useRequest(([params]) => getOrganizationSevices(params));
	const partnerServices = useRequest(([params]) => getPartnerSevices(params));
	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const [isPagination, setIsPagination] = useState(false);

	useEffect(() => {
		setTitle(t('services'));
	}, [setTitle, t]);

	const getData = () => {
		if (organizationId) {
			organizationServices.execute(organizationId);
		} else if (partnerId) {
			partnerServices.execute(partnerId);
		} else {
			setIsPagination(true);
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			services.execute(listDataControl);
		}
	};

	useEffectCustom(() => {
		getData();
	}, [pagination, search]);

	const handleListAction = (action: string, service?: IService) => {
		switch (action) {
		case t('new'):
			if (partnerId) {
				navigate(AppRoutes.CREATE_SERVICES_PARTNER.formatMap({ partnerId }));
			} else if (organizationId) {
				navigate(AppRoutes.CREATE_SERVICES_ORGANIZATION.formatMap({ organizationId }));
			}
			navigate(AppRoutes.CREATE_SERVICE);
			break;
		case t('edit'):
			navigate(AppRoutes.EDIT_SERVICE.formatMap({ id: service?.id }));
			break;
		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListServicesPage
			services={
				services.data?.data
				|| organizationServices.data?.data
				|| partnerServices.data?.data
			}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
			handleListAction={handleListAction}
			pagination={isPagination}
			total={services.data?.total}
		/>
	);
};

export default ListServices;
