import { normalizeCurrency } from '@Helpers';
import {
	ApiResponse, IListDataControl, IPagination, IService, IServiceReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getServices = async (params: IListDataControl):
	Promise<ApiResponse<IPagination<IService[]>>> => {
	const resp = await reqGet({
		url: Endpoint.SERVICES.format(params.page, params.size, params.search),
	});
	const data = resp.data as ApiResponse<IPagination<IService[]>>;
	data.statusCode = resp.status;
	return data;
};

export const getServiceById = async (id: string): Promise<ApiResponse<IService>> => {
	const resp = await reqGet({
		url: Endpoint.SERVICE_BY_ID.format(id),
	});
	const data = resp.data as ApiResponse<IService>;
	data.statusCode = resp.status;
	return data;
};

export const addService = async (service: IServiceReq): Promise<ApiResponse<IService>> => {
	const dataToUpload = {
		...service,
		organizations: service.organizations.reduce((acc, el) => {
			acc.push(el.id); return acc;
		}, [] as number[]),
		prize: normalizeCurrency(service.prize),
		cost: normalizeCurrency(service.cost),
		prizeType: service.prizeType?.id,
	};
	const resp = await reqPost({
		url: Endpoint.ADD_SERVICE,
		data: { ...dataToUpload },
	});
	const data = resp.data as ApiResponse<IService>;
	data.statusCode = resp.status;
	return data;
};
export const editService = async (serviceId: string, service: IServiceReq):
	Promise<ApiResponse<IService>> => {
	const dataToUpload = {
		...service,
		organizations: service.organizations.reduce((acc, el) => {
			acc.push(el.id); return acc;
		}, [] as number[]),
		prize: normalizeCurrency(service.prize),
		cost: normalizeCurrency(service.cost),
		prizeType: service.prizeType?.id,
		serviceId,
	};
	const resp = await reqPost({
		url: Endpoint.SERVICE_BY_ID.format(serviceId),
		data: { ...dataToUpload },
	});
	const data = resp.data as ApiResponse<IService>;
	data.statusCode = resp.status;
	return data;
};
