enum Endpoint {
   BASE_URL = 'https://dev.fidelity.api.innovaj.com/back-office/v1',

   // Authentication / User Profile
   LOGIN = '/auth/login',
   REFRESH_TOKEN = '/auth/refresh-token',
   CHANGE_PASSWORD = '/auth/change-password',
   LOGOUT = '/auth/logout',
   FORGOT_PASSWORD = '/auth/forgot-password',

   // Administrators
   ADMINISTRATORS = 'administrators',
   ADMIN_STATUS = 'administrators/{0}/status',

   // Users
   USER = '/users',
   EDIT_USER_BY_ID = '/users/{0}',
   GET_USERS = '/users?CostCenterId={0}&Page={1}&Size={2}&Search={3}',
   GET_USER_BY_ID = '/users/{0}',
   USER_STATUS = '/users/{0}/status',
   USER_CC = '/users/{0}/cost-centers',
   SEND_MESSAGE = '/users/{0}/messages',
   ADD_CREDIT = '/users/{0}/add-credit',
   REMOVE_FROM_CC = '/users/{0}/cost-centers/{1}',
   USER_TAG = '/users/{0}/tags',
   REMOVE_USER_TAG = '/users/{0}/tags/{1}',
   USER_CARS = '/forms/{0}/answers?userId={1}',
   USER_BALENCE = '/users/{0}/balance',
   USER_REPORT_EXPORT = '/users/export-report?InitialDate={0}&FinalDate={1}',
   USER_BY_PHONE = '/users/find-user-by/phone/{0}',
   USER_RESET_PASSWORD = '/users/{0}/reset-password',
   // Gender
   GET_GENDER = '/genders?page={0}&size={1}&search={2}',

   // Admins
   PROMOTE_TO_ADM = '/administrators',
   GET_ADMINS = '/administrators?Page={0}&Size={1}&Search={2}',
   CHANGE_ADMIN_STATUS = '/administrators/{0}/status',

   // Upload
   FILE_UPLOAD = '/files',

   // Coupons
   COUPONS = '/coupons',
   EDIT_COUPON = '/coupons/{0}',
   EXPORT_COUPONS_REPORT = '/coupons/export-report?initialDate={}&finalDate={1}{2}',
   GET_COUPONS = '/coupons?page={0}&size={1}&search={2}',
   CHANGE_COUPON_STATUS = '/coupons/{0}/status',
   SEND_COUPON_TO_USER = '/coupons/{0}/send-to-user/{1}',
   AUDIENCE_BY_COUPON_ID = '/coupons/{0}/audiences',
   AUDIENCE_BY_FILTER = '/coupons/audiences-by-filter',
   AUDIENCE_FILTER_ELEMENTS = '/coupons/{0}/audiences-filter-elements',
   SAVE_AUDIENCE = '/coupons/{0}/audiences',

   // Coupon Requests
   GET_COUPONS_REQUESTS = '/coupon-users?Page={0}&Size={1}&Search={2}&StatusId=2',
   DELETE_COUPON_REQUEST = '/coupon-users/{0}/change-status',

   // Organizations
   ORGANIZATION = '/organizations',
   LIST_ORGANIZATIONS = '/organizations?page={0}&size={1}&search={2}',
   ORGANIZATION_BY_ID = '/organizations/{0}',
   ORGANIZATIONS_NAMES = '/organizations/names',
   ORGANIZATION_STATUS = '/organizations/{0}/status',
   ORGANIZATION_SERVICES = '/organizations/{0}/services',
   ORGANIZATION_BARCODE = '/organizations/{0}/barcode',
   GET_SELLERS = '/organizations/{0}/sellers',

   // Cost Centers
   COST_CENTERS = '/cost-centers?OrganizationId={0}{1}',
   COST_CENTER_BY_ID = '/cost-centers/{0}',
   COST_CENTER_PAGINATION = '/cost-centers?OrganizationId={0}&Page={1}&Size={2}&Search={3}',
   CREATE_COST_CENTER = '/cost-centers',
   COST_CENTER_STATUS = '/cost-centers/{0}/status',
   ADD_PARTNER_USER = '/cost-centers/{0}/add-partner-user',
   REMOVE_PARTNER_USER = '/cost-centers/{0}/remove-partner-user',

   // Places
   COUNTRIES = '/countries',
   STATES = '/countries/{0}/states',
   CITIES = '/states/{0}/cities',
   // Tags
   TAGS = '/tags?page={0}&size={1}&search={2}',
   ADD_TAG = '/tags',
   TAG_BY_ID = '/tags/{0}',

   // Orders
   LIST_ORDERS = '/orders?page={0}&size={1}&search={2}',
   CANCEL_ORDER = '/orders/{0}/cancel',
   GET_ORDER_BY_ID = '/orders/{0}',
   CORRECT_ORDER = '/orders/{0}/fix',

   // Tickets
   TICKETS = '/tickets?Page={0}&Size={1}&Search={2}',
   TICKET_BY_ID = '/tickets/{0}',
   ADD_TICKET_MESSAGE = '/tickets/{0}/messages',
   CHANGE_TICKET_STATUS = '/tickets/{0}/status',

   // Transactions
   LIST_TRANSACTIONS = '/transactions?page={0}&size={1}&search={2}',
   CANCEL_TRANSACTION = '/transactions/{0}/cancel',
   APPROVE_TRANSACTION = '/transactions/{0}/approve',

   // Rating
   EDIT_RATING = '/ratings/{0}',
   GET_RATINGS = '/ratings',

   // Social Networks
   SOCIAL_NETWORKS = '/social-networks',
   SOCIAL_NETWORKS_TYPES = '/social-networks/types',
   SOCIAL_NETWORKS_BY_ID = '/social-networks/{0}',
   SOCIAL_NETWORK_STATUS = '/social-networks/{0}/status',

   // Referrals
   GET_REFERRALS = '/referrals?page={0}&size={1}&search={2}&referralStatusId={3}',
   EDIT_REFERRAL = '/referrals/{0}',
   ADD_REFERRAL_SELLER = '/referrals/{0}/update-seller',

   // Articles
   ARTICLES = '/articles',
   GET_ARTICLES = '/articles?page={0}&size={1}&search={2}',
   EDIT_ARTICLE = '/articles/{0}',
   ARTICLE_STATUS = '/articles/{0}/status',
   ALL_ARTICLE_STATUSES = '/articles/statuses',

   // Terms
   TERMS = '/terms',
   GET_TERMS = '/terms?page={0}&size={1}&search={2}',
   EDIT_TERM = '/terms/{0}',

   // Questions
   GET_QUESTIONS = '/questions?page={0}&size={1}&search={2}',
   CHANGE_QUESTION_STATUS = '/questions/{0}/status',
   CREATE_QUESTION = '/questions',
   QUESTION_BY_ID = '/questions/{0}',

   // Chain Stores
   CHAIN_STORES = '/chain-stores?page={0}&size={1}&search={2}',
   ADD_CHAIN_STORE = '/chain-stores',
   CHAIN_STORES_STATUS = '/chain-stores/{0}/status',
   CHAIN_STORES_BY_ID = '/chain-stores/{0}',

   // Services
   SERVICES = '/services?page={0}&size={1}&search={2}',
   ADD_SERVICE = '/services',
   SERVICE_BY_ID = '/services/{0}',

   // Partners
   PARTNERS = 'partners?page={0}&size={1}&search={2}',
   PARTNER_SERVICES = '/partners/{0}/services',
   CREATE_PARTNER = '/partners',
   PARTNER_ID = '/partners/{0}',
   PARTNER_STATUS = '/partners/{0}/status',
   PARTNER_BARCODE = '/partners/{0}/barcode',

   // Cards
   ADD_CARD = '/cards',
   EDIT_CARD = '/cards/{0}',
   CARDS = '/cards?page={0}&size={1}&search={2}',
   CARD_BY_ID = '/cards/{0}',
   CARD_CATEGORIES = '/cards/categories',
   CARD_TYPES = '/cards/types',
   CARD_BILLING_TYPES = '/cards/billing-types',
   CARD_STATUS = '/cards/{0}/status',
   CARD_ALL_STATUSES = '/cards/statuses',
   CARD_USERS = '/cards/{0}/carduser?Page={1}&Size={2}&Search={3}',
   USER_CARD_STATUS = '/cards/carduser/{0}/status',
   ISSUE_CARD = '/cards/carduser',
   UPLOAD_CARD_IMAGE = '/cards/upload',

   // Reports
   SALES_REPORT = '/reports/sales?StartDate={0}&EndDate={1}&SelectedSeller={2}&SelectedOrganization={3}&StatusID={4}',
   GET_PARTNER_SELLERS = '/sales/sellers',
   GET_SALES_REPORT = '/reports/sales',
   DASHBOARD_SUMMARY = '/reports/dashboard-summary',
   ORDER_REPORT = '/reports/orders?StartDate={0}&EndDate={1}',
   GET_REFERRAL_REPORT = '/reports/referrals?startDate={0}&endDate={1}{2}',
   TRANSACTION_REPORT = '/reports/transactions?StartDate={0}&EndDate={1}&CardId={2}&CardUserId={3}',
   GET_COUPONS_REPORT = '/reports/coupons/?StartDate={0}&EndDate={1}{2}',
   USER_REPORT = '/reports/users?StartDate={0}&EndDate={1}',
   GET_ORDER_COUPON_TRANSACTIONS = '/reports/coupons-summary?StartDate={0}&EndDate={1}{2}',
   GET_EXCEL_SHEET_REPORT = '/reports/coupons-summary-excel?StartDate={0}&EndDate={1}{2}',

   // Forms
   FORM = '/forms',
   USER_FORM = '/forms/{0}/answers?userId={1}',
   DELETE_FORM = '/forms/{0}?userId={1}',

   /** RESET PASSWORD FOR PARTNER APPLICATION */
   CHECK_CHANGE_PASSWORD = '/public/v1/check-change-password',
   CHANGE_PARTNER_PASSWORD = '/public/v1/change-password',

   /** EMAIL CONFIRMATION SCREEN */
   CONFIRM_EMAIL = '/auth/emailconfirm?Token={0}'

}

export default Endpoint;
