import { useEffectCustom } from '@Hooks';
import { putDecimalPoints } from '@Masks';
import { useFeedback, useTitleBar } from '@Providers';
import {
	addSellerToReferral,
	getReferralById,
	getAllSellers,
	saveServiceValueForReferrral,
} from '@Requests';
import { INameAndId, IReferral } from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReferralDetailsPage from './ReferralDetailsPage';

const RefferalDetails = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();

	const [sellerLoading, setSellerLoading] = useState(false);
	const [selectSellerError, setSelectSellerError] = useState('');
	const [serviceValueLoading, setServiceValueLoading] = useState(false);
	const [referral, setReferral] = useState<IReferral>();
	const [data, setData] = useState<{sellerId: number | string; serviceValue: string}>({ sellerId: '', serviceValue: '' });

	const [sellers, setSellers] = useState([] as INameAndId[]);

	const { id } = useParams();

	const getSellers = async (mRefferal: IReferral) => {
		// Get all sellers
		const mSellers = await getAllSellers(mRefferal.organization.id);
		// Look if the seller in this referral already exist in the list of sellers
		if (mRefferal.seller) {
			const found = mSellers.data.find((el) => el.id === mRefferal.seller?.id);
			if (!found) {
				// if the seller doesn't exist add him to the list
				mSellers.data.push({
					id: mRefferal.seller.id,
					name: mRefferal.seller.name,
				});
			}
		}
		setSellers(mSellers.data);
	};

	useEffectCustom(() => {
		setTitle(`${t('referralDetail')}`);
		setGlobalLoading(true);
		if (!referral) {
			getReferralById(id ?? '')
				.then(async (res) => {
					setData({
						...data,
						serviceValue: putDecimalPoints(res.data.currentCost),
						sellerId: res.data.seller?.id || '',
					});
					setReferral(res.data);
					getSellers(res.data);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => setGlobalLoading(false));
		}
	});

	const addSeller = () => {
		if (!data.sellerId || data.sellerId === 0) {
			return setSelectSellerError(t('emptyField'));
		}
		setSellerLoading(true);
		addSellerToReferral(id ?? '', data.sellerId)
			.then((res) => addToast({ message: res.message, error: false }))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setSellerLoading(false));
		return null;
	};

	const saveServiceValue = () => {
		setServiceValueLoading(true);
		saveServiceValueForReferrral(id ?? '', data.serviceValue)
			.then((res) => addToast({ message: res.message, error: false }))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setServiceValueLoading(false));
	};

	const onChange = (key: string, value: string | number) => {
		setData({ ...data, [key]: value });
		setSelectSellerError('');
	};

	return (
		<ReferralDetailsPage
			values={data}
			sellerLoading={sellerLoading}
			serviceValueLoading={serviceValueLoading}
			referral={referral}
			onSellerSaveClick={addSeller}
			sellerError={selectSellerError}
			onServiceValueSave={saveServiceValue}
			sellers={sellers}
			onChange={onChange}
		/>
	);
};

export default RefferalDetails;
