import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Dashboard as DashboardIcon,
	Groups,
	LocalActivity,
	List,
	AddCircle,
	FeaturedPlayList,
	ShoppingBag,
	Paid,
	VolunteerActivism,
	Star,
	Campaign,
	PlaylistAddCheck,
	Help,
	LocalOffer,
	Store,
	CorporateFare,
	CleaningServices,
	CreditCard,
	Assessment,
	GroupWork,
	AccountCircle,
	ConfirmationNumber,
	ConnectWithoutContact,
	ReceiptLong,
} from '@mui/icons-material';
import { AppRoutes } from '@Routes';
import { useTheme } from '@mui/material';

export interface UserRoutes {
   title: string;
   icon?: ReactNode;
   route: string;
   children?: { title: string; icon: ReactNode; route: string }[];
   active: boolean;
}

export const useMenuItems = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const iconStyle = {
		fontSize: 15,
		padding: 0,
		color: theme.palette.icon.main,
	};
	const DefaultUserRoutes: UserRoutes[] = [
		{
			title: t('dashboard'),
			route: AppRoutes.DASHBOARD,
			active: false,
			icon: <DashboardIcon style={iconStyle} />,
		},
		{
			title: t('users'),
			active: false,
			route: '/user',
			icon: <Groups style={iconStyle} />,
			children: [
				{
					title: t('listAll'),
					icon: <List style={iconStyle} />,
					route: AppRoutes.LIST_USERS,
				},
				{
					title: t('createNew'),
					icon: <AddCircle style={iconStyle} />,
					route: AppRoutes.NEW_USER,
				},
				{
					title: t('administrators'),
					icon: <AccountCircle style={iconStyle} />,
					route: AppRoutes.ADMINISTRATORS,
				},
			],
		},
		{
			title: t('coupons'),
			// route: AppRoutes.DASHBOARD,
			active: false,
			route: '/coupon',
			icon: <LocalActivity style={iconStyle} />,
			children: [
				{
					title: t('listAll'),
					icon: <List style={iconStyle} />,
					route: AppRoutes.LIST_COUPONS,
				},
				{
					title: t('couponRequests'),
					icon: <FeaturedPlayList style={iconStyle} />,
					route: AppRoutes.COUPON_REQUESTS,
				},
				{
					title: t('statistics'),
					icon: <LocalActivity style={iconStyle} />,
					route: AppRoutes.COUPONS_REPORT,
				},
				{
					title: t('couponSummary'),
					icon: <ReceiptLong style={iconStyle} />,
					route: AppRoutes.COUPON_QUANTITY_PER_TYPE,
				},
			],
		},
		{
			title: t('orders'),
			route: '/order',
			active: false,
			icon: <ShoppingBag style={iconStyle} />,
			children: [
				{
					title: t('listAll'),
					icon: <List style={iconStyle} />,
					route: AppRoutes.LIST_ORDERS,
				},
				{
					title: t('sales'),
					icon: <Assessment style={iconStyle} />,
					route: AppRoutes.SALES_REPORT,
				},
			],
		},
		{
			title: t('tickets'),
			route: AppRoutes.LIST_TICKETS,
			active: false,
			icon: <ConfirmationNumber style={iconStyle} />,
		},
		{
			title: t('transactions'),
			route: AppRoutes.LIST_TRANSACTIONS,
			active: false,
			icon: <Paid style={iconStyle} />,
		},
		{
			title: t('referrals'),
			route: AppRoutes.LIST_REFERRALS,
			active: false,
			icon: <VolunteerActivism style={iconStyle} />,
		},
		{
			title: t('ratings'),
			route: AppRoutes.LIST_RATINGS,
			active: false,
			icon: <Star style={iconStyle} />,
		},
		{
			title: t('articles'),
			route: AppRoutes.LIST_ARTICLES,
			active: false,
			icon: <Campaign style={iconStyle} />,
		},
		{
			title: t('terms'),
			route: AppRoutes.TERMS,
			active: false,
			icon: <PlaylistAddCheck style={iconStyle} />,
		},
		{
			title: t('faqs'),
			route: AppRoutes.QUESTIONS,
			active: false,
			icon: <Help style={iconStyle} />,
		},
		{
			title: t('tags'),
			route: AppRoutes.TAGS,
			active: false,
			icon: <LocalOffer style={iconStyle} />,
		},
		{
			title: t('socialNetworks'),
			route: AppRoutes.SOCIAL_NETWORK,
			active: false,
			icon: <ConnectWithoutContact style={iconStyle} />,
		},
		{
			title: t('networks'),
			route: AppRoutes.CHAIN_STORES,
			active: false,
			icon: <Store style={iconStyle} />,
		},
		{
			title: t('organizations'),
			route: AppRoutes.ORGANIZATIONS,
			active: false,
			icon: <CorporateFare style={iconStyle} />,
		},
		{
			title: t('services'),
			route: AppRoutes.SERVICES,
			active: false,
			icon: <CleaningServices style={iconStyle} />,
		},
		{
			title: t('partners'),
			route: AppRoutes.PARTNERS,
			active: false,
			icon: <GroupWork style={iconStyle} />,
		},
		{
			title: t('cards'),
			active: false,
			route: '/cards',
			icon: <CreditCard style={iconStyle} />,
			children: [
				{
					title: t('listAll'),
					icon: <List style={iconStyle} />,
					route: AppRoutes.CARDS,
				},
				// {
				// 	title: t('createNew'),
				// 	icon: <AddCircle style={iconStyle} />,
				// 	route: AppRoutes.CREATE_CARD,
				// },
			],
		},
		{
			title: t('reports'),
			active: false,
			route: '/report',
			icon: <CreditCard style={iconStyle} />,
			children: [
				{
					title: t('users'),
					icon: <Groups style={iconStyle} />,
					route: AppRoutes.USERS_REPORT,
				},
				{
					title: t('couponSummary'),
					icon: <ReceiptLong style={iconStyle} />,
					route: AppRoutes.COUPON_QUANTITY_PER_TYPE,
				},
				{
					title: t('statistics'),
					icon: <LocalActivity style={iconStyle} />,
					route: AppRoutes.COUPONS_REPORT,
				},
				{
					title: t('sales'),
					icon: <Assessment style={iconStyle} />,
					route: AppRoutes.SALES_REPORT,
				},
				{
					title: t('transactions'),
					icon: <Paid style={iconStyle} />,
					route: AppRoutes.TRANSACTION_REPORT,
				},
				{
					title: t('referrals'),
					route: AppRoutes.REFERRAL_REPORT,
					icon: <VolunteerActivism style={iconStyle} />,
				},
				// {
				// 	title: t('orders'),
				// 	route: AppRoutes.ORDER_REPORT,
				// 	icon: <ShoppingBag style={iconStyle} />,
				// },
			],
		},
	];
	const [menuItems] = useState<UserRoutes[]>(DefaultUserRoutes);

	return { menuItems };
};
