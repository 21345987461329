import {
	Autocomplete, DateLabel, DatePicker, DefaultButton, Space, StatusLabel, TableItem,
} from '@Atoms';
import { formatCurrency } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { Print, Summarize } from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import {
	INameAndId,
	IOrganization,
	ISaleReportParams,
	ISaleReportResponse,
} from '@Types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { printReport } from '@Print';

interface SalesReportProps {
    organizations?: IOrganization[];
    sellers?: INameAndId[];
    salesReport?: ISaleReportResponse[];
    onFilterClick: () => void;
    onChange: (
        key: string, value: Date | IOrganization | INameAndId | number | undefined | null) => void;
    initialValues: ISaleReportParams;
    loading?: boolean;
    exportExcel: () => void
}

const SalesReportPage: React.FC<SalesReportProps> = (props) => {
	const {
		organizations,
		sellers,
		onFilterClick,
		onChange,
		initialValues,
		salesReport,
		loading,
		exportExcel,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const printRef = React.useRef<HTMLDivElement>(null);

	const handlePrintClick = () => {
		printReport(printRef);
	};

	return (
		<div>
			<Grid container spacing={2} sx={{ padding: theme.spacing(3) }}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Grid container spacing={2}>
							<Grid item xs={12} md={2}>
								<DatePicker label={t('startDate')} value={initialValues.initialDate} onChange={(value) => onChange('initialDate', value)} />
							</Grid>
							<Grid item xs={12} md={2}>
								<DatePicker label={t('endDate')} value={initialValues.finalDate} onChange={(value) => onChange('finalDate', value)} />
							</Grid>
							<Grid item xs={12} md={3}>
								<Autocomplete
									options={organizations || []}
									defaultValue={initialValues.selectedOrganization}
									label={t('company')}
									placeholder=""
									renderOption={(inputprops, item) => (
										<li
											{...inputprops}
											key={item.id}
										>
											{item.officialName}
										</li>
									)}
									getOptionLabel={(item) => item.officialName}
									onChange={(_, item) => onChange('selectedOrganization', item)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<Autocomplete
									options={sellers || []}
									label={t('collaborator')}
									defaultValue={initialValues.selectedCustomer}
									placeholder=""
									renderOption={(inputprops, item) => (
										<li
											{...inputprops}
											key={item.id}
										>
											{item.name}
										</li>
									)}
									getOptionLabel={(item) => item.name}
									onChange={(_, item) => onChange('selectedCustomer', item)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<Autocomplete
									options={[
										{ id: 2, name: 'Confirmada' },
										{ id: 3, name: 'Cancelada' },
									]}
									label={t('status')}
									placeholder=""
									defaultValue={{ id: 2, name: 'Confirmada' }}
									renderOption={(inputprops, item) => (
										<li
											{...inputprops}
											key={item.id}
										>
											{item.name}
										</li>
									)}
									getOptionLabel={(item) => item.name}
									onChange={(_, item) => onChange('statusId', item?.id)}
								/>
							</Grid>
							<Grid item xs={12} md={2}>
								<DefaultButton loading={loading} title={t('filter')} onClick={onFilterClick} />
							</Grid>
						</Grid>
					</PaperContainer>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}
				sx={{
					pl: theme.spacing(3),
					pr: theme.spacing(3),
					pb: theme.spacing(3),
				}}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						{/* <Title
							text={`${t('periodInvoicing')}:${formatCurrency(salesReport?.total || 0)}`}
							color="red"
						/> */}
						<Space height={2} />
						<DefaultButton title={t('print')} startIcon={<Print />} onClick={handlePrintClick} />
						<DefaultButton
							sx={{ ml: 2, textTransform: 'none' }}
							title={t('export')}
							startIcon={<Summarize />}
							onClick={exportExcel}
						/>
						<Space height={2} />
						<div ref={printRef}>
							{salesReport?.map((report) => (
								<>
									<DataTable data={['']} stripedRows search={false}>
										<TableColumn headerText={t('company')} render={() => report.organizationName} />
										<TableColumn headerText="CC" render={() => report.costCenter} />
										<TableColumn headerText={t('seller')} render={() => report.seller} />
										<TableColumn headerText={t('nif')} render={() => report.taxId} />
										<TableColumn headerText={t('total')} render={() => formatCurrency(report.totalFinalTotalWithTaxes)} />
									</DataTable>
									<DataTable data={report.orders || []} search={false}>
										<TableColumn
											headerText={t('order')}
											render={(item) => (
												<TableItem
													title={item.orderId}
													subTitle={`${t('client')}:
										${item.customer}`}
												/>
											)}
											footer={<TableItem title={t('total')} color="red" />}
										/>
										<TableColumn
											headerText={t('invoice')}
											render={(item) => item.invoiceNumber}
											footer=""
										/>
										<TableColumn
											headerText={t('date')}
											render={(item) => <DateLabel date={item.date} showTime />}
											footer=""
										/>

										<TableColumn
											headerText={t('baseValue')}
											render={(item) => formatCurrency(item?.initTotalWithTaxes || 0)}
											footer={<TableItem title={formatCurrency(report.initTotalWithTaxes)} color="red" />}
										/>
										<TableColumn
											headerText={t('discount')}
											render={(item) => formatCurrency(item?.discount || 0)}
											footer={<TableItem title={formatCurrency(report.totalDiscountWithTaxes)} color="red" />}
										/>
										<TableColumn
											headerText={t('balenceUsed')}
											render={(item) => formatCurrency(item?.usedBalance || 0)}
											footer={<TableItem title={formatCurrency(report.totalUsedBalanceWithTaxes)} color="red" />}
										/>
										<TableColumn
											headerText={t('finalValue')}
											render={(item) => formatCurrency(item?.finalTotalWithTaxes || 0)}
											footer={<TableItem title={formatCurrency(report.totalFinalTotalWithTaxes)} color="red" />}
										/>
										<TableColumn
											headerText={t('refund')}
											render={(item) => formatCurrency(item.cashBack || 0)}
											footer={<TableItem title={formatCurrency(report.totalCashback)} color="red" />}
										/>
										<TableColumn
											// align="right"
											// headerOptions={{ align: 'right' }}
											headerText={t('status')}
											render={(item) => <StatusLabel status={item.status} />}
											footer=""
										/>
									</DataTable>

								</>
							))}
						</div>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};
export default SalesReportPage;
