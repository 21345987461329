import { useFeedback, useTitleBar } from '@Providers';
import {
	getArticleById, createArticle, editArticle, getArticleStatus,
} from '@Requests';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ICreateArticlesReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import { useEffectCustom } from '@Hooks';
import { AppRoutes } from '@Routes';
import { useRequest } from '@Api';
import CreateArticlePage from './CreateArticlePage';

const CreateArticle = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const articleStatuses = useRequest(([param]) => getArticleStatus(param));

	const [initialValues, setInitialValues] = useState<ICreateArticlesReq>({
		title: '',
		image: '',
		link: 'http://',
		typeId: '',
		description: '',
		extra1: '',
		extra2: '',
		languageId: 2,
		subtitle: '',
		statusId: 1,
		startDate: null,
		endDate: null,
	});
	const [isEdit, setEdit] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			setTitle(`${t('campaignDetail')}`);
		} else {
			setEdit(true);
			setTitle(`${t('new')} ${t('article')}`);
		}
	}, [id, setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			articleStatuses.execute(abortCtrl.signal);
			if (id) {
				setGlobalLoading(true);
				getArticleById(id, abortCtrl.signal)
					.then(({ data }) => {
						setInitialValues({
							title: data.title,
							image: data.image,
							link: data.link,
							typeId: data.type.id,
							description: data.description,
							extra1: data.extra1,
							extra2: data.extra2,
							subtitle: data.subtitle,
							languageId: data.language.id,
							statusId: data.status.id,
							startDate: data.startDate ? new Date(data.startDate) : null,
							endDate: data.endDate ? new Date(data.endDate) : null,
						});
					})
					.catch(({ response }) => addToast({ message: response.data.message, error: true }))
					.finally(() => {
						setGlobalLoading(false);
					});
			}
		};
		getData();
		return () => abortCtrl.abort();
	}, [id]);

	const categories = [
		{ id: 1, name: t('campaigns') },
	];

	const validate = (values: ICreateArticlesReq) => {
		const errors: FormikErrors<ICreateArticlesReq> = {};
		if (!values.title) {
			errors.title = t('emptyField');
		}
		if (!values.image) {
			errors.image = t('emptyField');
		}
		if (!values.link || values.link.length < 8) {
			errors.link = t('emptyField');
		}
		if (!values.typeId || typeof values.typeId !== 'number') {
			errors.typeId = t('emptyField');
		}
		if (!values.description) {
			errors.description = t('emptyField');
		}
		return errors;
	};

	const onCreateArticles = (
		values: ICreateArticlesReq,
		formikHelpers: FormikHelpers<ICreateArticlesReq>,
	) => {
		if (id) {
			editArticle(id, values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ARTICLES);
				})
				.catch((err) => addToast({ message: err.message, error: true }))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			createArticle(values)
				.then((res) => {
					formikHelpers.resetForm({});
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_ARTICLES);
				})
				.catch((err) => addToast({ message: err.message, error: true }))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('article')}`);
		setEdit(true);
	};

	const handleBackClick = () => {
		navigate(AppRoutes.LIST_ARTICLES);
	};

	return (
		<CreateArticlePage
			initialValues={initialValues}
			onCreateArticle={onCreateArticles}
			validate={validate}
			categories={categories}
			statuses={articleStatuses.data}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
			handleBackClick={handleBackClick}
		/>
	);
};

export default CreateArticle;
