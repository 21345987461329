import {
	ApiResponse, ISocialNetwork, ISocialNetworkReq, ISocialNetworkType,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqDelete, reqGet, reqPost } from '../request.api';

export const getSocialNetworks = async (signal: AbortSignal):
	Promise<ApiResponse<ISocialNetwork[]>> => {
	const resp = await reqGet({
		url: Endpoint.SOCIAL_NETWORKS,
		signal,
	});
	const data = resp.data as ApiResponse<ISocialNetwork[]>;
	data.statusCode = resp.status;
	return data;
};

export const getSocialNetworkById = async (id: string):
	Promise<ApiResponse<ISocialNetwork>> => {
	const resp = await reqGet({
		url: Endpoint.SOCIAL_NETWORKS_BY_ID.format(id),
	});
	const data = resp.data as ApiResponse<ISocialNetwork>;
	data.statusCode = resp.status;
	return data;
};

export const createSocialNetwork = async (payload: ISocialNetworkReq):
	Promise<ApiResponse<void>> => {
	const dataToUpload = {
		typeId: payload.typeId.id,
		pageId: payload.pageId,
		userName: payload.userName,
	};
	const resp = await reqPost({
		url: Endpoint.SOCIAL_NETWORKS,
		data: { ...dataToUpload },
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};

export const editSocialNetwork = async (payload: ISocialNetworkReq, id: string):
	Promise<ApiResponse<void>> => {
	const dataToUpload = {
		typeId: payload.typeId.id,
		pageId: payload.pageId,
		userName: payload.userName,
	};
	const resp = await reqPost({
		url: Endpoint.SOCIAL_NETWORKS_BY_ID.format(id),
		data: { ...dataToUpload },
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};
export const deleteSocialNetwork = async (id?: number):
	Promise<ApiResponse<void>> => {
	const resp = await reqDelete({
		url: Endpoint.SOCIAL_NETWORKS_BY_ID.format(id),
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};

export const getSocialNetworkTypes = async (): Promise<ApiResponse<ISocialNetworkType[]>> => {
	const resp = await reqGet({
		url: Endpoint.SOCIAL_NETWORKS_TYPES,
	});
	const data = resp.data as ApiResponse<ISocialNetworkType[]>;
	data.statusCode = resp.status;
	return data;
};

export const socialNetworkStatus = async (id: number): Promise<ApiResponse<ISocialNetwork>> => {
	const resp = await reqPost({
		url: Endpoint.SOCIAL_NETWORK_STATUS.format(id),
	});
	const data = resp.data as ApiResponse<ISocialNetwork>;
	data.statusCode = resp.status;
	return data;
};
