import React, {
	FC, useState, useMemo, useContext, createContext,
} from 'react';

interface TitleBarContextProps {
   title?: string | null;
   setTitle: (user: string | null) => void;
   setGlobalLoading: (loading: boolean) => void;
   loading: boolean
}

const TitleBarContext = createContext({} as TitleBarContextProps);

export const TitleBarProvider: FC = ({ children }) => {
	const [title, setTitle] = useState<string | null>();
	const [loading, setGlobalLoading] = useState(false);

	const value = useMemo(() => ({
		title, setTitle, setGlobalLoading, loading,
	}), [title, loading, setGlobalLoading]);
	return <TitleBarContext.Provider value={value}>{children}</TitleBarContext.Provider>;
};

export default function useTitleBar() {
	return useContext(TitleBarContext);
}
