import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {
	Divider, IconButton, SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	DefaultButton, Row, Space,
} from '@Atoms';
import { Close } from '@mui/icons-material';
import { IField, IForm } from '@Types';
import React from 'react';
import FormElement from './FormElement';

interface EditVehicleModalProps {
   open: boolean;
   handleClose: (action?: string) => void;
   answer: IForm;
   form?: IForm;
   onChange: (field: IField, event:
		React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> |
		SelectChangeEvent<string>) => void;
	loading: boolean
}

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	borderRadius: 1,
	bgcolor: 'background.paper',
	boxShadow: 24,
};

const EditVehicleModal = (props: EditVehicleModalProps) => {
	const {
		open, handleClose, answer, form, onChange, loading,
	} = props;

	const { t } = useTranslation('translations');

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box sx={style}>
					<Box sx={{ p: 1 }}>
						<Row justify="space-between">
							<Typography id="transition-modal-title" color="text.secondary" variant="h6" component="h6">
								{t('editVehicle')}
							</Typography>
							<IconButton aria-label="delete" sx={{ p: 0 }} onClick={() => handleClose()}>
								<Close />
							</IconButton>
						</Row>
					</Box>
					<Divider />
					<Box sx={{ p: 2 }}>
						{form?.fields.map((el, index) => (
							<div key={`form-field-${index.toString()}`}>
								<FormElement field={el} answers={answer.fields} onChange={onChange} />
								<Space height={2} />
							</div>

						))}
					</Box>
					<Divider />
					<Space height={2} />
					<Box sx={{ p: 1 }}>
						<Row justify="flex-end">
							<DefaultButton title={t('close')} disabled={loading} onClick={() => handleClose()} color="info" />
							<DefaultButton title={t('save')} loading={loading} onClick={() => handleClose(t('save'))} sx={{ ml: 2 }} />
						</Row>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default EditVehicleModal;
