import { useFeedback, useTitleBar } from '@Providers';
import { createTerm, editTerm, getTermById } from '@Requests';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ICreateTermsReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import CreateTermsPage from './CreateTermsPage';

const CreateTerm = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState<ICreateTermsReq>({
		categoryId: '',
		link: '',
	});

	const { id } = useParams();

	const categories = [
		{ name: t('privacyPolicy'), id: 1 },
	];

	useEffect(() => {
		setTitle(`${id ? t('edit') : t('new')} ${t('terms')}`);
	}, [id, setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			if (id) {
				setGlobalLoading(true);
				getTermById(id)
					.then(({ data }) => {
						setInitialValues({
							categoryId: data.category.id,
							link: data.link,
						});
					})
					.catch(({ response }) => addToast({ message: response.data.message, error: true }))
					.finally(() => {
						setGlobalLoading(false);
					});
			}
		};
		getData();
	}, []);

	const validate = (values: ICreateTermsReq) => {
		const errors: FormikErrors<ICreateTermsReq> = {};
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		if (!values.link) {
			errors.link = t('emptyField');
		}
		if (!values.link.match(urlRegex)) {
			errors.link = t('validLinkError');
		}
		if (!values.categoryId) {
			errors.categoryId = t('emptyField');
		}
		return errors;
	};

	const onCreateTerm = (values: ICreateTermsReq, formikHelpers: FormikHelpers<ICreateTermsReq>) => {
		if (id) {
			editTerm(id, values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.TERMS);
				})
				.catch(({ response }) => addToast({ message: response.data.title, error: true }))
				.finally(() => formikHelpers.setSubmitting(false));
		} else {
			createTerm(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.TERMS);
				})
				.catch(({ response }) => addToast({ message: response.data.title, error: true }))
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	return (
		<CreateTermsPage
			initialValues={initialValues}
			onCreateTerm={onCreateTerm}
			validate={validate}
			categories={categories}
		/>
	);
};

export default CreateTerm;
