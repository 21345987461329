/* eslint-disable @typescript-eslint/no-explicit-any */
import { IForm, ApiResponse } from '@Types';
import { reqDelete, reqGet, reqPost } from '../request.api';
import Endpoint from '../endpoints.api';

export const getFormsById = async (formId: number): Promise<ApiResponse<IForm>> => {
	const resp = await reqGet({
		url: Endpoint.FORM,
	});
	const data = resp.data as ApiResponse<IForm[]>;
	data.statusCode = resp.status;
	const tempData = { ...data, data: data.data[formId - 1] };
	return tempData;
};

export const getUserAnswers = async (userId: string): Promise<ApiResponse<IForm[]>> => {
	const resp = await reqGet({ url: Endpoint.USER_FORM.format(1, userId) });
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};

export const deleteForm = async (hash: string, userId?: string): Promise<ApiResponse<void>> => {
	const resp = await reqDelete({
		url: Endpoint.DELETE_FORM.format(hash, userId),
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};

export const addForm = async (userId: string | undefined, form: IForm):
	Promise<ApiResponse<any>> => {
	const answers = [] as {fieldId: number, value: string}[];
	form.fields.forEach((el) => answers.push({ fieldId: el.id, value: el.value }));
	const formReq = {
		hash: form.hash,
		answers,
		userId,
	};
	const resp = await reqPost({
		url: Endpoint.FORM,
		data: { ...formReq },
	});
	const data = resp.data as ApiResponse<any>;
	data.statusCode = resp.status;
	return data;
};
