import AppRouter from './AppRouter';

enum AppRoutes {
	// Main Routes
	LOGIN= '/login',
	MAIN= '/',
	CHANGE_PASSWORD_LINK = '/reset-password/:id/:token',
	CHANGE_EMAIL_LINK = '/change-email-confirm/:token',
	FORGOT_PASSWORD = '/forgot-password',
	DASHBOARD='/dashboard',
	// User
	LIST_USERS= '/users/list',
	NEW_USER= '/user/create',
	EDIT_ACTIVE_USER= '/user/edit/:id',
	EDIT_USER= '/user/edit/:id',
	CHANGE_PASSWORD= '/user/change-password',
	PROMOTE_ADM= '/user/:id/promote-admin',
	LINK_TO_CC= '/user/:id/add-to-cc',
	SEND_MESSAGE= '/user/:id/send-message',
	BALENCE_POINTS= '/user/:id/add-credits',
	USER_TAGS= '/user/:id/tags',
	USER_VEHICLES= '/user/:id/vehicles',
	// Administrators
	ADMINISTRATORS= '/admin/list',
	// Coupons
	LIST_COUPONS= '/coupon/list',
	NEW_COUPON= '/coupon/create',
	EDIT_COUPON= '/coupon/edit/:id',
	COUPON_DEFINE_AUDIENCE= '/coupon/audience/:id',
	COUPON_REQUESTS= '/coupon/requests',
	DEFINE_COUPON_AUDIENCE= '/coupon/define-audience/:id',
	// Order
	LIST_ORDERS= '/order/list',
	ORDER_DETAIL= '/order/details/:id',
	ORDER_FIX='/order/fix/:id',
	// Tickets
	LIST_TICKETS = '/tickets/list',
	EDIT_TICKETS = '/tickets/edit/:id',
	// Transactions
	LIST_TRANSACTIONS= '/transactions/list',
	// Indications
	LIST_INDICATIONS= '/indications/list',
	INDICATION_DETAILS= 'indications/:id/details',
	// Referrals
	LIST_REFERRALS= '/referrals/list',
	REFERRAL_DETAILS= 'referrals/:id/details',
	// Ratings
	LIST_RATINGS= '/ratings/list',
	EDIT_RATING= '/ratings/edit/:id',
	NEW_RATING= '/ratings/create',
	// Articles
	LIST_ARTICLES= '/articles/list',
	NEW_ARTICLE= '/articles/create',
	EDIT_ARTICLE= '/articles/:id/edit',
	// Terms
	TERMS= '/terms/list',
	EDIT_TERM= '/terms/:id/edit',
	NEW_TERM= '/terms/create',
	// Questions
	QUESTIONS= '/questions/list',
	EDIT_QUESTION= '/question/edit/:id',
	CREATE_QEUSTION= '/question/create',
	// Tags
	TAGS='/tags/list',
	EDIT_TAGS='/tag/edit/:id',
	CREATE_TAG='/tag/create',
	// Social Network
	SOCIAL_NETWORK = '/social-network/list',
	EDIT_SOCIAL_NETWORK = '/social-network/edit/:id',
	CREATE_SOCIAL_NETWORK = '/social-network/create',
	// Chain Stores
	CHAIN_STORES = '/chain-store/list',
	CREATE_CHAIN_STORE = '/chain-store/create',
	EDIT_CHAIN_STORE = '/chain-store/edit/:id',
	// Organizations
	ORGANIZATIONS = '/organization/list',
	CREATE_ORGANIZATION = '/organization/create',
	EDIT_ORGANIZATION = '/organization/edit/:id',
	// Services
	SERVICES = '/services/list',
	SERVICES_ORGANIZATION = '/services/list/organization/:organizationId',
	SERVICES_PARTNERS = '/services/list/partners/:partnerId',
	EDIT_SERVICE = '/services/edit/:id',
	CREATE_SERVICE = '/services/create',
	CREATE_SERVICES_ORGANIZATION = '/services/organization/:organizationId/create',
	CREATE_SERVICES_PARTNER = '/services/partner/:partnerId/create',
	// Partners
	PARTNERS = '/partners/list',
	EDIT_PARTNER = '/partners/edit/:id',
	CREATE_PARTNER = '/partners/create',
	// Cards
	CARDS = '/cards/list',
	EDIT_CARD = '/cards/edit/:id',
	CREATE_CARD = '/cards/create',
	ISSUE_CARD = '/cards/issue/:id',
	// Report
	USERS_REPORT = '/report/users',
	COUPONS_REPORT = '/report/coupons',
	SALES_REPORT = '/report/sales',
	TRANSACTION_REPORT = '/report/transaction',
	REFERRAL_REPORT = '/report/referrals',
	ORDER_REPORT = '/report/order',
	COUPON_QUANTITY_PER_TYPE= '/report/coupon-quantity',

	// COST CENTERS
	COST_CENTERS_ORGANIZATION = '/cost-center/organization/:organizationId',
	COST_CENTERS_PARTNER = '/cost-center/partner/:partnerId',
	CREATE_COST_CENTER_PARTNER = '/cost-center/partner/:partnerId/create',
	CREATE_COST_CENTER_ORGANIZATION = '/cost-center/organization/:organizationId/create',
	EDIT_COST_CENTER_PARTNER = '/cost-center/partner/:partnerId/edit/:id',
	EDIT_COST_CENTER_ORGANIZATION = '/cost-center/organization/:organizationId/edit/:id'
}

export { AppRoutes, AppRouter };
