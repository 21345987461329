import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface TitleProps extends TypographyProps {
	text: string
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit';
	fontWeight?: 'light' | 'bold'
}

const Title: React.FC<TitleProps> = (props) => {
	const { text, variant = 'h5', fontWeight = 'light' } = props;
	return <Typography {...props} variant={variant} fontWeight={fontWeight}>{text}</Typography>;
};

export default Title;
