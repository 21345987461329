import { useFeedback, useTitleBar, useAuth } from '@Providers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	addNewUser,
	getCitiesByStateId,
	getCountries,
	getStatesByCountryId,
	getUserById,
	updateUser,
} from '@Requests';
import {
	ICities,
	IStates,
	ICountry,
	IUserReq,
} from '@Types';
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { phoneString } from '@Masks';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import { validateNif } from '@Helpers';
import CreateUserPage, { DROPDOWN_ACTION } from './CreateUserPage';

const PHONE_LENGTH = 11;

const CreateUser = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast, addDialog } = useFeedback();
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { setUserImage } = useAuth();

	const [countries, setCountries] = useState<ICountry[]>([]);
	const [states, setStates] = useState<IStates[]>([]);
	const [cities, setCities] = useState<ICities[]>([]);

	const [profileImage, setProfileImage] = useState({} as File);

	const [initialValues, setInitialValues] = useState<IUserReq>({
		name: '',
		email: '',
		phone: '',
		taxId: '',
		city: undefined as ICities | undefined,
		state: undefined as IStates | undefined,
		country: undefined as ICountry | undefined,
	});

	const [selectedPlaces] = useState({
		country: 1,
		state: 1,
	});

	const getStates = async (countryId: number) => {
		const mStates = await getStatesByCountryId(countryId);
		return mStates;
	};

	const getCities = async (stateId: number) => {
		const mCities = await getCitiesByStateId(stateId);
		return mCities;
	};

	const getDropdowns = async () => {
		const mCountries = await getCountries();
		const mStates = await getStates(selectedPlaces.country);
		const mCities = await getCities(selectedPlaces.state);
		setCountries(mCountries.data);
		setStates(mStates.data);
		setCities(mCities.data);
		setInitialValues({
			name: '',
			email: '',
			phone: '',
			taxId: '',
			city: mCities.data[0],
			country: mCountries.data[0],
			state: mStates.data[0],
		});
	};

	const getUser = () => {
		setGlobalLoading(true);
		getUserById(id ?? '')
			.then(async ({ data }) => {
				const mCountries = await getCountries();
				const mStates = await getStates(data.country.id);
				const mCities = await getCities(data.state.id);
				setCountries(mCountries.data);
				setStates(mStates.data);
				setCities(mCities.data);
				setInitialValues({
					name: data.name || '',
					email: data.email || '',
					phone: phoneString(data.phone) || '',
					city: data.city,
					state: data.state,
					country: data.country,
					profileImage: data.profileImage,
					taxId: data.taxId,
				});
			})
			.catch((err) => {
				addToast({ message: err.response.data.message, error: true });
			})
			.finally(() => setGlobalLoading(false));
	};

	useEffectCustom(() => {
		const getData = () => {
			if (id) {
				setTitle(`${t('edit')} ${t('user')}`);
				getUser();
			} else {
				getDropdowns();
				setTitle(`${t('new')} ${t('user')}`);
			}
		};
		getData();
	}, [id]);

	const addImage = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			if (event.target.files.length > 0) {
				setProfileImage(event.target.files[0]);
			}
		}
	};

	const getDropdownById = async (action: string, dropdownId: number) => {
		switch (action) {
		case DROPDOWN_ACTION.COUNTRY:
		{
			const mStates = await getStates(dropdownId);
			setStates(mStates.data);
			break;
		}
		case DROPDOWN_ACTION.STATE:
		{
			const mCities = await getCities(dropdownId);
			setCities(mCities.data);
			break;
		}
		default:
			break;
		}
	};

	const validateUser = (values: IUserReq) => {
		const errors: Partial<IUserReq> = {};
		// eslint-disable-next-line no-useless-escape
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.email) {
			errors.email = t('emptyField');
		}
		if (!emailRegex.test(values.email)) {
			errors.email = t('invalidEmail');
		}
		if (!values.phone) {
			errors.phone = t('emptyField');
		}
		if (values.phone.length > 0 && values.phone.length < PHONE_LENGTH) {
			errors.phone = t('phoneError');
		}
		if (!values.country) {
			errors.country = t('emptyField');
		}
		if (!values.state) {
			if (values.country?.id === 1) {
				errors.state = t('emptyField');
			}
		}
		if (values.state && Object.keys(values.state).length === 0) {
			if (values.country?.id === 1) {
				errors.state = t('emptyField');
			}
		}
		if (!values.city) {
			if (values.country?.id === 1) {
				errors.city = t('emptyField');
			}
		}
		if (values.city && Object.keys(values.city).length === 0) {
			if (values.country?.id === 1) {
				errors.city = t('emptyField');
			}
		}
		if (!id) {
			if (!values.taxId) {
				errors.taxId = t('emptyField');
			}
			if (values.taxId && values.taxId.length < 10) {
				errors.taxId = t('invalidNifMessage');
			}
		}
		return errors;
	};

	const checkNif = (nif: string, setFieldValue: (key: string, value: string) => void) => {
		const isValidated = validateNif(nif);
		if (!isValidated) {
			setFieldValue('taxId', '');
			addDialog({ title: t('invalidNif'), message: t('invalidNifMessage'), error: true });
		}
	};

	const submit = (values: IUserReq, formikHelpers: FormikHelpers<IUserReq>) => {
		if (id) {
			updateUser({ ...values, profileImage }, id)
				.then((res) => {
					addToast({ message: res.message, error: false });
					formikHelpers.setSubmitting(false);
					if (profileImage.size) {
						setUserImage(res.data.profileImage);
						navigate(AppRoutes.LIST_USERS);
						window.location.reload();
					}
				})
				.catch(({ response }) => {
					addDialog({ title: response.data.title, message: response.data.message, error: true });
				}).finally(() => formikHelpers.setSubmitting(false));
		} else {
			addNewUser(values)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.LIST_USERS);
					// setShowDialog(true);
					// setDialogData({ message: res.title, title: res.message });
					formikHelpers.resetForm({});
				})
				.catch(({ response }) => {
					addDialog({ title: response.data.title, message: response.data.message, error: true });
				})
				.finally(() => formikHelpers.setSubmitting(false));
		}
	};

	return (
		<CreateUserPage
			initialValues={initialValues}
			countries={countries}
			states={states}
			cities={cities}
			checkNif={checkNif}
			requestById={getDropdownById}
			validateForm={validateUser}
			submitForm={submit}
			edit={!!id}
			addFile={addImage}
			image={profileImage}
		/>
	);
};

export default CreateUser;
