import { useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@Api';
import { getReferralReport, getOrganizations } from '@Requests';
import { useEffectCustom } from '@Hooks';
import { IOrganization, IReferralReport } from '@Types';
import { exportUserExcel } from '@Export';
import moment from 'moment';
import { formatCurrency } from '@Helpers';
import ReferralReportPage from './ReferralReportPage';

const FIRST_DAY_OF_MONTH = new Date(new Date().setDate(1));

const ReferralReport = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const abortCtrl = new AbortController();

	const referrals = useRequest(([params]) => getReferralReport(params));
	const companies = useRequest(([params]) => getOrganizations(params, abortCtrl.signal));

	const [referralReportReq, setReferralReportReq] = useState({
		organization: [] as IOrganization[],
		startDate: FIRST_DAY_OF_MONTH,
		endDate: new Date(),
	});

	useEffect(() => {
		setTitle(`${t('reports')} - ${t('referral')}`);
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: 1,
				size: 10000,
				search: '',
			};
			companies.execute(listDataControl);
		};
		getData();
		return () => {
			abortCtrl.abort();
		};
	}, []);

	const getReferrals = () => {
		referrals.execute(referralReportReq);
	};

	const onChange = (key: string, value: IOrganization[] | Date) => {
		setReferralReportReq({ ...referralReportReq, [key]: value });
	};

	const exportReferralExcel = () => {
		if (referrals.data) {
			const header = [
				'ID',
				t('referredBy'),
				t('referral'),
				t('phone'),
				t('company'),
				`${t('product')}/${t('service')}`,
				t('responsible'),
				t('serviceValue'),
				t('commission'),
				t('balenceDate'),
			];
			const coupons = referrals.data?.map((row: IReferralReport) => ({
				id: row.id,
				referredBy: row.fromUserName,
				referral: row.name,
				phone: row.phone,
				company: row.organizationName,
				product: row.serviceName,
				responsible: row.sellerName,
				serviceValue: formatCurrency(row.serviceCost),
				commission: '',
				balenceDate: '',
			}));
			exportUserExcel(`${t('referral')}-${moment(new Date()).format('DD-MM-YYYY HH:mm')}.xlsx`, coupons, header);
		}
	};

	return (
		<ReferralReportPage
			values={referralReportReq}
			companies={companies.data?.data}
			onChange={onChange}
			referrals={referrals.data}
			getReferralReport={getReferrals}
			exportExcel={exportReferralExcel}
		/>
	);
};

export default ReferralReport;
