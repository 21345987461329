import { Space, DefaultSelect, DefaultButton } from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Chip,
	Divider,
	Grid,
	SelectChangeEvent,
	Typography,
	useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITag } from '@Types';

interface UserTagsProps {
   tags?: ITag[];
   selectedTag?: number;
   selectedTagError: string;
   handleChange: (event: SelectChangeEvent<string>) => void;
   loading: boolean;
   handleSubmit: () => void;
   userTags?: ITag[];
   handleDelete: (tagId: number) => void;
}

const UserTagsPage = (props: UserTagsProps) => {
	const {
		tags,
		selectedTag,
		handleChange,
		loading,
		handleSubmit,
		userTags,
		handleDelete,
		selectedTagError,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<Grid container sx={{ padding: theme.spacing(3) }} spacing={2}>
			<Grid item xs={6} md={6}>
				<PaperContainer>
					<DefaultSelect
						options={tags || []}
						label={t('tags')}
						value={selectedTag}
						disabled={loading}
						error={!!selectedTagError}
						helperText={selectedTagError}
						renderItem={(item) => ({ optionLabel: item.name, optionValue: item.id })}
						onChange={handleChange}
					/>
					<Space height={2} />
					<DefaultButton title={t('save')} loading={loading} onClick={handleSubmit} />
					<Space height={2} />
					<Divider />
					<Space height={2} />
					<Typography variant="h6" fontWeight={500}>
						{t('addedTags')}
					</Typography>
					<Space height={2} />
					{ userTags?.map((el) => (
						<span key={`user-tag-chip-${el.id}`}>
							<Chip
								label={el.name}
								onDelete={() => { handleDelete(el.id); }}
								color="secondary"
								sx={{ mr: 1, mt: 1 }}
							/>
						</span>
					))}
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default UserTagsPage;
