import { PaperContainer } from '@Molecules';
import {
	DateLabel, DefaultButton, Dropdown, StatusLabel, TableItem,
} from '@Atoms';
import {
	Grid, useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IPagination, IPaginationTable, IUser } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Mail, Phone } from '@mui/icons-material';
import { FontSizes } from '@Constants';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgCrown } from '@Images';

interface ListUserPageProps {
   data?: IPagination<IUser[]>;
   onSearch: (query: string) => void;
   onPageChange: (pagination: IPaginationTable) => void;
   handleNavigate: () => void;
   onMenuClick: (action: string, userId: number) => void;

}

const ListUsersPage: FC<ListUserPageProps> = (props) => {
	const {
		data, onSearch, onPageChange, handleNavigate, onMenuClick,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<div>
			<Grid container sx={{ padding: theme.spacing(3) }} spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={handleNavigate} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={data?.data || []}
							onSearching={(query) => onSearch(query)}
							onChange={(page, size) => onPageChange({ page, size })}
							pagination
							total={data?.total}
						>
							<TableColumn headerText="ID" render={(item) => item.id} />
							<TableColumn headerText={t('registration')} render={(item) => <DateLabel date={item.registrationDate} showTime />} />
							<TableColumn
								headerText={t('nameClassification')}
								render={(item) => (
									<TableItem
										title={item.name}
										subTitle={item.scoreLevel}
										subTitleIcon={(
											<SvgIcon
												sx={{ fontSize: FontSizes.MD, color: theme.palette.text.primary }}
											>
												<SvgCrown />
											</SvgIcon>
										)}
									/>
								)}
							/>
							<TableColumn
								headerText={t('phoneEmail')}
								render={(item) => (
									<TableItem
										title={item.phone}
										subTitle={item.email}
										titleIcon={<Phone sx={{ fontSize: FontSizes.MD }} />}
										subTitleIcon={<Mail sx={{ fontSize: FontSizes.SM }} />}
									/>
								)}
							/>
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
							<TableColumn
								render={(item) => (
									<Dropdown
										data={[
											t('changeStatus'),
											t('promoteAdm'),
											t('linkOrg'),
											t('sendMessage'),
											t('addBalence'),
											t('vehicles'),
											t('tags'),
											t('resetPassword'),
										]}
										label={t('action')}
										renderItem={(dropdownItem) => ({
											optionValue: dropdownItem,
											optionLabel: dropdownItem,
										})}
										onChange={(action) => {
											onMenuClick(action, item.id);
										}}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ListUsersPage;
