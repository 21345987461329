import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';

export interface Data {
   title?: string;
   message: string;
   error: boolean;
}

export interface FeedbackProviderProps {
   open: boolean;
   openDailog: boolean;
   toastProps: Data | null;
   removeToast: () => void;
   addToast: (data: Data) => void;
   addDialog: (data: Data) => void
   dialogProps: Data | null
}

export const Feedback = createContext<FeedbackProviderProps>({} as FeedbackProviderProps);

export const FeedbackProvider: FC = ({ children }) => {
	const [toastProps, setToastProps] = useState<Data | null>(null);
	const [open, setOpen] = useState(false);
	const [dialogProps, setDialogProps] = useState<Data | null>(null);
	const [openDailog, setOpenDailog] = useState(false);

	const value = useMemo(() => ({
		open,
		toastProps,
		openDailog,
		dialogProps,
		removeToast: () => {
			setOpen(false);
			setOpenDailog(false);
			setToastProps(null);
		},
		addToast: (data: Data) => {
			setOpen(true);
			setToastProps(data);
		},
		addDialog: (data: Data) => {
			setOpenDailog(true);
			setDialogProps(data);
		},
	}), [open, toastProps, openDailog, dialogProps]);

	return <Feedback.Provider value={value}>{children}</Feedback.Provider>;
};

export default function useFeedback() {
	const {
		open, toastProps, removeToast, addToast, openDailog, addDialog, dialogProps,
	} = useContext(Feedback);
	return {
		open, toastProps, removeToast, addToast, openDailog, addDialog, dialogProps,
	};
}
