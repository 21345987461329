import {
	ApiResponse,
	ICities,
	ICountry,
	IStates,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

export const getCountries = async (): Promise<ApiResponse<ICountry[]>> => {
	const resp = await reqGet({ url: Endpoint.COUNTRIES });
	const data = resp.data as ApiResponse<ICountry[]>;
	data.statusCode = resp.status;
	return data;
};

export const getStatesByCountryId = async (countryId: number): Promise<ApiResponse<IStates[]>> => {
	const resp = await reqGet({ url: Endpoint.STATES.format(countryId) });
	const data = resp.data as ApiResponse<IStates[]>;
	data.statusCode = resp.status;
	return data;
};

export const getCitiesByStateId = async (stateId: number): Promise<ApiResponse<ICities[]>> => {
	const resp = await reqGet({ url: Endpoint.CITIES.format(stateId) });
	const data = resp.data as ApiResponse<ICities[]>;
	data.statusCode = resp.status;
	return data;
};
