import { FontSizes } from '@Constants';
import { Box, Stack, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';

interface TableItemProps {
   title: string;
   subTitle?: string;
   titleIcon?: ReactNode;
   subTitleIcon?: ReactNode;
   endIcon?: ReactNode;
   color?: string;
   width?: string;
   lineClamp?: number
}

const TableItem: FC<TableItemProps> = ({
	title, subTitle, titleIcon, subTitleIcon, color, width, lineClamp = 3, endIcon,
}) => (
	<Box>
		<Stack direction="row">
			{titleIcon ?? ''}
			<Typography
				sx={{
					fontSize: FontSizes.MD,
					display: '-webkit-box',
					overflow: 'hidden',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: lineClamp,
					width,
				}}
				color={color}
			>

				{' '}
				{title}

			</Typography>
			{endIcon}
		</Stack>

		{subTitle && (
			<Stack direction="row">
				{subTitleIcon && (
					<>
						{subTitleIcon}
						<span style={{ width: 5 }} />
					</>
				)}
				<Typography sx={{
					fontSize: FontSizes.SM,
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
				>
					{' '}
					{subTitle}
				</Typography>
			</Stack>
		)}
	</Box>
);

export default TableItem;
