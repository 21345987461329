import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getOrderById } from '@Requests';
import { IOrder } from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import OrderDetailPage from './OrderDetailPage';

const OrderDetail = () => {
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const [order, setOrder] = useState<IOrder>();
	const { id } = useParams();
	const { addToast } = useFeedback();

	useEffectCustom(() => {
		setTitle(t('orderDetials'));
		if (!order) {
			setGlobalLoading(true);
			getOrderById(id)
				.then((res) => setOrder(res.data))
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => {
					setGlobalLoading(false);
				});
		}
	}, []);

	return <OrderDetailPage order={order} />;
};

export default OrderDetail;
