import { useRequest } from '@Api';
import { changeUserStatus, getUsers, resetPassword } from '@Requests';
import { AppRoutes } from '@Routes';
import { useFeedback, useTitleBar } from '@Providers';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListDataControl, IPaginationTable } from '@Types';
import { useTranslation } from 'react-i18next';
import { useEffectCustom } from '@Hooks';
import ListUsersPage from './ListUsersPage';

const ListUsers = () => {
	const { addToast } = useFeedback();
	const abortCtrl = new AbortController();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const getAllUsers = useRequest(([params]) => getUsers(params, abortCtrl.signal));
	const changeStatus = useRequest(([params]) => changeUserStatus(params));

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();

	useEffect(() => {
		setTitle(t('users'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		if (changeStatus.data) {
			getAllUsers.setData((prev) => {
				const index = getAllUsers.data?.data.findIndex((el) => el.id === changeStatus.data?.id);
				const Mprev = prev;
				if (Mprev) {
					if (index !== undefined && changeStatus.data) {
						Mprev.data[index].status = changeStatus?.data.status;
					}
				}
				return Mprev;
			});
		}
	}, [changeStatus.data]);

	useEffectCustom(() => {
		const getData = () => {
			const listControl: IListDataControl = {
				page: pagination.page,
				search,
				size: pagination.size,
			};
			getAllUsers.execute(listControl);
		};

		getData();
		return () => {
			abortCtrl.abort();
		};
	}, [search, pagination.page, pagination.size]);

	const resetUserPassword = (userId: number) => {
		setGlobalLoading(true);
		resetPassword(userId)
			.then((res) => {
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => setGlobalLoading(false));
	};

	const handleMenuClick = (action: string, id: number) => {
		switch (action) {
		case t('changeStatus'):
			changeStatus.execute(id);
			break;
		case t('promoteAdm'):
			navigate(AppRoutes.PROMOTE_ADM.formatMap({ id }));
			break;
		case t('linkOrg'):
			navigate(AppRoutes.LINK_TO_CC.formatMap({ id }));
			break;
		case t('sendMessage'):
			navigate(AppRoutes.SEND_MESSAGE.formatMap({ id }));
			break;
		case t('addBalence'):
			navigate(AppRoutes.BALENCE_POINTS.formatMap({ id }));
			break;
		case t('vehicles'):
			navigate(AppRoutes.USER_VEHICLES.formatMap({ id }));
			break;
		case t('tags'):
			navigate(AppRoutes.USER_TAGS.formatMap({ id }));
			break;
		case t('resetPassword'):
			resetUserPassword(id);
			break;
		default:
			break;
		}
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListUsersPage
			data={getAllUsers.data}
			onSearch={setTableSearch}
			onPageChange={setTablePagination}
			handleNavigate={() => navigate(AppRoutes.NEW_USER)}
			onMenuClick={handleMenuClick}
		/>
	);
};

export default ListUsers;
