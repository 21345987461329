import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getOrders, cancelOrder } from '@Requests';
import {
	IListDataControl,
	IPagination,
	IOrder,
	IPaginationTable,
} from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import OrderListPage from './OrdersPage';

const OrderList = () => {
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();
	const { addToast } = useFeedback();

	const [orders, setOrders] = useState<IPagination<IOrder[]>>();
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	const getData = () => {
		setGlobalLoading(true);
		const listControl: IListDataControl = {
			page: pagination.page,
			search,
			size: pagination.size,
		};

		getOrders(listControl)
			.then((res) => {
				setOrders(res.data);
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => setGlobalLoading(false));
	};

	const onCancelOrder = (id: string) => {
		setGlobalLoading(true);
		cancelOrder(id)
			.then((res) => {
				const index = orders?.data?.findIndex((el) => el.id === res?.data?.id);
				if (index !== undefined && orders) {
					const newOrders: IPagination<IOrder[]> = Object.create(orders);
					newOrders.data[index] = res.data;
					setOrders(newOrders);
				}
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
	};

	useEffect(() => {
		setTitle(t('orders'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		getData();
	}, [search, pagination]);

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<OrderListPage
			data={orders}
			onPageChange={setTablePagination}
			onSearch={setTableSearch}
			onCancelOrder={onCancelOrder}
		/>
	);
};

export default OrderList;
