import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getRefferals } from '@Requests';
import {
	IListDataControl,
	IPagination,
	IPaginationTable,
	IReferral,
} from '@Types';
import React, {
	MutableRefObject, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import ReferralsListPage from './ReferralsListPage';

const ReferralsList = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const resetPagination = useRef() as MutableRefObject<() => void>;

	const [referrals, setReferrals] = useState<IPagination<IReferral[]> | undefined>();
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const [selectedTab, setSelectedTab] = useState(0);

	useEffectCustom(() => {
		const getData = () => {
			setGlobalLoading(true);
			setTitle(t('referrals'));
			const listControl: IListDataControl = {
				page: pagination.page,
				search,
				size: pagination.size,
			};
			getRefferals(listControl, selectedTab + 1)
				.then((res) => {
					setReferrals(res.data);
				})
				.catch(({ response }) => {
					addToast({ message: response.data.message, error: true });
				})
				.finally(() => setGlobalLoading(false));
		};
		getData();
	}, [search, pagination]);

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setSelectedTab(newValue);
		setPagination({ page: 1, size: 10 });
		setSearch('');
		resetPagination.current();
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ReferralsListPage
			referrals={referrals}
			onPageChange={setTablePagination}
			onSearch={setTableSearch}
			onTabChange={handleTabChange}
			selectedTab={selectedTab}
			resetPagination={resetPagination}
		/>
	);
};

export default ReferralsList;
