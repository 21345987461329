import {
	DefaultButton, Image, Space, TextField,
} from '@Atoms';
import { logo } from '@Images';
import { Box, Container, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ForgotPasswordProps {
	email: string;
	handleChange: (email: string) => void;
	error: string;
	handleSubmit: () => void;
	loading: boolean
}

const ForgotPasswordPage: FC<ForgotPasswordProps> = (props) => {
	const {
		email, handleChange, handleSubmit, error, loading,
	} = props;
	const { t } = useTranslation('translations');

	return (
		<Container>
			<Container maxWidth="xs">
				<Space height={5} />

				<Image src={logo} height="100%" width="100%" alt="My AJ Logo" />

				<Space height={3} />
				<form onSubmit={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
				>

					<TextField value={email} label={t('email')} onChange={(event) => handleChange(event.target.value)} error={!!error} helperText={error} />

					<Space height={2} />

					<DefaultButton loading={loading} title={t('passwordResetLink')} type="submit" fullWidth />
				</form>
				<Space height={2} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						{process.env.REACT_APP_NAME}
						{' '}
						-
						{' '}
						{t('copyright')}
						{' '}
						©
						{' '}
						{new Date().getFullYear()}
					</Typography>
				</Box>

			</Container>
		</Container>
	);
};

export default ForgotPasswordPage;
