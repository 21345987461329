import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getOrderById, fixOrder } from '@Requests';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IOrder } from '@Types';
import { AppRoutes } from '@Routes';
import OrderFixPage from './OrderFixPage';

const OrderFix = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { addToast } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState({ invoiceNumber: '', serie: '', year: '' });
	const [order, setOrder] = useState({} as IOrder);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTitle(t('orderCorrection'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			if (id) {
				getOrderById(id).then((res) => {
					setInitialValues({
						invoiceNumber: String(res.data.invoiceNumber ?? ''),
						serie: res.data.serie ?? '',
						year: String(res.data.year ?? ''),
					});
					setOrder(res.data);
				});
			}
		};
		getData();
	}, [id]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setInitialValues({ ...initialValues, [event.target.name]: event.target.value });
	};

	const handleSubmit = () => {
		setLoading(true);
		fixOrder(id ?? '', initialValues)
			.then((res) => {
				addToast({ message: res.message, error: false });
				setOrder(res.data);
				navigate(AppRoutes.LIST_ORDERS);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setLoading(false));
	};

	return (
		<OrderFixPage
			order={order}
			initialValues={initialValues}
			onChange={handleChange}
			onSubmit={handleSubmit}
			loading={loading}
		/>
	);
};

export default OrderFix;
