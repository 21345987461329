import React from 'react';
import { login } from '@Requests';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { FormikHelpers } from 'formik';
import { useAuth, useFeedback, useSettingContext } from '@Providers';
import { EMAIL_PATTERN } from '@Constants';
import LoginPage, { LoginValues } from './LoginPage';

const Login = () => {
	const initialValues = { email: '', password: '' };

	const { setCurrentUser } = useAuth();
	const { uniqueHash } = useSettingContext();
	const { addToast } = useFeedback();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');

	const loginUser = (values: LoginValues, formikHelpers: FormikHelpers<LoginValues>) => {
		login({ ...values, deviceUniqueId: uniqueHash })
			.then((res) => {
				setCurrentUser(res.data);
				navigate(AppRoutes.DASHBOARD);
			})
			.catch((error) => {
				addToast({ message: error.response.data.message, error: true });
			})
			.finally(() => {
				formikHelpers.setSubmitting(false);
			});
	};

	const validate = (values: LoginValues) => {
		const errors: Partial<LoginValues> = {};
		if (!values.email) {
			errors.email = t('emptyField');
		} else if (!EMAIL_PATTERN.test(values.email)) {
			errors.email = t('invalidEmail');
		}
		if (!values.password) {
			errors.password = t('emptyField');
		}
		return errors;
	};

	const forgotPassword = () => {
		navigate(AppRoutes.FORGOT_PASSWORD);
	};

	return (
		<LoginPage
			submit={loginUser}
			initialValues={initialValues}
			validate={validate}
			forgotPassword={forgotPassword}
		/>
	);
};

export default Login;
