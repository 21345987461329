import { useRequest } from '@Api';
import { padId } from '@Helpers';
import { useEffectCustom } from '@Hooks';
import { Close } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import { addTicketMessage, getTicketById, changeTicketStatus } from '@Requests';
import { AppRoutes } from '@Routes';
import { ITicketMessageReq } from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import React, {
	useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import EditTicketPage from './EditTicketPage';
import HistoryTable from './HistoryTable';

const EditTicket = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const navigate = useNavigate();
	const { addToast, addDialog } = useFeedback();

	const ticket = useRequest(([params]) => getTicketById(params));

	const [initialValues] = useState({
		message: '',
		fileAttachmentDescription: '',
	} as ITicketMessageReq);
	const [showHistory, setShowHistory] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTitle(`${t('ticket')} ${padId(id ?? '', 4)}`);
	}, [setTitle, t, id]);

	useEffectCustom(() => {
		const getData = () => {
			ticket.execute(id ?? '');
		};
		getData();
	}, []);

	const changeStatus = (statusId: number) => {
		setLoading(true);
		changeTicketStatus(statusId, id ?? '')
			.then((res) => ticket.setData(res.data))
			.catch(({ response }) => addToast({ message: response.data.message, error: false }))
			.finally(() => setLoading(false));
	};

	const handleActionClick = (action: string, statusId?: number) => {
		switch (action) {
		case t('back'):
			navigate(AppRoutes.LIST_TICKETS);
			break;
		case t('history'):
			setShowHistory(true);
			break;
		default:
			changeStatus(statusId ?? 0);
			break;
		}
	};

	const validate = (values: ITicketMessageReq) => {
		const errors = {} as FormikErrors<ITicketMessageReq>;
		if (!values.message) {
			errors.message = t('emptyField');
		}
		return errors;
	};

	const submitMessage = (values: ITicketMessageReq, helpers: FormikHelpers<ITicketMessageReq>) => {
		addTicketMessage(values, id ?? '')
			.then((res) => {
				addToast({ message: res.message, error: false });
				ticket.setData(res.data);
				helpers.resetForm({});
			})
			.catch(({ response }) => {
				addDialog({
					title: response.title,
					message: response.data.message,
					error: true,
				});
			})
			.finally(() => helpers.setSubmitting(false));
	};

	const closeHistory = () => {
		setShowHistory(false);
	};

	return (
		<>
			<Dialog
				open={showHistory}
				handleClose={closeHistory}
				title={(
					<Stack direction="row" justifyContent="space-between" width="100%">
						<Typography>{t('history')}</Typography>
						<IconButton onClick={closeHistory} sx={{ p: 0, m: 0 }}>
							<Close />
						</IconButton>
					</Stack>
				)}
				description={(
					<HistoryTable ticket={ticket.data} />
				)}
				primaryButtonText={t('close')}
			/>
			<EditTicketPage
				ticket={ticket.data}
				onActionClick={handleActionClick}
				initialValues={initialValues}
				validate={validate}
				onSaveMessage={submitMessage}
				loading={loading}
			/>
		</>
	);
};

export default EditTicket;
