import {
	Autocomplete, DefaultButton, Space,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Button, Grid, Stack, TextField, Typography, useTheme,
} from '@mui/material';
import { IOrganization, ICostCenter } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Values { organization: IOrganization; costCenter: ICostCenter | null }
interface Errors {organization: string; costCenter: string}

interface LinkToCCProps {
   initialValues: Values;
   organizations?: IOrganization[];
   costCenters?: ICostCenter[] ;
   userCostCenters?: ICostCenter[];
   loading: boolean;
   handleRemove: (costCenterId: number) => void;
   handleOrganizationChange: (organization: IOrganization) => void;
   handleCCChange: (cc: ICostCenter) => void;
   handleSave: () => void;
   errors: Errors
}

const LinkToCostCenterPage = (props: LinkToCCProps) => {
	const {
		initialValues,
		organizations,
		userCostCenters,
		costCenters,
		loading,
		handleRemove,
		handleOrganizationChange,
		handleCCChange,
		handleSave,
		errors,
	} = props;

	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Grid container sx={{ padding: theme.spacing(3) }} spacing={2}>
			<Grid item xs={12} md={12}>
				<Grid item xs={12} md={12} />
				<PaperContainer>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Autocomplete
								label={t('organizations')}
								placeholder=""
								disabled={loading}
								options={organizations || []}
								getOptionLabel={(item) => item.fantasyName || ''}
								renderOption={(autoCompleteProps, item) => (
									<li {...autoCompleteProps}>{item.fantasyName}</li>
								)}
								error={!!errors.organization}
								helperText={errors.organization}
								defaultValue={initialValues.organization}
								isOptionEqualToValue={(item, option) => item.id === option.id}
								onChange={(_, item) => {
									if (item) {
										handleOrganizationChange(item);
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Autocomplete
								options={costCenters || []}
								disabled={loading}
								getOptionLabel={(item) => item.name || ''}
								renderOption={(autoCompleteProps, item) => (
									<li {...autoCompleteProps}>{item.name}</li>
								)}
								defaultValue={initialValues.costCenter}
								error={!!errors.costCenter}
								helperText={errors.costCenter}
								isOptionEqualToValue={(item, option) => item.id === option.id}
								label={t('costCenter')}
								placeholder=""
								onChange={(_, item) => {
									if (item) {
										handleCCChange(item);
									}
								}}
							/>
						</Grid>
					</Grid>
					<Space height={2} />
					<DefaultButton title={t('save')} loading={loading} onClick={handleSave} />

				</PaperContainer>
				<Space height={3} />
				<PaperContainer>
					<Grid container spacing={3}>
						<Grid item xs={12} md={12}>
							<Typography variant="h5" fontWeight={200}>
								{t('userBelongToCostCenter')}
							</Typography>
							<Space height={3} />
							{ userCostCenters?.map((el) => (
								<div key={`user-cost-center-${el.id}`}>
									<Stack direction="row">
										<Button
											key={el.id}
											color="error"
											variant="contained"
											size="small"
											disableElevation
											onClick={() => handleRemove(el.id)}
										>
											{t('remove')}

										</Button>
										<TextField placeholder={`${el.costCenter} - ${el.organization.fantasyName}`} fullWidth disabled size="small" />
									</Stack>
									<Space height={3} />

								</div>
							))}
						</Grid>
					</Grid>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default LinkToCostCenterPage;
