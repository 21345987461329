import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import {
	addUserToCostCenter,
	getCostCenters,
	getOrganizations,
	getUserCostCenters,
	removeUserFromCC,
} from '@Requests';
import { IOrganization, ICostCenter } from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LinkToCostCenterPage from './LinkToCostCenterPage';

const LinkToCostCenter = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();

	const { id } = useParams<{ id: string }>();

	const organizations = useRequest(([params, signal]) => getOrganizations(params, signal));
	const costCenters = useRequest(([params]) => getCostCenters(params));
	const userCostCenters = useRequest(([params]) => getUserCostCenters(params));
	const [initialValues, setInitialValues] = useState({
		title: '',
		message: '',
		organization: {} as IOrganization,
		costCenter: {} as ICostCenter | null,
	});
	const [errors, setErrors] = useState({ organization: '', costCenter: '' });
	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		setTitle(t('addtoCostCenter'));
		const getData = () => {
			const listDataControl = {
				page: 1,
				size: 1500,
				search: '',
			};
			organizations.execute(listDataControl, abortCtrl.signal);
			userCostCenters.execute(id);
		};
		getData();
		return () => abortCtrl.abort();
	}, []);

	useEffectCustom(() => {
		if (organizations.data) {
			setInitialValues({ ...initialValues, organization: organizations.data.data[0] });
			costCenters.execute(organizations.data.data[0].id);
		}
	}, [organizations.data]);

	useEffectCustom(() => {
		if (costCenters.data) {
			setInitialValues({ ...initialValues, costCenter: costCenters.data.data[0] });
			if (costCenters.data.data[0]) {
				setErrors({ ...errors, costCenter: '' });
			} else {
				setInitialValues({ ...initialValues, costCenter: null });
				setErrors({ ...errors, costCenter: t('noCostCenter') });
			}
		}
	}, [costCenters.data]);

	const handleRemove = (costCenterId: number) => {
		removeUserFromCC(id, costCenterId)
			.then(() => {
				if (userCostCenters.data) {
					const cc = [...userCostCenters.data];
					const index = cc.findIndex((el) => el.id === costCenterId);
					cc.splice(index, 1);
					userCostCenters.setData(cc);
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const handleCCChange = (item: ICostCenter) => {
		setInitialValues({ ...initialValues, costCenter: item });
		setErrors({ ...errors, organization: '' });
	};

	const handleOrganizationChange = (item: IOrganization) => {
		setInitialValues({ ...initialValues, organization: item });
		costCenters.execute(item.id);
	};

	const addUserToCC = () => {
		if (!initialValues.costCenter) {
			return setErrors({ ...errors, costCenter: t('emptyField') });
		}
		if (!initialValues.organization) {
			return setErrors({ ...errors, organization: t('emptyField') });
		}
		setErrors({ costCenter: '', organization: '' });
		setLoading(true);
		addUserToCostCenter(id, initialValues.costCenter.id)
			.then((res) => {
				if (userCostCenters.data) {
					const newCC = [...userCostCenters.data];
					newCC.push(res.data);
					userCostCenters.setData(newCC);
					if (organizations.data) {
						setInitialValues({
							...initialValues,
							organization: organizations.data.data[0],
							costCenter: null,
						});
						costCenters.execute(organizations.data.data[0].id);
					}
				}
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => setLoading(false));
		return null;
	};

	return (
		<LinkToCostCenterPage
			organizations={organizations.data?.data}
			initialValues={initialValues}
			userCostCenters={userCostCenters.data}
			costCenters={costCenters.data?.data}
			loading={loading}
			handleRemove={handleRemove}
			handleCCChange={handleCCChange}
			handleOrganizationChange={handleOrganizationChange}
			handleSave={addUserToCC}
			errors={errors}
		/>
	);
};

export default LinkToCostCenter;
