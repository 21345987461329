import { PaperContainer } from '@Molecules';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IRating } from '@Types';
import { AppRoutes } from '@Routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { DefaultButton, Image } from '@Atoms';

export interface RatingsListPageProps {
   ratings?: IRating[];
}

const RatingsListPage = (props: RatingsListPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { ratings } = props;
	const navigate = useNavigate();

	const handleListAction = (action: string, rating?: IRating) => {
		switch (action) {
		case t('edit'): {
			navigate('/ratings/edit/{0}'.format(rating?.id));
			break;
		}
		default: {
			navigate(AppRoutes.NEW_RATING);
			break;
		}
		}
	};
	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={2}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable data={ratings || []} search={false}>
							<TableColumn headerText="ID" render={(item) => `${item.id}`} />
							<TableColumn headerText={t('level')} render={(item) => `${item.name}`} />
							<TableColumn headerText={t('points')} render={(item) => `${item.minScore}`} />
							<TableColumn
								headerText={t('icon')}
								render={(item) => (
									<Image
										height={40}
										width={40}
										src={item.icon}
										alt="level icon"
									/>
								)}
							/>
							<TableColumn
								headerText=""
								align="right"
								render={(item) => (
									<DefaultButton title={t('edit')} onClick={() => handleListAction(t('edit'), item)} />
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</div>
	);
};

export default RatingsListPage;
