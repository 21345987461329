import { IPagination, IPaginationTable, ITag } from '@Types';
import React, { FC } from 'react';
import { useTheme, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableItem, Dropdown, DefaultButton } from '@Atoms';
import { PaperContainer } from '@Molecules';
import { DataTable, TableColumn } from '@Organisms';

interface ListTagsProps {
	tags?: IPagination<ITag[]>;
	handleListAction: (action: string, tag?: ITag) => void;
	onSearch: (query: string) => void;
	onPagination: (pagination: IPaginationTable) => void
}

const ListTagsPage: FC<ListTagsProps> = (props) => {
	const {
		tags, handleListAction, onSearch, onPagination,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('new')} onClick={() => handleListAction(t('new'))} />
				</Grid>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={tags?.data || []}
							pagination
							onSearching={onSearch}
							onChange={(page, size) => onPagination({ page, size })}
							total={tags?.total}
						>
							<TableColumn headerText="ID" render={(tag) => <TableItem title={tag.id} />} />
							<TableColumn headerText={t('name')} render={(tag) => <TableItem title={tag.name} />} />
							<TableColumn headerText={t('description')} render={(tag) => tag.description} />
							<TableColumn
								align="right"
								render={(tag) => (
									<Dropdown
										variant="contained"
										label={t('action')}
										data={[t('edit')]}
										renderItem={(dropdownItem) => (
											{ optionValue: dropdownItem, optionLabel: dropdownItem }
										)}
										onChange={(optionLabel) => handleListAction(optionLabel, tag)}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ListTagsPage;
