import {
	Checkbox, DefaultButton, DefaultSelect, Row, Space, TextField,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Grid,
	useTheme,
	Typography,
} from '@mui/material';
import { IUser } from '@Types';
import {
	Form, Formik, FormikHelpers,
} from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from '.';

interface SendMessageProps {
   user?: IUser;
   initialValues: Message;
   validateForm: (values: Message) => Partial<Message>;
   submitForm: (values: Message, formikHelpers: FormikHelpers<Message>) => void;
}

const SendMessagePage = (props: SendMessageProps) => {
	const {
		user,
		initialValues,
		validateForm,
		submitForm,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<div>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xs={12} md={12} lg={4} xl={4}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							onSubmit={(values, formikHelpers) => {
								submitForm(values, formikHelpers);
							}}
							validateOnBlur={false}
							validateOnChange={false}
							validateOnMount={false}
							validate={validateForm}
						>
							{({
								values, setFieldValue, isSubmitting, errors, handleChange,
							}) => (
								<Form autoComplete="off">
									<TextField label={t('recipient')} size="small" fullWidth value={user?.name || ''} disabled autoComplete="none" />
									<Space height={2} />
									<TextField
										name="title"
										label={t('title')}
										value={values.title}
										onChange={handleChange}
										error={!!errors.title}
										helperText={errors.title}
									/>
									<Space height={2} />
									<TextField
										name="message"
										label={t('message')}
										onChange={handleChange}
										value={values.message}
										error={!!errors.message}
										helperText={errors.message}
										minRows={4}
										multiline
									/>
									<Space height={2} />
									<DefaultSelect
										options={[{ label: 'My AJ', id: 1 }, { label: 'AJ Partner', id: 2 }]}
										label={t('application')}
										value={values.application}
										error={!!errors.application}
										disabled={isSubmitting}
										helperText={errors.application}
										renderItem={(item) => ({ optionLabel: item.label, optionValue: item.id })}
										onChange={(event) => {
											setFieldValue('application', event.target.value);
										}}
									/>
									<Space height={2} />
									<Row justify="space-between">
										<Checkbox
											checked={values.pushNotification}
											disabled={isSubmitting}
											label={t('pushNotifications')}
											onChange={(event, checked) => {
												setFieldValue('pushNotification', checked);
											}}
										/>
										<Checkbox
											checked={values.email}
											disabled={isSubmitting || !user?.email}
											label={t('email')}
											onChange={(event, checked) => {
												setFieldValue('email', checked);
											}}
										/>
										<Checkbox
											checked={values.sms}
											disabled={isSubmitting || !user?.phone}
											label={t('sms')}
											onChange={(event, checked) => {
												setFieldValue('sms', checked);
											}}
										/>
									</Row>
									{errors.pushNotification && (
										<Typography variant="caption" color="red">
											{t('emptyField')}
										</Typography>
									)}
									<Space height={2} />
									<DefaultButton loading={isSubmitting} title={t('send')} type="submit" />
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default SendMessagePage;
