import { useRequest } from '@Api';
import { CARD_STATUSES } from '@Constants';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import {
	getCardById, getCardUsers, getUserByPhone, changeUserCardStatus, issueCardToUser,
} from '@Requests';
import { ICardUser } from 'data/models/card.model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { normalizeCurrency } from '@Helpers';
import { IPaginationTable } from '@Types';
import IssueCardPage from './IssueCardPage';

const LIMITLESS_CARD = 3;

const IssueCard = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const { id } = useParams();

	const user = useRequest(([params]) => getUserByPhone(params));
	const card = useRequest(([params, signal]) => getCardById(params, signal));
	const cardUsers = useRequest(([param1, param2, signal]) => getCardUsers(param1, param2, signal));
	const changeStatus = useRequest(([param1, param2]) => changeUserCardStatus(param1, param2));

	const [phone, setPhone] = useState('');
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const [issueCardFields, setIssueCardFields] = useState({ externalId: '', limit: '' });
	const [issueFieldError, setIssueFieldError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTitle(`${t('card')} - ${t('issue')}`);
	}, [setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const listDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			cardUsers.execute(id, listDataControl, abortCtrl.signal);
		};
		getData();
		return () => abortCtrl.abort();
	}, [pagination.page, pagination.size, search]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			card.execute(id, abortCtrl.signal);
		};
		getData();
		return () => abortCtrl.abort();
	}, [id]);

	useEffectCustom(() => {
		const changeStatusLocally = () => {
			const { data } = changeStatus;
			if (data) {
				cardUsers.setData((prev) => {
					const mPrev = prev;
					const index = mPrev?.data.findIndex((el) => el.id === data?.id);
					if (index !== undefined && mPrev) {
						mPrev.data[index] = data;
					}
					return mPrev;
				});
			}
		};
		changeStatusLocally();
	}, [changeStatus.data]);

	const getUser = () => {
		user.execute(phone);
	};

	const changeCardStatus = (cardUser: ICardUser) => {
		switch (cardUser.status.id) {
		case CARD_STATUSES.ACTIVATED:
			changeStatus.execute(cardUser.id, CARD_STATUSES.BLOCKED_BY_COMPANY);
			break;
		case CARD_STATUSES.BLOCKED_BY_COMPANY:
			changeStatus.execute(cardUser.id, CARD_STATUSES.ACTIVATED);
			break;
		default:
			break;
		}
	};

	const handleListItemAction = (action: string, cardUser: ICardUser) => {
		switch (action) {
		case t('changeStatus'):
			changeCardStatus(cardUser);
			break;
		default:
			break;
		}
	};

	const handleChange = (
		key: string,
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setIssueCardFields({ ...issueCardFields, [key]: event.target.value });
		setIssueFieldError('');
	};

	const issueCard = () => {
		const cardIssueReq = {
			cardId: id,
			userId: user.data?.id,
			externalId: issueCardFields.externalId,
			cardLimt: issueCardFields.limit,
			cardTypeId: card.data?.cardCategory.id,
		};
		const value = parseFloat(normalizeCurrency(issueCardFields.limit || '0'));
		/** Check if limit is not empty */
		if (card.data?.cardCategory.id !== LIMITLESS_CARD && value < 1) {
			return setIssueFieldError(t('emptyField'));
		}
		setLoading(true);
		issueCardToUser(cardIssueReq)
			.then((res) => {
				addToast({ message: res.message, error: false });
				cardUsers.setData((prev) => {
					const mPrev = prev;
					if (mPrev) {
						mPrev.data.push(res.data);
					}
					return mPrev;
				});
				user.setData(undefined);
				setPhone('');
				setIssueCardFields({ externalId: '', limit: '' });
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => setLoading(false));
		return null;
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<IssueCardPage
			phone={phone}
			issueFieldError={issueFieldError}
			setPhone={setPhone}
			onSearchPress={getUser}
			user={user.data}
			error={user.error ? t('userNotFound') : ''}
			card={card.data}
			cardUsers={cardUsers.data}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
			listItemAction={handleListItemAction}
			onChange={handleChange}
			onIssueClick={issueCard}
			issueCardLoading={loading}
		/>
	);
};

export default IssueCard;
