import { IListDataControl, ApiResponse, IGender } from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

export const getGenders = async (props: IListDataControl): Promise<ApiResponse<IGender[]>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.GET_GENDER.format(page, size, search),
	});
	const { data } = resp;
	data.statusCode = resp?.status;
	return data;
};
