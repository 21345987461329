/* eslint-disable no-console */
import React, { ErrorInfo, ReactNode } from 'react';
import { ErrorFallback } from '@Pages';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
		console.log(error, errorInfo);
	}

	render() {
		const { hasError } = this.state;
		if (hasError) {
			// You can render any custom fallback UI
			return <ErrorFallback />;
		}
		// eslint-disable-next-line react/destructuring-assignment
		return this.props.children;
	}
}
