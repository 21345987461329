import { useRequest } from '@Api';
import {
	addPartner,
	getPartnerById,
	editPartner,
	getChainStores,
	getCitiesByStateId,
	getCountries,
	getStatesByCountryId,
} from '@Requests';
import { useFeedback, useTitleBar } from '@Providers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
	IOrganizationReq, ICities, IStates, ICountry, IChainStore,
} from '@Types';
import { FormikErrors, FormikHelpers } from 'formik';
import {
	phoneString, postCodeString, replaceComma, replaceDecimal, taxIdString,
} from '@Masks';
import { AppRoutes } from '@Routes';
import { useEffectCustom } from '@Hooks';
import { DROPDOWN_ACTION } from '@Constants';
import CreatePartnerPage from './CreatePartnerPage';

const CreatePartner = () => {
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const { id } = useParams();
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();

	const [initialValues, setInitialValues] = useState<IOrganizationReq>({
		taxId: '',
		phone: '',
		email: '',
		address: '',
		addressComplement: '',
		postalCode: '',
		neighborhood: '',
		latitude: '',
		longitude: '',
		fantasyName: '',
		officialName: '',
		alternativeName: '',
		cityId: {} as ICities,
		stateId: {} as IStates,
		countryId: {} as ICountry,
		establishmentNumber: 0,
		chainStoreId: {} as IChainStore,
	});

	const [countries, setCountries] = useState<ICountry[]>([]);
	const [states, setStates] = useState<IStates[]>([]);
	const [cities, setCities] = useState<ICities[]>([]);
	const [selectedPlaces] = useState({
		country: 1,
		state: 1,
	});
	const [isEdit, setEdit] = useState(false);

	const chainStores = useRequest(([params]) => getChainStores(params));

	const getStates = async (countryId: number) => {
		const mStates = await getStatesByCountryId(countryId);
		return mStates;
	};

	const getCities = async (stateId: number) => {
		const mCities = await getCitiesByStateId(stateId);
		return mCities;
	};

	const getDropdowns = async () => {
		const mCountries = await getCountries();
		const mStates = await getStates(selectedPlaces.country);
		const mCities = await getCities(selectedPlaces.state);
		setCountries(mCountries.data);
		setStates(mStates.data);
		setCities(mCities.data);
		setInitialValues({
			...initialValues,
			cityId: mCities.data[0],
			countryId: mCountries.data[0],
			stateId: mStates.data[0],
		});
	};

	const getPartner = async () => {
		setGlobalLoading(true);
		getPartnerById(id ?? '').then(async ({ data }) => {
			const mCountries = await getCountries();
			const mStates = await getStates(data.country.id);
			const mCities = await getCities(data.state.id);
			setCountries(mCountries.data);
			setStates(mStates.data);
			setCities(mCities.data);
			setInitialValues({
				taxId: taxIdString(data.taxId),
				phone: phoneString(data.phone) || '',
				email: data.email,
				address: data.address,
				addressComplement: data.addressComplement,
				postalCode: postCodeString(data.postalCode),
				neighborhood: data.neighborhood,
				latitude: replaceDecimal(data.latitude),
				longitude: replaceDecimal(data.longitude),
				fantasyName: data.fantasyName,
				alternativeName: data.fantasyName,
				officialName: data.officialName,
				cityId: data.city,
				stateId: data.state,
				countryId: data.country,
				establishmentNumber: data.establishmentNumber,
				chainStoreId: data.chainStore,
			});
		})
			.catch((err) => {
				addToast({ message: err.response.data.message, error: true });
			})
			.finally(() => setGlobalLoading(false));
	};

	useEffectCustom(() => {
		const getData = () => {
			const listOptions = {
				page: 1,
				size: 1000,
				search: '',
			};
			chainStores.execute(listOptions);
			if (id) {
				setTitle(`${t('partnerDetail')}`);
				getPartner();
			} else {
				setEdit(true);
				getDropdowns();
				setTitle(`${t('new')} ${t('partner')}`);
			}
		};
		getData();
	}, [id]);

	const validation = (partnerValues: IOrganizationReq) => {
		const errors = {} as FormikErrors<IOrganizationReq>;
		const lat = replaceComma(partnerValues.latitude);
		const long = replaceComma(partnerValues.longitude);
		if (!partnerValues.officialName) {
			errors.officialName = t('emptyField');
		}
		if (!partnerValues.fantasyName) {
			errors.fantasyName = t('emptyField');
		}
		if (!partnerValues.taxId) {
			errors.taxId = t('emptyField');
		}
		if (typeof partnerValues.establishmentNumber !== 'number') {
			errors.establishmentNumber = t('emptyField');
		}
		if (!partnerValues.email) {
			errors.email = t('emptyField');
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(partnerValues.email)) {
			errors.email = t('invalidEmail');
		}
		if (!partnerValues.phone) {
			errors.phone = t('emptyField');
		}
		if (!partnerValues.address) {
			errors.address = t('emptyField');
		}
		if (!partnerValues.postalCode) {
			errors.postalCode = t('emptyField');
		}
		if (!partnerValues.latitude) {
			errors.latitude = t('emptyField');
		}
		if (partnerValues.latitude.length < 6) {
			errors.latitude = t('lengthError');
		}
		if (partnerValues.longitude.length < 6) {
			errors.longitude = t('lengthError');
		}
		if (lat > 90) {
			errors.latitude = t('coordError').format(90);
		}
		if (!partnerValues.longitude) {
			errors.longitude = t('emptyField');
		}
		if (long > 180) {
			errors.longitude = t('coordError').format(180);
		}
		if (!partnerValues.countryId) {
			errors.countryId = t('emptyField');
		}
		if (!partnerValues.alternativeName) {
			errors.alternativeName = t('emptyField');
		}
		return errors;
	};

	const getDropdownById = async (action: string, dropdownId: number) => {
		switch (action) {
		case DROPDOWN_ACTION.COUNTRY:
		{
			const mStates = await getStates(dropdownId);
			setStates(mStates.data);
			if (dropdownId !== 1) {
				setCities([]);
			}
			break;
		}
		case DROPDOWN_ACTION.STATE:
		{
			const mCities = await getCities(dropdownId);
			setCities(mCities.data);
			break;
		}
		default:
			break;
		}
	};

	const submit = (
		partnerFormValues: IOrganizationReq,
		formikActions: FormikHelpers<IOrganizationReq>,
	) => {
		if (id) {
			editPartner(id, partnerFormValues)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.PARTNERS);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => formikActions.setSubmitting(false));
		} else {
			addPartner(partnerFormValues)
				.then((res) => {
					navigate(AppRoutes.PARTNERS);
					addToast({ message: res.message, error: false });
				})
				.catch(({ response }) => {
					addDialog({
						title: response.data.title,
						message: response.data.message,
						error: true,
					});
				})
				.finally(() => formikActions.setSubmitting(false));
		}
	};

	const handleEditClick = () => {
		setTitle(`${t('edit')} ${t('partner')}`);
		setEdit(true);
	};

	const handleBackClick = () => {
		navigate(AppRoutes.PARTNERS);
	};

	return (
		<CreatePartnerPage
			partner={initialValues}
			countries={countries}
			states={states}
			cities={cities}
			chainStores={chainStores.data?.data}
			validate={validation}
			requestById={getDropdownById}
			submit={submit}
			isEdit={isEdit}
			handleEditClick={handleEditClick}
			handleBackClick={handleBackClick}
		/>
	);
};
export default CreatePartner;
