import { Checkbox, DefaultSelect, TextField } from '@Atoms';
import { FIELD_TYPES } from '@Constants';
import { acceptNumbers, carRegisteration } from '@Masks';
import { SelectChangeEvent } from '@mui/material';
import { IField } from '@Types';
import React, { useEffect, useState } from 'react';

interface FormElementProps {
	field: IField;
	answers: IField[];
	onChange: (field: IField, event:
		React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> |
		SelectChangeEvent<string>) => void
}

const FormElement: React.FC<FormElementProps> = (props) => {
	const { field, answers, onChange } = props;
	const {
		type, id, label, placeholder, options, required,
	} = field;

	const [value, setValue] = useState(answers.find((el) => id === el.id));

	useEffect(() => {
		setValue(answers.find((el) => id === el.id));
	}, [answers, id, onChange]);

	switch (type.id) {
	case FIELD_TYPES.TEXT:
		return (
			<TextField
				id={id.toString()}
				label={label}
				placeholder={placeholder}
				inputProps={{ maxLength: field.maxLength }}
				value={value?.value}
				required={required}
				onChange={(event) => {
					let mEvent = event;
					if (field.id === 4) {
						mEvent = carRegisteration(mEvent);
					}
					onChange(field, mEvent);
				}}
			/>
		);

	case FIELD_TYPES.ALL:
		return (
			<TextField
				id={id.toString()}
				value={value?.value}
				required={required}
				label={label}
				inputProps={{ maxLength: field.maxLength }}
				placeholder={placeholder}
				onChange={(event) => { onChange(field, acceptNumbers(event)); }}
			/>
		);

	case FIELD_TYPES.SELECT:
		return (
			<DefaultSelect
				id={id.toString()}
				required={required}
				value={value?.value}
				onChange={(event) => {
					onChange(field, event);
				}}
				options={options || []}
				label={label}
				renderItem={(item) => ({ optionLabel: item, optionValue: item })}
			/>
		);

	case FIELD_TYPES.CHECK_BOX:
		return (
			<Checkbox
				label={label}
				checked={value?.value === '1'}
				onChange={(event) => {
					const mEvent = { ...event };
					mEvent.target.value = value?.value === '1' ? '0' : '1';
					onChange(field, mEvent);
				}}
			/>
		);

	default:
		return <div />;
	}
};

export default FormElement;
