import {
	DateLabel, DatePicker, DefaultButton,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Grid, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, TableColumn } from '@Organisms';
import { Print, Summarize } from '@mui/icons-material';
import { printReport } from '@Print';
import { IOrderReport } from '@Types';
import { formatCurrency } from '@Helpers';

type DateRange = {
	startDate: Date;
	endDate: Date
}

interface OrderReportProps {
	orders?: IOrderReport[];
	values: DateRange;
	onChange: (key: string, value: Date) => void;
	filterOrders: () => void;
	loading: boolean;
	exportExcel: () => void
}

const OrderReportPage: FC<OrderReportProps> = (props) => {
	const {
		orders, values, onChange, filterOrders, loading, exportExcel,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const printRef = React.useRef<HTMLDivElement>(null);

	const printDocument = () => {
		printReport(printRef);
	};

	return (
		<div>
			<Grid container spacing={2} sx={{ padding: theme.spacing(3) }}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('startDate')}
									maxDate={new Date()}
									value={values.startDate}
									disabled={loading}
									onChange={(value) => onChange('startDate', value)}
								/>

							</Grid>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('endDate')}
									minDate={values.startDate}
									value={values.endDate}
									disabled={loading}
									onChange={(value) => onChange('endDate', value)}
								/>

							</Grid>
							<Grid item xs={12} md={3}>
								<DefaultButton
									loading={loading}
									title={t('filter')}
									onClick={filterOrders}
								/>

							</Grid>
						</Grid>
					</PaperContainer>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}
				sx={{
					pl: theme.spacing(3),
					pr: theme.spacing(3),
					pb: theme.spacing(3),
				}}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DefaultButton
							title={t('print')}
							startIcon={<Print />}
							onClick={printDocument}
						/>
						<DefaultButton
							title={t('export')}
							sx={{ ml: 2, textTransform: 'none' }}
							startIcon={<Summarize />}
							onClick={exportExcel}
						/>
						<div ref={printRef}>
							<DataTable data={orders || []} search={false}>
								<TableColumn headerText={t('orderNumber')} render={(item) => item.orderId} />
								<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item.registration} />} />
								<TableColumn headerText={t('location')} render={(item) => item.costCenter?.city} />
								<TableColumn headerText={t('seller')} render={(item) => item.sellerName} />
								<TableColumn headerText={t('orderValue')} render={(item) => formatCurrency(item?.orderAmount)} />
								<TableColumn headerText={`${t('coupon')} / ${t('transaction')}`} render={(item) => item.description} />
								<TableColumn headerText={t('balence')} render={(item) => formatCurrency(item.amount)} />
								<TableColumn headerText={t('client')} render={(item) => item.customerName} />
							</DataTable>
						</div>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default OrderReportPage;
