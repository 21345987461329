import React, { FC, ReactNode } from 'react';

export interface RowProps {
   children: ReactNode;
   justify: 'center' | 'space-between' | 'space-around' | 'flex-start' | 'flex-end';
}

const Row: FC<RowProps> = ({ children, justify }) => (
	<div style={{
		width: '100%', display: 'flex', flexDirection: 'row', justifyContent: justify, alignItems: 'center',
	}}
	>
		{children}
	</div>
);

export default Row;
