import {
	ApiResponse, ICostCenter, ICostCenterReq, IListDataControl, IPagination, IUser,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getCostCenters = async (organizationId: number, listDataControl?: IListDataControl):
   Promise<ApiResponse<IPagination<ICostCenter[]>>> => {
	let paginationString = '';
	if (listDataControl) {
		paginationString = `&Page=${listDataControl.page}&Size=${listDataControl.size}&Search=${listDataControl.search}`;
	}
	const resp = await reqGet({
		url: Endpoint.COST_CENTERS.format(organizationId, paginationString),
	});
	const data = resp.data as ApiResponse<IPagination<ICostCenter[]>>;
	data.statusCode = resp.status;
	return data;
};

export const getCostCenterById = async (costCenterId?: string):
	Promise<ApiResponse<ICostCenter>> => {
	const resp = await reqGet({
		url: Endpoint.COST_CENTER_BY_ID.format(costCenterId),
	});
	const data = resp.data as ApiResponse<ICostCenter>;
	data.statusCode = resp.status;
	return data;
};

export const changeCostCenterStatus = async (costCenterId: number):
	Promise<ApiResponse<ICostCenter>> => {
	const resp = await reqPost({
		url: Endpoint.COST_CENTER_STATUS.format(costCenterId),
	});
	const data = resp.data as ApiResponse<ICostCenter>;
	data.statusCode = resp.status;
	return data;
};

export const createCostCenter = async (costCenter: ICostCenterReq):
	Promise<ApiResponse<ICostCenter>> => {
	const costCenterReq = { ...costCenter, organizationId: costCenter.organizationId };
	const resp = await reqPost({
		url: Endpoint.CREATE_COST_CENTER,
		data: { ...costCenterReq },
	});
	const data = resp.data as ApiResponse<ICostCenter>;
	data.statusCode = resp.status;
	return data;
};

export const editCostCenter = async (costCenterId: string, costCenter: ICostCenterReq):
	Promise<ApiResponse<ICostCenter>> => {
	const costCenterReq = { ...costCenter, organizationId: costCenter.organizationId, costCenterId };
	const resp = await reqPost({
		url: Endpoint.COST_CENTER_BY_ID.format(costCenterId),
		data: { ...costCenterReq },
	});
	const data = resp.data as ApiResponse<ICostCenter>;
	data.statusCode = resp.status;
	return data;
};

export const addPartnerUser = async (email: string, costCenterId?: string):
	Promise<ApiResponse<IUser>> => {
	const resp = await reqPost({
		url: Endpoint.ADD_PARTNER_USER.format(email),
		data: { costCenterId },
	});
	const data = resp.data as ApiResponse<IUser>;
	data.statusCode = resp.status;
	return data;
};

export const removePartnerUser = async (email: string, costCenterId: string):
	Promise<ApiResponse<IUser>> => {
	const resp = await reqPost({
		url: Endpoint.REMOVE_PARTNER_USER.format(email),
		data: { costCenterId },
	});
	const data = resp.data as ApiResponse<IUser>;
	data.statusCode = resp.status;
	return data;
};
