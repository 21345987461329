import { Row, Space } from '@Atoms';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	Typography,
	useTheme,
} from '@mui/material';
import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { IForm } from '@Types';
import { useTranslation } from 'react-i18next';

interface UserVehicleInterface {
   answers?: IForm[];
   handleEdit: (index: number) => void;
   handleDelete: (hash: string) => void;
}

const UserVehiclesPage = (props: UserVehicleInterface) => {
	const { answers, handleEdit, handleDelete } = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');

	return (
		<Grid container sx={{ padding: theme.spacing(3) }} spacing={2}>
			<Space height={1} />
			{answers?.length === 0 && (
				<Box sx={{
					bgcolor: 'secondary.light',
					width: '100%',
					p: '1rem',
					borderRadius: '7px',
				}}
				>
					{t('noRegisteredVehicles')}
					.
				</Box>
			)}
			{ answers?.map((el, index) => (
				<Grid item xs={12} md={4} key={`user-cars-${index.toString()}`}>
					<Card elevation={0} sx={{ border: `1px solid ${theme.palette.cardBorder.main}` }}>
						<CardHeader
							sx={{ bgcolor: theme.palette.divider }}
							subheader={`${t('vehicle')} ${index + 1}`}
							action={(
								<>
									<IconButton aria-label="edit" onClick={() => handleEdit(index)}>
										<Edit />
									</IconButton>
									<IconButton aria-label="delete" sx={{ ml: 1 }} onClick={() => handleDelete(el.hash)}>
										<Delete color="error" />
									</IconButton>
								</>
							)}
						/>
						<Divider />
						<CardContent>
							{el.fields.map((field, fieldIndex) => (
								<Row justify="flex-start" key={`field-${fieldIndex.toString()}`}>
									<Typography variant="body2" fontWeight={600}>
										{field.label}
										:
									</Typography>
									<Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
										{(field.type.id === 5) && (field.value === '1' ? t('yes') : t('no'))}
										{field.type.id !== 5 && field.value}
									</Typography>
								</Row>
							))}
						</CardContent>
					</Card>
				</Grid>
			))}
		</Grid>
	);
};

export default UserVehiclesPage;
