import {
	DefaultButton, Description, Image, Space, TextField, Title,
} from '@Atoms';
import {
	Box, Container, Stack, Typography,
} from '@mui/material';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { logo } from '@Images';
import { UserData } from '.';

export type InitialValue = {
	password: string;
	confirmPassword: string
}

interface ResetPasswordProps{
	initialValues: InitialValue;
	submit: (value: InitialValue, helpers: FormikHelpers<InitialValue>) => void;
	validate: (values: InitialValue) => FormikErrors<InitialValue>;
	user?: UserData;
	expired?: boolean;
	success?: boolean
}

const ResetPasswordPage: FC<ResetPasswordProps> = (props) => {
	const {
		initialValues, submit, validate, user, expired, success,
	} = props;
	const { t } = useTranslation('translations');
	return (
		<Container>
			<Container maxWidth="xs">
				<Space height={5} />

				<Image src={logo} height="100%" width="100%" alt="AJ Rent Logo" />
				{success && (
					/** If User have changed password just show this message */
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Title text={t('sucess')} textAlign="center" />
						<Typography variant="caption" sx={{ textAlign: 'center', alignSelf: 'center' }}>{t('passwordChangeSuccess')}</Typography>
					</Box>
				)}
				{!expired ? (
					/** If User have changed Password donot show input */
					!success && (
						<>
							<Stack direction="column" alignItems="center">
								<Space height={2} />
								<Title text={user?.name ?? ''} />
								<Space height={1} />
								<Description text={t('fillPasswordFields')} />
								<Space height={2} />
							</Stack>
							<Formik
								initialValues={initialValues}
								validateOnMount={false}
								validateOnBlur={false}
								validateOnChange={false}
								onSubmit={submit}
								validate={validate}
							>
								{({
									handleChange, values, errors, isSubmitting,
								}) => (
									<Form autoComplete="off">
										<TextField
											name="password"
											id="password"
											type="password"
											label={t('password')}
											placeholder={t('password')}
											aria-label={t('password')}
											value={values.password}
											error={!!errors.password}
											helperText={errors.password}
											onChange={handleChange}
											disabled={isSubmitting}
										/>

										<Space height={2} />

										<TextField
											name="confirmPassword"
											id="confirmPassword"
											aria-label={t('confirmPassword')}
											type="password"
											label={t('confirmPassword')}
											placeholder={t('confirmPassword')}
											value={values.confirmPassword}
											error={!!errors.confirmPassword}
											helperText={errors.confirmPassword}
											onChange={handleChange}
											disabled={isSubmitting}
										/>

										<Space height={2} />

										<DefaultButton
											id="submit"
											name="submit"
											data-testid="submit"
											title={t('update')}
											aria-label={t('update')}
											type="submit"
											loading={isSubmitting}
											fullWidth
										/>
									</Form>
								)}
							</Formik>
						</>
					)

				) : (
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Title text={t('expiredLink')} textAlign="center" />
						<Typography variant="caption" sx={{ textAlign: 'center', alignSelf: 'center' }}>{t('expiredLinkDescription')}</Typography>
					</Box>
				)}
				<Space height={3} />

				<Box display="flex" justifyContent="center">
					<Typography variant="caption">
						{process.env.REACT_APP_NAME}
						{' '}
						-
						{' '}
						{t('copyright')}
						{' '}
						©
						{' '}
						{new Date().getFullYear()}
					</Typography>
				</Box>
			</Container>
		</Container>
	);
};

export default ResetPasswordPage;
