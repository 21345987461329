import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import {
	addTag, editTag, getTagById,
} from '@Requests';
import { AppRoutes } from '@Routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateTagPage from './CreateTagPage';

const CreateTag = () => {
	const { addToast } = useFeedback();
	const { setTitle } = useTitleBar();
	const { id } = useParams();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const [tag, setTag] = useState({ name: '', description: '' });
	const [errors, setErrors] = useState({ name: '', description: '' });
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			setTitle(`${t('edit')} tag`);
		} else {
			setTitle(`${t('new')} tag`);
		}
	}, [id, setTitle, t]);

	const getTag = () => {
		getTagById(id ?? '')
			.then(({ data }) => setTag({
				name: data.name,
				description: data.description,
			}));
	};

	useEffectCustom(() => {
		if (id) {
			getTag();
		}
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setTag({ ...tag, [event.target.name]: event.target.value });
		setErrors({ ...errors, [event.target.name]: '' });
	};

	const handleSubmit = () => {
		if (!tag.name) {
			return setErrors({ ...errors, name: t('emptyField') });
		}
		if (!tag.description) {
			return setErrors({ ...errors, description: t('emptyField') });
		}
		setLoading(true);
		if (id) {
			editTag(id, tag)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.TAGS);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => { setLoading(false); });
		} else {
			addTag(tag)
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(AppRoutes.TAGS);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => { setLoading(false); });
		}

		return null;
	};

	return (
		<CreateTagPage
			onChange={handleChange}
			tag={tag}
			errors={errors}
			handleSubmit={handleSubmit}
			loading={loading}
		/>
	);
};

export default CreateTag;
