import { Box, useTheme } from '@mui/material';
import React, { ReactNode, FC } from 'react';

export interface InfoProps {
   children: ReactNode;
   color?: string;
}

const InfoBox: FC<InfoProps> = ({ color, children }) => {
	const theme = useTheme();
	return <Box sx={{ borderRadius: '5px', backgroundColor: color ?? theme.palette.success.light, padding: theme.spacing(1) }}>{children}</Box>;
};

export default InfoBox;
