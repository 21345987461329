import {
	IListDataControl, ApiResponse, IPagination, IReferral, IReferralReportReq, IReferralReport,
} from '@Types';
import { encodeDate, normalizeCurrency } from '@Helpers';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getRefferals = async (props: IListDataControl, referralStatusId: number):
    Promise<ApiResponse<IPagination<IReferral[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.GET_REFERRALS.format(page, size, search, referralStatusId),
	});
	const data = resp.data as ApiResponse<IPagination<IReferral[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getReferralById = async (couponId: string):
    Promise<ApiResponse<IReferral>> => {
	const resp = await reqGet({
		url: Endpoint.EDIT_REFERRAL.format(couponId),
	});
	const data = resp.data as ApiResponse<IReferral>;
	data.statusCode = resp?.status;
	return data;
};

export const getReferralReport = async (params: IReferralReportReq):
Promise<ApiResponse<IReferralReport[]>> => {
	let orgQuery = '';
	params.organization.forEach((el) => {
		orgQuery = `${orgQuery}&orgId=${el.id}`;
	});
	const resp = await reqGet({
		url: Endpoint.GET_REFERRAL_REPORT.format(
			encodeDate(params.startDate),
			encodeDate(params.endDate),
			orgQuery,
		),
	});
	const data = resp.data as ApiResponse<IReferralReport[]>;
	data.statusCode = resp.status;
	return data;
};

export const addSellerToReferral = async (referralId: string, sellerId: number | string):
	Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.ADD_REFERRAL_SELLER.format(referralId),
		data: { sellerId },
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};

export const saveServiceValueForReferrral = async (referralId: string, mCost: string):
	Promise<ApiResponse<IReferral>> => {
	const cost = normalizeCurrency(mCost);
	const resp = await reqPost({
		url: Endpoint.EDIT_REFERRAL.format(referralId),
		data: { cost },
	});
	const data = resp.data as ApiResponse<IReferral>;
	data.statusCode = resp.status;
	return data;
};
