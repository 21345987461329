import { encodeDate } from '@Helpers';
import {
	IListDataControl, ApiResponse, IOrder, IPagination, IOrderReq, IOrderReport,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getOrders = async (props: IListDataControl):
    Promise<ApiResponse<IPagination<IOrder[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({
		url: Endpoint.LIST_ORDERS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<IOrder[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getOrderReport = async (startDate: Date, endDate: Date):
	Promise<ApiResponse<IOrderReport[]>> => {
	const resp = await reqGet({
		url: Endpoint.ORDER_REPORT.format(encodeDate(startDate), encodeDate(endDate)),
	});
	const data = resp.data as ApiResponse<IOrderReport[]>;
	data.statusCode = resp?.data;
	return data;
};

export const cancelOrder = async (orderId: string): Promise<ApiResponse<IOrder>> => {
	const resp = await reqPost({
		url: Endpoint.CANCEL_ORDER.format(orderId),
	});
	const data = resp.data as ApiResponse<IOrder>;
	data.statusCode = resp?.status;
	return data;
};

export const getOrderById = async (orderId?: string): Promise<ApiResponse<IOrder>> => {
	const resp = await reqGet({
		url: Endpoint.GET_ORDER_BY_ID.format(orderId),
	});
	const data = resp.data as ApiResponse<IOrder>;
	data.statusCode = resp?.status;
	return data;
};

export const fixOrder = async (orderId: string, orderReq: IOrderReq):
	Promise<ApiResponse<IOrder>> => {
	const resp = await reqPost({
		url: Endpoint.CORRECT_ORDER.format(orderId),
		data: { ...orderReq },
	});
	const data = resp.data as ApiResponse<IOrder>;
	data.statusCode = resp?.status;
	return data;
};
