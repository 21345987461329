import {
	DefaultButton, Row, Space, Title, TextField, TableItem, CardImage, Dropdown, StatusLabel,
} from '@Atoms';
import { FontSizes } from '@Constants';
import { profileImage } from '@Images';
import { phone as phoneMask, currency } from '@Masks';
import { PaperContainer, UserTableItem } from '@Molecules';
import {
	Avatar,
	Box,
	Card as MuiCard,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { ICard, IPaginationTable, IUser } from '@Types';
import { ICardUser, CardUserResponse } from 'data/models/card.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '@Routes';
import { formatCurrency } from '@Helpers';

interface IssueCardProps {
	phone: string;
	setPhone: (value: string)=>void;
	onSearchPress: () => void;
	user?: IUser;
	error?: string;
	card?: ICard;
	cardUsers?: CardUserResponse;
	setSearch: (query: string) => void;
	setPagination: (pagination: IPaginationTable) => void;
	listItemAction: (action: string, cardUser: ICardUser) => void;
	onChange: (key: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onIssueClick: () => void;
	issueCardLoading: boolean;
	issueFieldError: string
}

const LIMITLESS_CARD = 3;

const IssueCardPage: React.FC<IssueCardProps> = (props) => {
	const theme = useTheme();
	const {
		phone,
		setPhone,
		onSearchPress,
		user,
		error,
		card,
		setSearch,
		setPagination,
		cardUsers,
		listItemAction,
		onChange,
		onIssueClick,
		issueCardLoading,
		issueFieldError,
	} = props;

	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	return (
		<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
			<Grid item xs={12} md={12}>
				<DefaultButton title={t('back')} onClick={() => navigate(AppRoutes.CARDS)} />
			</Grid>
			<Grid item xs={12} md={12}>
				<PaperContainer>
					<Grid container spacing={2}>
						<Grid item lg={4} xl={4} md={12} sm={12} xs={12}>
							<Stack direction="row">
								<CardImage src={card?.image} height={114} width={200} alt="card" style={{ borderRadius: 5 }} title={card?.name} description={card?.description} />
								<Box sx={{ p: 2 }}>
									<Row justify="flex-start">
										<Typography variant="subtitle1" fontSize={FontSizes.MD} fontWeight="bold">
											{t('issued')}
											:
										</Typography>
										<Typography variant="subtitle1" sx={{ ml: 1 }} fontSize={FontSizes.MD}>
											{cardUsers?.issued}
										</Typography>
									</Row>
									<Row justify="flex-start">
										<Typography variant="subtitle1" fontSize={FontSizes.MD} fontWeight="bold">Blocked: </Typography>
										<Typography variant="subtitle1" fontSize={FontSizes.MD} sx={{ ml: 1 }}>
											{cardUsers?.blocked}
										</Typography>
									</Row>
								</Box>
							</Stack>
							{(card?.type.id !== 4) && (
								<>
									<Space height={3} />
									<Title text={t('addPhone')} fontWeight="bold" />
									<Space height={1} />
									<Typography variant="caption">
										{t('cardIssuePhone')}
									</Typography>
									<Space height={1} />
									<TextField
										value={phone}
										onChange={(e) => setPhone(phoneMask(e).target.value)}
										error={!!error}
										helperText={error}
									/>
									<Space height={3} />
									<DefaultButton title={t('search')} onClick={onSearchPress} />
								</>
							)}

							<Space height={4} />
							{user && !error && (
								<MuiCard sx={{ maxWidth: '100%', border: `1px ridge ${theme.palette.divider}` }} elevation={0}>
									<CardHeader
										avatar={(
											<Avatar src={profileImage} sx={{ bgcolor: 'primary.main' }} aria-label="recipe">
												{user?.name && user.name[0]}
											</Avatar>
										)}
										title={user?.name}
										subheader={(<TableItem title={`${t('nif')}: ${user.taxId}`} subTitle={`${t('email')}: ${user?.email || ''}`} />)}
									/>
									<CardContent>
										<TextField label={t('externalId')} onChange={(event) => onChange('externalId', event)} />
										<Space height={2} />
										{card && card?.cardCategory.id !== LIMITLESS_CARD && (
											<TextField
												label={t('limit')}
												error={!!issueFieldError}
												helperText={issueFieldError}
												onChange={(event) => onChange('limit', currency(event))}
											/>
										)}
									</CardContent>
									<CardActions>
										<Stack width="100%" direction="row" justifyContent="center">
											<DefaultButton title={t('issue')} onClick={onIssueClick} loading={issueCardLoading} />
										</Stack>
									</CardActions>
								</MuiCard>
							)}
						</Grid>
						<Grid item lg={8} xl={8} md={12} sm={12} xs={12}>
							<Title variant="h6" fontWeight="bold" text={`${t('cards')} ${t('issued')}`} />
							<Space height={2} />
							<Divider />
							<Space height={2} />
							<DataTable
								data={cardUsers?.data || []}
								total={cardUsers?.total}
								pagination
								onSearching={setSearch}
								onChange={(page, size) => setPagination({ page, size })}
							>
								<TableColumn headerText="ID" render={(item) => item.id} />
								<TableColumn headerText={t('externalId')} render={(item) => item.external_id} />
								<TableColumn headerText={t('limit')} render={(item) => formatCurrency(item.limit || 0)} />
								<TableColumn headerText={t('user')} render={(item) => <UserTableItem user={{ ...item.user, phone: item.phone, taxId: item.taxid }} />} />
								<TableColumn headerText={t('name')} render={(item) => item.name} />
								<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
								<TableColumn
									align="right"
									render={(userCardItem) => (
										<Dropdown
											data={[t('changeStatus')]}
											label={t('action')}
											onChange={(action) => listItemAction(action, userCardItem)}
											renderItem={(item) => ({ optionLabel: item, optionValue: item })}
										/>
									)}
								/>

							</DataTable>
						</Grid>
					</Grid>
				</PaperContainer>
			</Grid>
		</Grid>
	);
};

export default IssueCardPage;
