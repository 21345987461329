import { Description } from '@Atoms';
import { FontSizes } from '@Constants';
import { Avatar, Stack } from '@mui/material';
import { IUser } from '@Types';
import React from 'react';

interface UserTableItemProps{
	user: IUser
}

const UserTableItem: React.FC<UserTableItemProps> = (props) => {
	const { user } = props;
	return (
		<Stack direction="row">
			<Avatar src={user.profileImage} />
			<Stack direction="column" sx={{ pl: 1 }}>
				<Description text={user.name} fontWeight="500" />
				<Stack direction="row">
					<Description fontSize={FontSizes.SM} fontWeight="500" text="Tel: " />
					<Description fontSize={FontSizes.SM} text={user.phone} />
				</Stack>
				<Stack direction="row">
					<Description fontSize={FontSizes.SM} fontWeight="500" text="NIF: " />
					<Description fontSize={FontSizes.SM} text={user.taxId} />
				</Stack>
			</Stack>
		</Stack>
	);
};

export default UserTableItem;
