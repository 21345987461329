import { Description, PhoneDailog, Title } from '@Atoms';
import { Stepper } from '@Molecules';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import {
	getCoupons, changeCouponStatus as changeStatus, getUserByPhone, sendCouponToUser,
} from '@Requests';
import { AppRoutes } from '@Routes';
import {
	ICoupon, IPagination, IListDataControl, IUser, IPaginationTable,
} from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { phone } from '@Masks';
import { FontSizes } from '@Constants';
import { useEffectCustom } from '@Hooks';
import CouponsListPage from './CouponsListPage';

const CouponList = () => {
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const [showSendModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState<number>(1);
	const [couponToSend, setCouponToSend] = useState<number>();
	const [userPhone, setUserPhone] = useState('');
	const [userPhoneError, setUserPhoneError] = useState('');
	const [userData, setUserData] = useState<IUser>();
	const [coupons, setCoupons] = useState<IPagination<ICoupon[]>>();
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => {
		setTitle(t('coupons'));
	}, [setTitle, t]);

	const changeCouponStatus = (id?: number) => {
		setGlobalLoading(true);
		changeStatus(id)
			.then((res) => {
				const index = coupons?.data?.findIndex((el) => el.id === res?.data?.id);
				if (coupons && index !== undefined) {
					const newCoupons: IPagination<ICoupon[]> = Object.create(coupons);
					newCoupons.data[index] = res.data;
					setCoupons(newCoupons);
				}
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
	};

	useEffectCustom(() => {
		const getData = () => {
			setGlobalLoading(true);
			const listControl: IListDataControl = {
				page: pagination.page,
				search,
				size: pagination.size,
			};

			getCoupons(listControl)
				.then((res) => {
					setCoupons(res.data);
				})
				.catch(({ response }) => {
					addToast({ message: response.data.message, error: true });
				})
				.finally(() => setGlobalLoading(false));
		};
		getData();
	}, [search, pagination]);

	// const sendToUser = () => {};

	const handleListAction = (action: string, coupon?: ICoupon) => {
		switch (action) {
		case t('detail'): {
			navigate(AppRoutes.EDIT_COUPON.formatMap({ id: coupon?.id }));
			break;
		}
		case t('changeStatus'): {
			changeCouponStatus(coupon?.id);
			break;
		}
		case t('defineAudience'): {
			navigate('/coupon/define-audience/{0}'.format(coupon?.id));
			break;
		}
		case t('sendToSpecificUser'): {
			setCouponToSend(coupon?.id);
			setModalData(1);
			setShowModal(true);
			break;
		}
		default: {
			navigate(AppRoutes.NEW_COUPON);
			break;
		}
		}
	};

	const handleClose = () => {
		setShowModal(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setUserPhone(event.target.value);
	};

	const searchUser = async () => {
		if (!userPhone) {
			return setUserPhoneError(t('emptyField'));
		}
		if (userPhone.length < 11) {
			return setUserPhoneError(t('phoneError'));
		}
		setUserPhoneError('');
		getUserByPhone(userPhone)
			.then((res) => {
				setUserData(res.data);
				setModalData(2);
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			});

		return null;
	};

	const sendToUser = () => {
		sendCouponToUser(userData?.id, couponToSend)
			.then((res) => addToast({ message: res.message, error: false }))
			.catch(({ response }) => addToast({ message: response.data.message, error: true }));
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<>
			{showSendModal && (
				<>
					{modalData === 1 && (
						<Dialog
							title={<Stepper steps={[t('phone'), t('confirm')]} activeStep={0} />}
							description={(
								<PhoneDailog
									phoneError={userPhoneError}
									onChange={(text) => handleChange(phone(text))}
								/>
							)}
							open={showSendModal}
							handleClose={() => null}
							primaryButtonText={t('search')}
							primaryButtonAction={searchUser}
							secondaryButtonText={t('cancel')}
							secondaryButtonAction={handleClose}
						/>
					)}
					{modalData === 2 && (
						<Dialog
							title={<Stepper steps={[t('phone'), t('confirm')]} activeStep={1} />}
							description={(
								<div>
									<Title text={t('confirm')} />
									<Description fontSize={FontSizes.LG} text={t('confirmSendCoupon')} />
									<Description fontSize={FontSizes.LG} fontWeight="bold" text={`${userData?.name}?`} />
								</div>
							)}
							open={showSendModal}
							handleClose={handleClose}
							primaryButtonText={t('yes')}
							primaryButtonAction={sendToUser}
							secondaryButtonText={t('cancel')}
							secondaryButtonAction={handleClose}
						/>
					)}
				</>
			)}
			<CouponsListPage
				data={coupons}
				onPageChange={setTablePagination}
				onSearch={setTableSearch}
				handleListAction={handleListAction}
			/>
		</>

	);
};

export default CouponList;
