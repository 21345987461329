import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getRatings } from '@Requests';
import { IRating } from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RatingsListPage from './RatingsListPage';

const RatingsList = () => {
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();

	const [ratings, setRatings] = useState<IRating[] | undefined>();

	useEffectCustom(() => {
		setTitle(t('ratings'));
		if (!ratings) {
			setGlobalLoading(true);
			getRatings()
				.then((res) => setRatings(res.data))
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => {
					setGlobalLoading(false);
				});
		}
	}, []);
	return <RatingsListPage ratings={ratings} />;
};

export default RatingsList;
