import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<Box
			sx={{
				bottom: 0,
				padding: theme.spacing(1),
				backgroundColor: theme.palette.background.paper,
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Typography color="gray" variant="caption" fontWeight="bold">
				©
				{t('copyright')}
				{' '}
				@2021
			</Typography>
			<Typography color="gray" variant="caption">
				{t('commandCenter')}
				{' '}
				{process.env.REACT_APP_NAME}
			</Typography>
		</Box>
	);
};

export default Footer;
