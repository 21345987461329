import {
	Autocomplete, DatePicker, DefaultButton, Space,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import { Print, Summarize } from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IReferralReport, IOrganization, IReferralReportReq } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { printReport } from '@Print';
import { formatCurrency } from '@Helpers';

interface ReferralReportProps {
	values: IReferralReportReq;
	companies?: IOrganization[];
	onChange: (key: string, value: Date | IOrganization[]) => void;
	referrals?: IReferralReport[];
	getReferralReport: () => void;
	exportExcel: () => void
}

const ReferralReportPage: FC<ReferralReportProps> = (props) => {
	const {
		values, companies, onChange, referrals, getReferralReport, exportExcel,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const printRef = React.useRef<HTMLDivElement>(null);

	const printDocument = () => {
		printReport(printRef);
	};

	return (
		<div>
			<Grid container spacing={2} sx={{ padding: theme.spacing(3) }}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<Autocomplete
									options={companies || []}
									multiple
									defaultValue={values.organization}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									label={t('company')}
									placeholder=""
									renderOption={(inputprops, item) => (
										<li
											{...inputprops}
											key={item.id}
										>
											{item.fantasyName}
										</li>
									)}
									getOptionLabel={(item) => item.fantasyName || ''}
									onChange={(_, item) => onChange('organization', item)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('startDate')}
									value={values.startDate}
									maxDate={new Date()}
									onChange={(value) => onChange('startDate', value)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('endDate')}
									minDate={values.startDate}
									value={values.endDate}
									onChange={(value) => onChange('endDate', value)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DefaultButton title={t('filter')} onClick={getReferralReport} />
							</Grid>
						</Grid>
					</PaperContainer>
				</Grid>
			</Grid>
			<Grid
				sx={{
					pl: theme.spacing(3),
					pr: theme.spacing(3),
					pb: theme.spacing(3),
				}}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DefaultButton
							title={t('print')}
							startIcon={<Print />}
							onClick={printDocument}
						/>
						<DefaultButton
							title={t('export')}
							sx={{ ml: 2, textTransform: 'none' }}
							startIcon={<Summarize />}
							onClick={exportExcel}
						/>
						<Space height={2} />
						<div ref={printRef}>
							<DataTable
								data={referrals || []}
								search={false}
							>
								<TableColumn headerText="ID" render={(item) => item.id} />
								<TableColumn headerText={t('referredBy')} render={(item) => item.fromUserName} />
								<TableColumn headerText={t('referral')} render={(item) => item.name} />
								<TableColumn headerText={t('phone')} render={(item) => item.phone} />
								<TableColumn headerText={t('company')} render={(item) => item.organizationName} />
								<TableColumn headerText={`${t('product')}/${t('service')}`} render={(item) => item.serviceName} />
								<TableColumn headerText={t('responsible')} render={(item) => item.sellerName} />
								<TableColumn headerText={t('serviceValue')} render={(item) => formatCurrency(item.serviceCost)} />
								<TableColumn headerText={t('commission')} render={() => ''} />
								<TableColumn headerText={t('balenceDate')} render={() => ''} />
							</DataTable>
						</div>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default ReferralReportPage;
