import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import moment from 'moment';
import { FontSizes } from '@Constants';

interface DateLabelProps {
	date: string | undefined;
	showTime?: boolean;
}

const DateLabel: FC<DateLabelProps> = (props) => {
	const { date, showTime } = props;
	return (
		<Box>
			<Typography sx={{ fontSize: FontSizes.MD }}>{date ? moment(date).format('DD/MM/YYYY') : ''}</Typography>
			{showTime && (
				<Typography sx={{ fontSize: FontSizes.SM }}>{date ? moment(date).format('HH:mm:SS') : ''}</Typography>
			)}
		</Box>
	);
};

export default DateLabel;
