import { useRequest } from '@Api';
import { useFeedback, useTitleBar } from '@Providers';
import {
	getFormsById, getUserAnswers, deleteForm, addForm,
} from '@Requests';
import React, { useEffect, useState } from 'react';
import { Dialog } from '@Organisms';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IField, IForm } from '@Types';
import { SelectChangeEvent } from '@mui/material';
import { useEffectCustom } from '@Hooks';
import EditVehicleModal from './EditVehicleModal';
import UserVehiclesPage from './UserVehiclesPage';

const UserVehicles = () => {
	const { setTitle } = useTitleBar();
	const { addToast } = useFeedback();
	const { t } = useTranslation('translations');
	const { id } = useParams<{ id: string }>();
	const form = useRequest(([params]) => getFormsById(params));

	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState({} as IForm);
	const [showConfirmDailog, setShowConfirmDailog] = useState(false);
	const [hashToDelete, setHashToDelete] = useState('');
	const [answers, setAnswers] = useState(undefined as IForm[] | undefined);
	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		setTitle(t('vehicles'));
		form.execute(1);
	}, [setTitle, t, id]);

	useEffect(() => {
		const getData = () => {
			getUserAnswers(id ?? '')
				.then((res) => {
					setAnswers(res.data);
				}).catch((err) => {
					throw err;
				});
		};
		getData();
	}, [id]);

	const addNewForm = () => {
		setLoading(true);
		addForm(id, selectedAnswer)
			.then((res) => {
				setShowEditModal(false);
				addToast({ message: res.message, error: false });
				const index = answers?.findIndex((el) => el.hash === selectedAnswer.hash);
				setAnswers((prev) => {
					const mPrev = prev;
					if (index !== undefined && mPrev) {
						mPrev[index] = selectedAnswer;
					}
					return mPrev;
				});
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setLoading(false));
	};

	const closeModal = (action?: string) => {
		switch (action) {
		case t('save'):
			addNewForm();
			break;
		default:
			setShowEditModal(false);
			break;
		}
	};

	const handleDelete = (hash: string) => {
		setShowConfirmDailog(true);
		setHashToDelete(hash);
	};

	const confirmDelete = () => {
		deleteForm(hashToDelete, id)
			.then((res) => {
				/**
				 * Delete The Form from the local state
				 */
				const indexToDelete = answers?.findIndex((el) => el.hash === hashToDelete);
				setAnswers((prev) => {
					const mPrev = prev;
					if (indexToDelete !== undefined) {
						if (mPrev !== undefined) {
							mPrev.splice(indexToDelete, 1);
						}
					}
					return mPrev;
				});
				/**
				 * Prompt a toast to the user
				 */
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }));
	};

	const cancelModal = () => {
		setShowConfirmDailog(false);
	};

	const handleEdit = (index: number) => {
		if (answers) {
		/**
		 * Make a copy of the parent to avoid changes in the original object
		 */
			setSelectedAnswer(JSON.parse(JSON.stringify({ ...answers[index] })));
		}
		setShowEditModal(true);
	};

	const handleChange = (
		field: IField,
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>,
	) => {
		/**
		 * Find the index of the answer which is edited
		 */
		const findIndex = selectedAnswer
			&& selectedAnswer.fields.findIndex((el) => el.id === field.id);
		if (findIndex !== -1 || undefined) { // If the index is already there just change the value
			setSelectedAnswer((prev) => {
				const mPrev = { ...prev };
				if (mPrev) {
					mPrev.fields[findIndex].value = event.target.value;
				}
				return mPrev;
			});
		} else if (findIndex === -1) { // if the index is not defined Add a field and update the value
			const mField = { ...field };
			mField.value = event.target.value;
			setSelectedAnswer((prev) => {
				const mPrev = { ...prev };
				if (mPrev) {
					mPrev.fields.push(mField);
				}
				return mPrev;
			});
		}
	};

	return (
		<>
			{showConfirmDailog && (
				<Dialog
					icon="error"
					title={t('sureMessage')}
					description={t('sureRemoveDescription')}
					primaryButtonAction={confirmDelete}
					primaryButtonText={t('confirmRemove')}
					secondaryButtonAction={cancelModal}
					secondaryButtonText={t('cancel')}
					open={showConfirmDailog}
					handleClose={cancelModal}
				/>
			)}
			{showEditModal && (
				<EditVehicleModal
					open={showEditModal}
					handleClose={closeModal}
					form={form.data}
					answer={selectedAnswer}
					onChange={handleChange}
					loading={loading}
				/>
			)}
			{answers && (
				<UserVehiclesPage
					answers={answers}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
				/>
			)}
		</>
	);
};

export default UserVehicles;
