import { FontSizes } from '@Constants';
import { Translate } from '@mui/icons-material';
import {
	IconButton, Menu, MenuItem, useTheme,
} from '@mui/material';
import { useLanguage, SupportedLocales } from '@Providers';
import i18next from 'i18next';
import React, { useState } from 'react';

const LANGUAGES = [
	{ code: 'enUS', name: 'English' },
	{ code: 'ptPT', name: 'Portuguese' },
] as {code: SupportedLocales, name: string}[];

const LanguageSelector = () => {
	const { changeLocale } = useLanguage();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onSelect = (option: SupportedLocales) => {
		setAnchorEl(null);
		changeLocale(option);
	};

	const selectedIndex = LANGUAGES.findIndex((el) => (
		el.code.substring(0, 2) === i18next.language.substring(0, 2)));

	return (
		<>
			<IconButton onClick={handleClick} color="primary">
				<Translate style={{ color: theme.palette.icon.main, fontSize: FontSizes.LG }} />
			</IconButton>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				// defaultValue={}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{LANGUAGES.map((el, index) => {
					const { code, name } = el;
					return (
						<MenuItem
							value={code}
							key={`dropdown-options-${name}`}
							selected={index === selectedIndex}
							onClick={() => {
								onSelect(code);
							}}
						>
							{name}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default LanguageSelector;
