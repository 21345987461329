import { PaperContainer } from '@Molecules';
import { DateLabel, Dropdown, StatusLabel } from '@Atoms';
import { Box, Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { useTranslation } from 'react-i18next';
import { IAdministrators, IPagination, IPaginationTable } from '@Types';
import React from 'react';

interface AdminPageProps {
   setSearch: (query: string) => void;
   setPagination: (pagination: IPaginationTable) => void;
   changeStatus: (id: string) => void;
   data?: IPagination<IAdministrators[]>;
}

const AdministratorPage = (props: AdminPageProps) => {
	const {
		setSearch, setPagination, changeStatus, data,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<Box>
			<Grid container sx={{ padding: theme.spacing(3) }} spacing={3}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable
							data={data?.data || []}
							onSearching={setSearch}
							pagination
							total={data?.total}
							onChange={(page, size) => setPagination({ page, size })}
						>
							<TableColumn headerText="ID" render={(item) => item.userId} />
							<TableColumn headerText={t('name')} render={(item) => item.name} />
							<TableColumn headerText={t('email')} render={(item) => item.email} />
							<TableColumn headerText={t('phone')} render={(item) => item.phone} />
							<TableColumn headerText={t('registration')} render={(item) => <DateLabel date={item.registration} />} />
							<TableColumn headerText={t('category')} render={(item) => item.category} />
							<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} />
							<TableColumn
								render={(item) => (
									<Dropdown
										data={[t('changeStatus')]}
										label={t('action')}
										renderItem={(val) => ({ optionValue: val, optionLabel: val })}
										onChange={() => {
											changeStatus(item.id);
										}}
									/>
								)}
							/>
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</Box>
	);
};

export default AdministratorPage;
