/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
import LocalStorageWrapper, { LocalStorageKeys } from '@LocalStorage';
import { refreshToken } from '@Requests';
import { IUser } from '@Types';
import React, {
	createContext, FC, useContext, useMemo, useState,
} from 'react';

interface AuthContextProps {
   user?: IUser | null;
   setCurrentUser: (user: IUser | null) => void;
   setUserImage: (image: string) => void;
   requestNewToken: () => Promise<void>
}

const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider: FC = ({ children }) => {
	const tempUser = LocalStorageWrapper.get<IUser>(LocalStorageKeys.USER);
	const [user, setUser] = useState<IUser | null>(tempUser);

	const setCurrentUser = (currentUser: IUser | null) => {
		setUser(currentUser);
		LocalStorageWrapper.set(LocalStorageKeys.USER, currentUser);
		LocalStorageWrapper.set(LocalStorageKeys.REFRESH_TOKEN, currentUser?.refreshToken);
	};

	const setUserImage = (image: string) => {
		setUser((prev) => {
			const mPrev = prev;
			if (mPrev) {
				mPrev.profileImage = image;
			}
			LocalStorageWrapper.set(LocalStorageKeys.USER, mPrev);
			return mPrev;
		});
	};

	const requestNewToken = async () => {
		const refToken = await LocalStorageWrapper.get(LocalStorageKeys.REFRESH_TOKEN) as string;
		if (refToken) {
			refreshToken(refToken)
				.then(({ data }) => {
					LocalStorageWrapper.set(LocalStorageKeys.USER, data);
					setUser(data);
				})
				.catch(() => {
					setCurrentUser(null);
				});
		} else {
			setCurrentUser(null);
		}
	};

	const value = useMemo(() => ({
		user,
		setCurrentUser,
		setUserImage,
		requestNewToken,
	}), [user, requestNewToken]);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
	return useContext(AuthContext);
}
