/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISaleReportResponse } from '@Types';
import * as XLSX from 'xlsx';

export const exportUserExcel = (fileName: string, rows: any, header: string[]) => {
	const worksheet = XLSX.utils.json_to_sheet(rows);
	/** WIDTH OF COLUMNS */
	worksheet['!cols'] = [
		{ wch: 15 },
		{ wch: 15 },
		{ wch: 20 },
		{ wch: 30 },
		{ wch: 12 },
		{ wch: 25 },
		{ wch: 25 },
		{ wch: 25 },
		{ wch: 15 },
		{ wch: 25 },
	];
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, 'dados');
	XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' });
	/* create an XLSX file and try to save  */
	XLSX.writeFile(workbook, fileName);
};

export const exportMultiTabbed = (
	fileName: string,
	header: string[],
	dataToExport?: ISaleReportResponse[],
) => {
	if (dataToExport) {
		const wb = XLSX.utils.book_new();

		const groupedByL: {[Key:string] : any} = {};

		dataToExport.forEach((el) => {
			groupedByL[el.organizationName] = groupedByL[el.organizationName] ?? [];
			groupedByL[el.organizationName] = [
				...groupedByL[el.organizationName],
				...el.orders.map((item) => ({
					id: item.orderId,
					date: item.date,
					seller: el.seller,
					client: item.customer,
					invoice: item.invoiceNumber,
					baseVaue: item.initTotalWithTaxes,
					discount: item.discount,
					usedBalance: item.usedBalance,
					finalValue: item.finalTotalWithTaxes,
					cashback: item.cashBack ?? 0,
					status: item.status.name,
				})),
			];
		}, {});

		(Object.keys(groupedByL)).forEach((gKey) => {
			const data = groupedByL[gKey];
			const ws = XLSX.utils.json_to_sheet(data);
			/** WIDTH OF COLUMNS */
			ws['!cols'] = [{ wch: 5 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 5 }, { wch: 11 }];
			XLSX.utils.book_append_sheet(wb, ws, gKey);
			XLSX.utils.sheet_add_aoa(ws, [header], { origin: 'A1' });
		});
		XLSX.writeFile(wb, fileName);
	}
};
