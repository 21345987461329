import {
	Space, Image, TextField, DefaultButton, FileInput,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Divider, Grid, useTheme,
} from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { placeholderImage } from '@Images';
import { IChainStoreReq } from '@Types';
import { getImageURL } from '@Helpers';

interface CreateChainStoreInterface {
	chainstore: IChainStoreReq;
	errors: Partial<IChainStoreReq>;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	submit: () => void;
	loading: boolean
}

const CreateChainStorePage: React.FC<CreateChainStoreInterface> = (props) => {
	const {
		chainstore, errors, onChange, submit, loading,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<div>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
					<PaperContainer>
						<TextField
							value={chainstore.name}
							variant="outlined"
							size="small"
							name="name"
							disabled={loading}
							label={t('name')}
							fullWidth
							helperText={errors.name}
							error={!!errors.name}
							onChange={onChange}
						/>
						<Space height={2} />
						<TextField
							value={chainstore.description}
							variant="outlined"
							size="small"
							name="description"
							disabled={loading}
							label={t('description')}
							fullWidth
							helperText={errors.description}
							error={!!errors.description}
							onChange={onChange}
						/>
						<Space height={2} />
						<Divider />
						<Space height={2} />
						<FileInput
							label={t('icon')}
							error={!!errors.profileImage}
							helperText={errors.profileImage}
							placeholder={typeof chainstore.profileImage === 'string' ? undefined : chainstore.profileImage?.name}
							name="profileImage"
							disabled={loading}
							onChange={onChange}
						/>
						<Space height={2} />
						<Image
							src={chainstore?.profileImage
								? getImageURL(chainstore.profileImage)
								: placeholderImage}
							height="50px"
							width="50px"
							alt="chainstore icon"
						/>
						<Space height={2} />
						<FileInput
							label={t('coverImage')}
							error={!!errors.coverImage}
							helperText={errors.coverImage}
							placeholder={typeof chainstore.coverImage === 'string' ? undefined : chainstore.coverImage?.name}
							name="coverImage"
							disabled={loading}
							onChange={onChange}
						/>
						<Space height={2} />
						<Image
							src={chainstore?.coverImage
								? getImageURL(chainstore.coverImage)
								: placeholderImage}
							height="150px"
							width="250px"
							style={{ borderRadius: 5 }}
							alt="chainstore cover"
						/>
						<Space height={2} />
						<DefaultButton title={t('save')} loading={loading} onClick={submit} />
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateChainStorePage;
