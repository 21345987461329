import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { currencyNumber, normalizeCurrency } from '@Masks';
import { useFeedback, useTitleBar } from '@Providers';
import { addCredit, getUserBalence, getUserById } from '@Requests';
import React, {
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BalencePointsPage from './BalencePointsPage';

const FORM_KEYS = {
	INPUT: 'value',
	RADIO: 'type',
};

const BalencePoints = () => {
	const { setTitle } = useTitleBar();
	const { id } = useParams<{ id: string }>();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();

	const userInfo = useRequest(([params]) => getUserBalence(params));
	const user = useRequest(([params]) => getUserById(params));

	const [values, setValues] = useState({ value: '', type: '1' });
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		const getData = () => {
			setTitle(t('balenceAddition'));
			userInfo.execute(id);
			user.execute(id);
		};
		getData();
	}, []);

	const handleChange = (value: string, key: string) => {
		if (key === FORM_KEYS.RADIO) {
			if (value === '1') {
				setValues({
					...values,
					[key]: value,
					value: !values.value ? '' : normalizeCurrency(values.value).toFixed(0),
				});
			} else {
				setValues({ ...values, [key]: value, value: currencyNumber(values.value) });
			}
		} else {
			setValues({ ...values, [key]: value });
		}
	};

	const handleSubmit = () => {
		if (!values.value) {
			return setError(t('emptyField'));
		}
		setError('');
		setLoading(true);
		const data = {
			mAmount: values.value,
			types: values.type,
			userId: id ?? '',
		};
		addCredit(data)
			.then((res) => {
				userInfo.execute(id);
				setValues({ ...values, value: '' });
				addToast({ message: res.message, error: false });
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			}).finally(() => setLoading(false));
		return null;
	};

	return (
		<BalencePointsPage
			userInfo={userInfo.data}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
			value={values}
			error={error}
			loading={loading}
			userImage={user.data?.profileImage}
		/>
	);
};

export default BalencePoints;
