import { exportUserExcel } from '@Export';
import { useTitleBar } from '@Providers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '@Api';
import { getOrderReport } from '@Requests';
// import { formatCurrency, formatDate } from '@Helpers';
import { formatCurrency, formatDate } from '@Helpers';
import OrderReportPage from './OrderReportPage';

const FIRST_DATE_OF_MONTH = new Date(new Date().setDate(1));

const OrderReport = () => {
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();

	const orders = useRequest(([param1, param2]) => getOrderReport(param1, param2));

	const [orderReportReq, setOrderReportReq] = useState({
		startDate: FIRST_DATE_OF_MONTH,
		endDate: new Date(),
	});

	useEffect(() => {
		setTitle(`${t('reports')} - ${t('orders')}`);
	}, [t, setTitle]);

	const onChange = (key: string, value: Date) => {
		setOrderReportReq({ ...orderReportReq, [key]: value });
	};

	const filterOrders = () => {
		orders.execute(orderReportReq.startDate, orderReportReq.endDate);
	};

	const exportOrderExcel = () => {
		if (orders.data) {
			const header = [
				t('orderNumber'),
				t('date'),
				t('location'),
				t('seller'),
				t('orderValue'),
				`${t('coupon')}/${t('transaction')}`,
				t('balence'),
				t('client'),
			];
			const coupons = orders.data?.map((item) => ({
				orderNumber: item.orderId,
				date: formatDate(item.registration),
				location: item.costCenter?.city,
				sellerName: item.sellerName,
				orderValue: formatCurrency(item?.orderAmount),
				transaction: item.description,
				balance: formatCurrency(item.amount),
				client: item.customerName,
			}));
			exportUserExcel(`${t('order')}-${moment(new Date()).format('DD-MM-YYYY HH:mm')}.xlsx`, coupons, header);
		}
	};

	return (
		<OrderReportPage
			orders={orders.data}
			loading={orders.loading}
			values={orderReportReq}
			onChange={onChange}
			filterOrders={filterOrders}
			exportExcel={exportOrderExcel}
		/>
	);
};

export default OrderReport;
