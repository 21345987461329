import { useEffectCustom } from '@Hooks';
import { useFeedback } from '@Providers';
import { checkResetToken, changePartnerPassword } from '@Requests';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ResetPasswordPage, { InitialValue } from './ResetPasswordPage';

export interface UserData {
	id: number;
	name: string
}

const ResetPassword = () => {
	const [initialValues] = useState({ password: '', confirmPassword: '' });
	const { t } = useTranslation('translations');
	const { id, token } = useParams();
	const { addToast } = useFeedback();

	const [user, setUser] = useState({} as UserData);
	const [expired, setExpired] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffectCustom(() => {
		const getUser = () => {
			if (id) {
				checkResetToken(id, token)
					.then((res) => setUser(res.data))
					.catch(({ response }) => {
						addToast({ message: response.data.message, error: true });
						setExpired(true);
					});
			}
		};
		getUser();
	}, []);

	const updatePassword = (value: InitialValue, helpers: FormikHelpers<InitialValue>) => {
		changePartnerPassword(id, token, value.password)
			.then((res) => {
				addToast({ message: res.message, error: false });
				setSuccess(true);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => { helpers.setSubmitting(false); });
	};

	const validate = (values: InitialValue) => {
		const errors = {} as FormikErrors<InitialValue>;
		if (!values.password) {
			errors.password = t('emptyField');
		}
		if (!(/[a-zA-Z]/.test(values.password))) {
			errors.password = t('passwordAplhabets');
		}

		if (!(/[0-9]/.test(values.password))) {
			errors.password = t('passwordNumber');
		}

		if (!(/[^a-zA-Z0-9]/.test(values.password))) {
			errors.password = t('passwordSpecial');
		}
		if (values.password.length < 8) {
			errors.password = t('passwordLength');
		}
		if (!values.confirmPassword) {
			errors.confirmPassword = t('emptyField');
			if (values.confirmPassword.length < 8) {
				errors.confirmPassword = t('passwordLength');
			}
		}
		if (values.password !== values.confirmPassword) {
			errors.confirmPassword = t('passwordsMatch');
		}
		return errors;
	};

	return 	(
		<ResetPasswordPage
			initialValues={initialValues}
			submit={updatePassword}
			validate={validate}
			user={user}
			expired={expired}
			success={success}
		/>
	);
};

export default ResetPassword;
