import {
	Autocomplete, DatePicker, DefaultButton, Space, TableItem,
} from '@Atoms';
// import { formatCurrency, formatPercentage } from '@Helpers';
import { PaperContainer } from '@Molecules';
import { Summarize } from '@mui/icons-material';
import { Grid, useTheme } from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { ICouponQuantityReport, ICouponQuantityReportReq, IOrganization } from '@Types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface CouponReportPage{
	couponReport?: ICouponQuantityReport[];
	organization?: IOrganization[];
	couponRequest: ICouponQuantityReportReq;
	onChange: (key: string, value: Date | IOrganization[]) => void;
	onFilterClick: () => void;
	loading: boolean;
	onExportClick: ()=>void
}

const CouponsReportPage: React.FC<CouponReportPage> = (props) => {
	const {
		couponReport,
		organization,
		couponRequest,
		onChange,
		onFilterClick,
		loading,
		onExportClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid sx={{ padding: theme.spacing(3) }} container spacing={3}>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('startDate')}
									value={couponRequest.startDate}
									disabled={loading}
									onChange={(value) => onChange('startDate', value)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DatePicker
									label={t('endDate')}
									value={couponRequest.endDate}
									disabled={loading}
									onChange={(value) => onChange('endDate', value)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<Autocomplete
									label={t('organizations')}
									placeholder=""
									multiple
									disabled={loading}
									options={organization || []}
									defaultValue={couponRequest.organizationIds}
									isOptionEqualToValue={(option, value) => option.id === value.id}
									renderOption={(inputprops, item) => (
										<li
											{...inputprops}
											key={item.id}
										>
											{item.fantasyName}
										</li>
									)}
									getOptionLabel={(item) => item.fantasyName}
									onChange={(_, value) => onChange('organizationIds', value)}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<DefaultButton
									title={t('filter')}
									onClick={onFilterClick}
									loading={loading}
								/>
							</Grid>
						</Grid>
					</PaperContainer>
				</Grid>
			</Grid>
			<Grid
				sx={{
					pr: theme.spacing(3),
					pl: theme.spacing(3),
					pb: theme.spacing(3),
				}}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DefaultButton title={t('export')} disabled={!couponReport || couponReport.length === 0} startIcon={<Summarize />} onClick={onExportClick} />
						<Space height={2} />
						<DataTable data={couponReport || []} search={false}>
							<TableColumn headerText={t('organization')} render={(item) => <TableItem title={item.organizationName} />} />
							<TableColumn headerText={t('costCenter')} render={(item) => item.costCenterName} />
							<TableColumn headerText={t('seller')} render={(item) => item.sellerName} />
							<TableColumn headerText={t('cashBackCouponQuantity')} render={(item) => (item.cashBackCouponQuantity)} />
							<TableColumn headerText={t('cashBackCouponTotal')} render={(item) => item.cashBackCouponTotal} />
							<TableColumn headerText={t('discountCouponQuantity')} render={(item) => item.discountCouponQuantity} />
							<TableColumn headerText={t('discountCouponTotal')} render={(item) => item.discountCouponTotal} />
						</DataTable>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CouponsReportPage;
