/* eslint-disable @typescript-eslint/no-explicit-any */
import { normalize, normalizePhone, replaceComma } from '@Masks';
import {
	ApiResponse,
	IBarcodeRes,
	ICostCenter,
	IListDataControl,
	INameAndId,
	IOrganization,
	IOrganizationReq,
	IPagination,
	IService,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getOrganizations = async (params: IListDataControl, signal: AbortSignal):
	Promise<ApiResponse<IPagination<IOrganization[]>>> => {
	const resp = await reqGet({
		url: Endpoint.LIST_ORGANIZATIONS.format(params.page, params.size, params.search),
		signal,
	});
	const data = resp.data as ApiResponse<IPagination<IOrganization[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getOrganizationById = async (organizationId: string):
	Promise<ApiResponse<IOrganization>> => {
	const resp = await reqGet({
		url: Endpoint.ORGANIZATION_BY_ID.format(organizationId),
	});
	const data = resp.data as ApiResponse<IOrganization>;
	data.statusCode = resp?.status;
	return data;
};

export const changeOrganizationStatus = async (organizationId: string):
	Promise<ApiResponse<IOrganization>> => {
	const resp = await reqPost({
		url: Endpoint.ORGANIZATION_STATUS.format(organizationId),
	});
	const data = resp.data as ApiResponse<IOrganization>;
	data.statusCode = resp?.status;
	return data;
};

export const addOrganization = async (organization: any):
	Promise<ApiResponse<IOrganization>> => {
	const dataToUpload = {
		...organization,
		countryId: organization.countryId?.id,
		cityId: organization.countryId.id === 1 ? organization.cityId?.id : 1,
		stateId: organization.countryId.id === 1 ? organization.stateId?.id : 1,
		taxId: normalize(organization.taxId),
		chainStoreId: organization.chainStoreId.id,
		longitude: replaceComma(organization.longitude),
		latitude: replaceComma(organization.latitude),
		postalCode: normalize(organization.postalCode),
		phone: normalizePhone(organization?.phone),
	};
	const resp = await reqPost({
		url: Endpoint.ORGANIZATION,
		data: { ...dataToUpload },
	});
	const data = resp.data as ApiResponse<IOrganization>;
	data.statusCode = resp?.status;
	return data;
};

export const editOrganization = async (
	organizationId: string,
	organizationFormValues: IOrganizationReq,
):
	Promise<ApiResponse<IOrganization>> => {
	const organizationToUpload = {
		...organizationFormValues,
		cityId: organizationFormValues.cityId?.id,
		countryId: organizationFormValues.countryId?.id,
		stateId: organizationFormValues.stateId?.id,
		taxId: normalize(organizationFormValues.taxId),
		chainStoreId: organizationFormValues.chainStoreId.id,
		longitude: replaceComma(organizationFormValues.longitude),
		latitude: replaceComma(organizationFormValues.latitude),
		postalCode: normalize(organizationFormValues.postalCode),
		phone: normalizePhone(organizationFormValues?.phone),
	};
	const resp = await reqPost({
		url: Endpoint.ORGANIZATION_BY_ID.format(organizationId),
		data: { ...organizationToUpload },
	});
	const data = resp.data as ApiResponse<IOrganization>;
	data.statusCode = resp.status;
	return data;
};

export const getOrganizationNames = async (): Promise<ApiResponse<IOrganization[]>> => {
	const resp = await reqGet({
		url: Endpoint.ORGANIZATIONS_NAMES,
	});
	const data = resp.data as ApiResponse<IOrganization[]>;
	data.statusCode = resp?.status;
	return data;
};

export const getCostCentersByOrganizationId = async ():
Promise<ApiResponse<ICostCenter[]>> => {
	const resp = await reqGet({
		url: Endpoint.ORGANIZATIONS_NAMES,
	});
	const data = resp.data as ApiResponse<ICostCenter[]>;
	data.statusCode = resp?.status;
	return data;
};

export const getOrganizationSevices = async (organizationId: string):
	Promise<ApiResponse<IPagination<IService[]>>> => {
	const resp = await reqGet({
		url: Endpoint.ORGANIZATION_SERVICES.format(organizationId),
	});
	const data = resp.data as ApiResponse<IPagination<IService[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const getOrganizationBarcode = async (organizationId: number):
	Promise<ApiResponse<IBarcodeRes>> => {
	const resp = await reqGet({
		url: Endpoint.ORGANIZATION_BARCODE.format(organizationId),
	});
	const data = resp.data as ApiResponse<IBarcodeRes>;
	data.statusCode = resp.status;
	return data;
};

export const getAllSellers = async (
	organizationId?: number,
): Promise<ApiResponse<INameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.GET_SELLERS.format(
			organizationId,
		),
	});
	const data = resp.data as ApiResponse<INameAndId[]>;
	data.statusCode = resp.status;
	return data;
};
