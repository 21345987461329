import React, { JSXElementConstructor } from 'react';
import {
	FeedbackProvider,
	LanguageProvider,
} from '@Providers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withContext = (WrappedComponent: JSXElementConstructor<any>) => (props: any) => (
	<FeedbackProvider>
		<LanguageProvider>
			<WrappedComponent {...props} />
		</LanguageProvider>
	</FeedbackProvider>
);

export default withContext;
