import { Image } from '@Atoms';
import { useEffectCustom } from '@Hooks';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import { getArticles } from '@Requests';
import {
	IPagination,
	IListDataControl,
	IArticle,
	IPaginationTable,
} from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ArticlesListPage from './ArticlesListPage';

const ArticlesList = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const navigate = useNavigate();
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const [articles, setArticles] = useState<IPagination<IArticle[]> | undefined>();
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');
	const [showModal, setShowModal] = useState(false);
	const [modalData, setModalData] = useState({ title: '', description: '' });

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		setTitle(t('articles'));
		const getData = () => {
			const listControl: IListDataControl = {
				page: pagination.page,
				search,
				size: pagination.size,
			};
			setGlobalLoading(true);
			getArticles(listControl, abortCtrl.signal)
				.then((res) => {
					setArticles(res.data);
				})
				.catch(({ response }) => {
					addToast({ message: response.data.message, error: true });
				})
				.finally(() => {
					setGlobalLoading(false);
				});
		};
		getData();
		return () => abortCtrl.abort();
	}, [search, pagination]);

	const handleListAction = (action: string, item?: IArticle) => {
		switch (action) {
		case t('new'):
			navigate('/articles/create');
			break;
		case t('details'):
			navigate('/articles/{0}/edit'.format(item?.id));
			break;
		case t('view'):
			setShowModal(true);
			setModalData({ ...modalData, title: item?.image || '', description: item?.description || '' });
			break;
		case t('access'):
			window.open(item?.link);
			break;
		default:
			break;
		}
	};

	const closeModal = () => {
		setShowModal(false);
		setModalData({ ...modalData, title: '' });
	};

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<>
			{showModal && (
				<Dialog
					title={(
						<Image
							alt="article"
							src={modalData.title}
							altImage=""
							height={300}
							width={300}
						/>
					)}
					description={modalData.description}
					open={showModal}
					handleClose={closeModal}
				/>
			)}
			<ArticlesListPage
				articles={articles}
				onPageChange={setTablePagination}
				onSearch={setTableSearch}
				handleListAction={handleListAction}
			/>
		</>
	);
};

export default ArticlesList;
