import { Grid, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaperContainer } from '@Molecules';
import {
	Autocomplete,
	DatePicker,
	DefaultButton,
	DateLabel,
	TableItem,
	StatusLabel,
	Space,
} from '@Atoms';
import {
	ICard, ICardUser, ITransactionReport, TransactionReportReq,
} from '@Types';
import { TableColumn, DataTable } from '@Organisms';
import { formatCurrency } from '@Helpers';
import { Print, Summarize } from '@mui/icons-material';
import {
	Form, Formik, FormikHelpers, FormikErrors,
} from 'formik';
import { printReport } from '@Print';

interface TransactionReportProps {
	initialValues: TransactionReportReq;
	cards?: ICard[];
	cardUsers?: ICardUser[];
	getCardUsersById: (cardId: number) => void;
	validateTransactionReport: (values: TransactionReportReq) => FormikErrors<TransactionReportReq>;
	getTransactions: (
		values: TransactionReportReq,
		formikHelpers: FormikHelpers<TransactionReportReq>) => void;
	transactions?: ITransactionReport[];
	total: number;
	onExportClick: ()=>void
}

const TransactionReportPage: FC<TransactionReportProps> = (props) => {
	const {
		initialValues,
		cards,
		cardUsers,
		getCardUsersById,
		validateTransactionReport,
		getTransactions,
		transactions,
		total,
		onExportClick,
	} = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const printRef = React.useRef<HTMLDivElement>(null);

	const handlePrintClick = () => {
		printReport(printRef);
	};

	return (
		<div>
			<Grid container spacing={2} sx={{ padding: theme.spacing(3) }}>
				<Grid item xs={12} md={12}>
					<Formik
						initialValues={initialValues}
						onSubmit={getTransactions}
						validate={validateTransactionReport}
					>
						{({
							values, isSubmitting, setFieldValue, errors, touched,
						}) => (
							<Form>
								<PaperContainer>
									<Grid container spacing={2}>
										<Grid item xs={12} md={3}>
											<Autocomplete
												options={cards || []}
												defaultValue={values.cardId}
												error={Boolean(touched.cardId && errors.cardId)}
												helperText={touched.cardId && String(errors.cardId || '')}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												disabled={isSubmitting}
												label={t('card')}
												placeholder=""
												renderOption={(inputprops, item) => (
													<li
														{...inputprops}
														key={item.id}
													>
														{item.name}
													</li>
												)}
												getOptionLabel={(item) => item.name || ''}
												onChange={(_, item) => {
													getCardUsersById(item?.id || 0);
													setFieldValue('cardId', item);
												}}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<Autocomplete
												options={cardUsers || []}
												disabled={isSubmitting}
												defaultValue={values.userId}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												label={t('user')}
												placeholder=""
												renderOption={(inputprops, item) => (
													<li {...inputprops} key={item.id}>
														{item.user?.name || item.phone}
													</li>
												)}
												getOptionLabel={(item) => item.user?.name || item.phone || ''}
												onChange={(_, item) => setFieldValue('userId', item)}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<DatePicker
												label={t('startDate')}
												value={values.startDate}
												disabled={isSubmitting}
												onChange={(value) => setFieldValue('startDate', value)}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<DatePicker
												label={t('endDate')}
												minDate={values.startDate}
												value={values.endDate}
												disabled={isSubmitting}
												onChange={(value) => setFieldValue('endDate', value)}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<DefaultButton title={t('filter')} type="submit" loading={isSubmitting} />
										</Grid>
									</Grid>
								</PaperContainer>
							</Form>
						)}
					</Formik>

				</Grid>
			</Grid>
			<Grid
				sx={{
					pl: theme.spacing(3),
					pr: theme.spacing(3),
					pb: theme.spacing(3),
				}}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<Space height={2} />
						<DefaultButton title={t('print')} startIcon={<Print />} onClick={handlePrintClick} />
						<DefaultButton title={t('export')} sx={{ ml: 2, textTransform: 'none' }} startIcon={<Summarize />} onClick={onExportClick} />
						{/* <DefaultButton
							sx={{ ml: 2, textTransform: 'none' }}
							title={t('export')}
							startIcon={<Summarize />}
							onClick={exportExcel}
						/> */}
						<Space height={2} />
						<div ref={printRef}>
							<DataTable
								data={transactions || []}
								search={false}
							>
								<TableColumn headerText="ID" render={(item) => item.id} footer="" />
								<TableColumn headerText={t('date')} render={(item) => <DateLabel date={item?.entryDate} showTime />} footer="" />
								<TableColumn
									headerText={t('description')}
									render={(item) => (
										<TableItem
											title={item?.operationName}
											subTitle={`${t('description')}: ${item?.description}`}
										/>
									)}
									footer=""
								/>
								<TableColumn
									headerText={t('user')}
									render={(item) => (
										<TableItem
											title={item.userName}
											// titleIcon={<Person sx={iconSx()} />}
											// subTitleIcon={<Phone sx={iconSx()} />}
											subTitle={item.userPhone}
										/>
									)}
									footer={<TableItem title={t('total')} color="red" />}
								/>
								<TableColumn headerText={t('value')} render={(item) => `${formatCurrency(item.amount)}`} footer={formatCurrency(total)} />
								<TableColumn headerText={t('status')} render={(item) => <StatusLabel status={item.status} />} footer="" />
							</DataTable>
						</div>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default TransactionReportPage;
