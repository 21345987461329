import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getTerms } from '@Requests';
import {
	IListDataControl,
	IPagination,
	IPaginationTable,
	ITerm,
} from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import TermsListPage from './TermsListPage';

const TermsList = () => {
	const { t } = useTranslation('translations');
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const [terms, setTerms] = useState<IPagination<ITerm[]> | undefined>();
	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffectCustom(() => {
		setTitle(t('terms'));

		const listControl: IListDataControl = {
			page: pagination.page,
			search,
			size: pagination.size,
		};
		setGlobalLoading(true);
		getTerms(listControl)
			.then((res) => {
				setTerms(res.data);
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => {
				setGlobalLoading(false);
			});
	}, [search, pagination]);

	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<TermsListPage
			terms={terms}
			onPageChange={setTablePagination}
			onSearch={setTableSearch}
		/>
	);
};

export default TermsList;
