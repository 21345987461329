import {
	IListDataControl,
	ApiResponse,
	IPagination,
	ICreateArticlesReq,
	IArticle,
	INameAndId,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './file.service';

export const getArticles = async (props: IListDataControl, signal: AbortSignal):
    Promise<ApiResponse<IPagination<IArticle[]>>> => {
	const { page, size, search } = props;
	const resp = await reqGet({
		url: Endpoint.GET_ARTICLES.format(page, size, search),
		signal,
	});
	const data = resp.data as ApiResponse<IPagination<IArticle[]>>;
	data.statusCode = resp?.status;
	return data;
};

export const changeArticleStatus = async (articleId: string): Promise<ApiResponse<IArticle>> => {
	const resp = await reqPost({
		url: Endpoint.ARTICLE_STATUS.format(articleId),
	});
	const data = resp.data as ApiResponse<IArticle>;
	return data;
};

export const getArticleById = async (id: string, signal: AbortSignal):
	Promise<ApiResponse<IArticle>> => {
	const resp = await reqGet({
		url: Endpoint.EDIT_ARTICLE.format(id),
		signal,
	});
	const data = resp.data as ApiResponse<IArticle>;
	data.statusCode = resp?.status;
	return data;
};

export const createArticle = async (model: ICreateArticlesReq): Promise<ApiResponse<IArticle>> => {
	let { image } = model;
	if (typeof image === 'object') {
		if (image.size) {
			const data = new FormData();
			data.append('file', image);
			const res = await uploadFile(data);
			image = res.data.file;
		}
	}
	const resp = await reqPost({
		url: Endpoint.ARTICLES,
		data: { ...model, image },
	});
	const data = resp.data as ApiResponse<IArticle>;
	data.statusCode = resp?.status;
	return data;
};

export const editArticle = async (articleId: string, model: ICreateArticlesReq):
    Promise<ApiResponse<IArticle>> => {
	let { image } = model;
	if (typeof image === 'object') {
		if (image.size) {
			const data = new FormData();
			data.append('file', image);
			const res = await uploadFile(data);
			image = res.data.file;
		}
	}
	const resp = await reqPost({
		url: Endpoint.EDIT_ARTICLE.format(articleId),
		data: { ...model, image },
	});
	const data = resp.data as ApiResponse<IArticle>;
	data.statusCode = resp?.status;
	return data;
};

export const getArticleStatus = async (signal: AbortSignal): Promise<ApiResponse<INameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.ALL_ARTICLE_STATUSES,
		signal,
	});
	const data = resp.data as ApiResponse<INameAndId[]>;
	data.statusCode = resp.status;
	return data;
};
