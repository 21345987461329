export const validateNif = (mValue: string) => {
	let value = mValue;
	value = value.replace(/-/g, '');
	if (!value || typeof value !== 'string' || value.length !== 9) return false;

	let sumAux = 0;
	for (let i = 9; i >= 2; i -= 1) {
		sumAux += i * (parseInt(value[value.length - i], 10) || 0);
	}

	const module = sumAux % 11;

	// Get the eight first numbers
	const NIFwithoutLastDigit = value.slice(0, value.length - 1);

	if (module === 0 || module === 1) {
		return `${NIFwithoutLastDigit}0` === value;
	}
	return `${NIFwithoutLastDigit}${11 - module}` === value;
};
