import { Description } from '@Atoms';
import React from 'react';

interface TicketListItemProps {
	title: string;
	value: string | React.ReactNode
}

const TicketListItem: React.FC<TicketListItemProps> = (props) => {
	const { title, value } = props;
	return (
		<div>
			<Description fontWeight="bold" text={title} />
			<div />
			{typeof value === 'string' && <Description text={value} />}
			{typeof value === 'object' && value}
		</div>
	);
};

export default TicketListItem;
