import { PaperContainer } from '@Molecules';
import {
	Box,
	Grid,
	FormControlLabel,
	Checkbox,
	useTheme,
	Typography,
} from '@mui/material';
import {
	IGender, ICoupon, IDefineCouponAudienceReq, ITag, INameAndId, IForm as FormType,
} from '@Types';
import {
	Form, Formik, FormikHelpers,
} from 'formik';
import { useTranslation } from 'react-i18next';
import {
	Autocomplete, DefaultButton, Image, InfoBox, Space, Title,
	TextField,
} from '@Atoms';
import React from 'react';

export interface DefineAudienceProps {
   tags?: ITag[];
   genders: IGender[] | undefined;
   coupon: ICoupon | undefined;
   initialValues: IDefineCouponAudienceReq;
   onDefineAudience: (
      values: IDefineCouponAudienceReq,
      formikHelpers: FormikHelpers<IDefineCouponAudienceReq>) => void;
   loading: boolean;
   validate: (values: IDefineCouponAudienceReq) => Partial<IDefineCouponAudienceReq>;
   getAudience: (items: IDefineCouponAudienceReq) => void;
   currentAudience?: INameAndId[];
   audienceByFilter?: INameAndId[];
   forms?: FormType;
}

const DefineAudiencePage = (props: DefineAudienceProps) => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const {
		validate,
		onDefineAudience,
		initialValues,
		loading,
		tags,
		coupon,
		genders,
		getAudience,
		currentAudience,
		audienceByFilter,
		forms,
	} = props;
	const Brand = forms?.fields.find((el) => el.type.id === 4);
	return (
		<Box>
			<Grid container sx={{ padding: theme.spacing(3) }} spacing={theme.spacing(3)}>
				<Grid item xs={12} md={6} lg={4}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							enableReinitialize
							validateOnMount={false}
							validateOnBlur={false}
							validateOnChange={false}
							validate={validate}
							onSubmit={onDefineAudience}
						>
							{({
								values, handleChange, setFieldValue,
							}) => (
								<Form>
									<Grid container sx={{ padding: theme.spacing(2) }} spacing={3}>
										<Grid item xs={12}>
											<InfoBox>
												<Typography variant="body2">{t('defineCouponInfo').format(currentAudience?.length || 0)}</Typography>
											</InfoBox>
										</Grid>
										<Grid item xs={12}>
											<Image height={180} width="100%" src={coupon?.banner ?? ''} alt={`coupons-item-image-${coupon?.id}`} />
										</Grid>
										<Grid item xs={12}>
											<Autocomplete
												label={t('gender')}
												placeholder=""
												options={genders || []}
												multiple
												getOptionLabel={(item) => item.name || ''}
												renderOption={(autoCompleteProps, item) => (
													<li {...autoCompleteProps}>{item.name}</li>
												)}
												defaultValue={values.genders}
												isOptionEqualToValue={(item, option) => item.id === option.id}
												onChange={(event, items) => {
													if (items) {
														setFieldValue('genders', items);
														getAudience({ ...values, genders: items });
													}
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Autocomplete
												label={t('tags')}
												placeholder=""
												options={tags || []}
												multiple
												getOptionLabel={(item) => item.name || ''}
												renderOption={(autoCompleteProps, item) => (
													<li {...autoCompleteProps}>{item.name}</li>
												)}
												defaultValue={values.tags}
												isOptionEqualToValue={(item, option) => item.id === option.id}
												onChange={(event, items) => {
													if (items) {
														setFieldValue('tags', items);
														getAudience({ ...values, tags: items });
													}
												}}
											/>
											<Space height={2} />
											<FormControlLabel
												control={<Checkbox name="hasVehicle" checked={values.hasVehicle} />}
												onChange={(event) => {
													handleChange(event);
													getAudience({ ...values, hasVehicle: !values.hasVehicle });
												}}
												label={t('hasVehicle').toString()}
											/>
											<Space height={2} />
											{values.hasVehicle && (
												<>
													<Autocomplete
														label={Brand?.label || ''}
														placeholder=""
														options={Brand?.options || []}
														multiple
														getOptionLabel={(item) => item || ''}
														renderOption={(autoCompleteProps, item) => (
															<li {...autoCompleteProps}>{item}</li>
														)}
														defaultValue={values.brands}
														isOptionEqualToValue={(item, option) => item === option}
														onChange={(event, items) => {
															if (items) {
																setFieldValue('brands', items);
																getAudience({ ...values, brands: items });
															}
														}}
													/>
													<Space height={2} />
													<TextField
														label={t('model')}
														name="model"
														value={values.model}
														onBlur={() => getAudience(values)}
														onChange={(event) => setFieldValue('model', event.target.value)}
													/>
												</>
											)}
										</Grid>
										<Grid item xs={12}>
											<Title
												variant="body1"
												fontWeight="bold"
												color="red"
												text={t('newUserReached').format(((audienceByFilter?.length || 0) - (currentAudience?.length || 0)) < 0 ? 0 : (audienceByFilter?.length || 0) - (currentAudience?.length || 0))}
											/>
										</Grid>
										<Grid item xs={3}>
											<DefaultButton title={t('save')} loading={loading} type="submit" />
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DefineAudiencePage;
