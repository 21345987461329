import {
	ApiResponse, ISaleReportResponse, ISaleReportParams, INameAndId,
} from '@Types';
import { encodeDate } from '@Helpers';
import { reqGet } from '../request.api';
import Endpoint from '../endpoints.api';

export const getSalesReport = async (params: ISaleReportParams):
	Promise<ApiResponse<ISaleReportResponse[]>> => {
	const {
		initialDate, finalDate, selectedCustomer, selectedOrganization, statusId,
	} = params;
	const encodedInitialDate = encodeDate(initialDate);
	const encodedfinalDate = encodeDate(finalDate);
	const resp = await reqGet({
		url: Endpoint.SALES_REPORT.format(
			encodedInitialDate,
			encodedfinalDate,
			selectedCustomer ? selectedCustomer.id : '',
			selectedOrganization ? selectedOrganization.id : '',
			statusId || '',
		),
	});
	const data = resp.data as ApiResponse<ISaleReportResponse[]>;
	data.statusCode = resp.status;
	return data;
};

export const getPartnerSellers = async (): Promise<ApiResponse<INameAndId[]>> => {
	const resp = await reqGet({
		url: Endpoint.GET_PARTNER_SELLERS,
		data: { hello: 'abc' },
	});
	const data = resp.data as ApiResponse<INameAndId[]>;
	data.statusCode = resp.status;
	return data;
};
