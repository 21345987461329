import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import {
	getCouponById,
	getCurrentAudience,
	getGenders,
	getTags,
	getAudienceByFilter,
	getFormsById,
	getAudienceFilterElements,
	saveAudience,
} from '@Requests';
import {
	IListDataControl,
	ICoupon,
	IGender,
	ITag,
	IDefineCouponAudienceReq,
} from '@Types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DefineAudiencePage from './DefineAudiencePage';

const DefineAudience = () => {
	const { id } = useParams();
	const { t } = useTranslation('translations');
	const { setTitle } = useTitleBar();
	const { addToast } = useFeedback();

	const currentAudience = useRequest((params) => getCurrentAudience(params));
	const audienceByFilter = useRequest(([params]) => getAudienceByFilter(params));
	const forms = useRequest(([params]) => getFormsById(params));

	const [tags, setTags] = useState<ITag[]>();
	const [genders, setGenders] = useState<IGender[]>();
	const [coupon, setCoupon] = useState<ICoupon>();
	const [loading, setLoading] = useState(false);

	const [initialValues, setInitialValues] = useState({
		genders: [] as IGender[],
		tags: [] as ITag[],
		hasVehicle: false,
		brands: [] as string[],
		model: '',
	});
	const getData = async (signal?: AbortSignal) => {
		setTitle(`${t('coupon')} - ${t('defineAudience')}`);

		const listControl: IListDataControl = {
			page: 1,
			size: 2147483647,
			search: '',
		};
		const [
			genderRes,
			tagRes,
			couponRes,
		] = await Promise.all([
			getGenders(listControl),
			getTags(listControl),
			getCouponById(id ?? '', signal)]);

		setGenders(genderRes.data);
		setTags(tagRes.data.data);
		setCoupon(couponRes.data);

		currentAudience.execute(id);
		getAudienceFilterElements(id ?? '')
			.then(({ data }) => {
				setInitialValues({
					...initialValues,
					genders: genderRes?.data?.filter((o1) => (
						data?.filter?.gender?.some((o2) => o1.id.toString() === o2.value))) || [],
					tags: tagRes.data.data?.filter((o1) => (
						data?.filter?.tags?.some((o2) => o1.id === o2.fieldId))) || [],
					hasVehicle: data?.filter?.has_vehicle,
					brands: data?.filter?.brand?.reduce((br, el) => {
						br.push(el.value); return br;
					}, [] as string[]),
					model: data?.filter?.models?.value,
				});
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }));
		forms.execute(1);
	};
	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		getData(abortCtrl.signal);
		return () => abortCtrl.abort();
	}, []);

	const validate = () => {
		const errors: Partial<IDefineCouponAudienceReq> = {};

		return errors;
	};

	const defineAudience = (values: IDefineCouponAudienceReq) => {
		const audience = audienceByFilter.data?.filter((el) => (
			!currentAudience.data?.some((item) => el.id === item.id)
		));
		setLoading(true);
		saveAudience(id ?? '', values, audience)
			.then((res) => {
				addToast({ message: res.message, error: false });
				getData();
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setLoading(false));
	};

	const getAudience = (audienceFilters: IDefineCouponAudienceReq) => {
		audienceByFilter.execute(audienceFilters);
	};

	return (
		<DefineAudiencePage
			initialValues={initialValues}
			validate={validate}
			tags={tags}
			genders={genders}
			coupon={coupon}
			onDefineAudience={defineAudience}
			loading={loading}
			getAudience={getAudience}
			currentAudience={currentAudience.data}
			audienceByFilter={audienceByFilter.data}
			forms={forms.data}
		/>
	);
};

export default DefineAudience;
