import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { Dialog } from '@Organisms';
import { useFeedback, useTitleBar } from '@Providers';
import {
	createCostCenter,
	editCostCenter,
	getOrganizationById,
	getCostCenterById,
	addPartnerUser,
	getUsers,
	removePartnerUser,
	getPartnerById,
} from '@Requests';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateCostCenterPage from './CreateCostCenterPage';

const CreateCostCenter = () => {
	const { id, organizationId, partnerId } = useParams();
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast, addDialog } = useFeedback();
	const navigate = useNavigate();
	const abortCtrl = new AbortController();

	const organization = useRequest(([params]) => getOrganizationById(params));
	const partner = useRequest(([params]) => getPartnerById(params));
	const users = useRequest(([param1, param2]) => getUsers(param1, abortCtrl.signal, param2));
	const removePartner = useRequest(([param1, param2]) => removePartnerUser(param1, param2));

	const [costCenter, setCostCenter] = useState({ name: '', email: '' });
	const [errors, setErrors] = useState({ name: '', email: '' });
	const [loading, setLoading] = useState(false);
	const [partnerLoading, setPartnerLoading] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [userToRemove, setUserToRemove] = useState('');

	useEffectCustom(() => {
		const getData = () => {
			if (organizationId) {
				organization.execute(organizationId);
			}
			if (partnerId) {
				partner.execute(partnerId);
			}
			const listDataControl = {
				page: 1,
				size: 1000,
				search: '',
			};
			users.execute(listDataControl, id);
		};
		getData();
	}, []);

	useEffectCustom(() => {
		const { data } = removePartner;
		if (data) {
			const index = users.data?.data.findIndex((el) => el.email === data.email);
			if (index !== undefined) {
				users.setData((prev) => {
					const mPrev = prev;
					mPrev?.data.splice(index, 1);
					return mPrev;
				});
			}
		}
	}, [removePartner.data]);

	const getCC = () => {
		getCostCenterById(id).then((res) => { setCostCenter({ ...costCenter, name: res.data.name }); })
			.catch(({ response }) => addToast({ message: response.data.message, error: true }));
	};

	useEffectCustom(() => {
		if (id) {
			setTitle(`${t('edit')} ${t('costCenter')}`);
			getCC();
		} else {
			setTitle(`${t('new')} ${t('costCenter')}`);
		}
	}, [id, setTitle, t]);

	const handleChange = (key: string, value: string) => {
		setCostCenter({ ...costCenter, [key]: value });
	};

	const submit = () => {
		if (!costCenter.name) {
			return setErrors({ ...errors, name: t('emptyField') });
		}
		setErrors({ ...errors, name: '' });
		setLoading(true);
		const orgId = organizationId ?? partnerId;
		if (id) {
			editCostCenter(id, { organizationId: orgId, name: costCenter.name })
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(-1);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => setLoading(false));
		} else {
			createCostCenter({ organizationId: orgId, name: costCenter.name })
				.then((res) => {
					addToast({ message: res.message, error: false });
					navigate(-1);
				})
				.catch(({ response }) => addDialog({
					title: response.data.title,
					message: response.data.message,
					error: true,
				}))
				.finally(() => setLoading(false));
		}
		return null;
	};

	const addPartner = (email: string) => {
		setPartnerLoading(true);
		addPartnerUser(email, id).then((res) => {
			users.setData((prev) => {
				const mPrev = prev;
				mPrev?.data.push(res.data);
				return mPrev;
			});
			setCostCenter({ ...costCenter, email: '' });
			addToast({ message: res.message, error: false });
		}).catch(({ response }) => {
			addToast({ message: response.data.message, error: false });
		}).finally(() => {
			setPartnerLoading(false);
		});
	};

	const handlePartnerUserClick = (action: string, userEmail?: string) => {
		switch (action) {
		case t('add'):
			if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(costCenter.email)) {
				return setErrors({ ...errors, email: t('invalidEmail') });
			}
			setErrors({ ...errors, email: '' });
			addPartner(costCenter.email);
			break;
		case t('remove'):
			setShowRemoveModal(true);
			setUserToRemove(userEmail || '');
			break;
		default:
			break;
		}
		return null;
	};

	const handleCloseModal = () => {
		setShowRemoveModal(false);
	};

	const removeUser = () => {
		removePartner.execute(userToRemove, id);
	};

	return (
		<>
			<Dialog
				open={showRemoveModal}
				handleClose={handleCloseModal}
				title={t('removeThisUser')}
				description={t('removeThisUserDecription')}
				primaryButtonText={t('confirmRemove')}
				primaryButtonAction={removeUser}
				secondaryButtonText={t('no')}
				secondaryButtonAction={handleCloseModal}
			/>
			<CreateCostCenterPage
				organization={organization.data || partner.data}
				handleChange={handleChange}
				values={costCenter}
				errors={errors}
				submit={submit}
				loading={loading}
				edit={!!id}
				handlePartnerUserClick={handlePartnerUserClick}
				users={users.data?.data}
				addMemberLoading={partnerLoading}
			/>

		</>
	);
};

export default CreateCostCenter;
