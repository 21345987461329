import { useFeedback, useTitleBar } from '@Providers';
import { editRating, getRatingById } from '@Requests';
import { IRating } from '@Types';
import { AppRoutes } from '@Routes';
import { FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffectCustom } from '@Hooks';
import CreateRatingPage from './CreateRatingPage';

const CreateRating = () => {
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { id } = useParams();
	const { addToast } = useFeedback();
	const [rating, setRating] = useState<IRating>();
	const [initialValues, setInitialValues] = useState<IRating>({
		id: -1,
		minScore: 0,
		name: '',
	});

	useEffectCustom(() => {
		setTitle(`${t('edit')} ${t('rating')}`);

		if (!rating) {
			setGlobalLoading(true);
			getRatingById(id)
				.then((res) => {
					setRating(res.data);
					setInitialValues(res.data);
				})
				.catch(({ response }) => addToast({ message: response.data.message, error: true }))
				.finally(() => setGlobalLoading(false));
		}
	}, []);

	const validate = (values: IRating) => {
		const errors: FormikErrors<IRating> = {};
		if (!values.name) {
			errors.name = t('emptyField');
		}
		if (!values.minScore) {
			errors.minScore = t('emptyField');
		}
		return errors;
	};

	const onEditRating = (values: IRating, formikHelpers: FormikHelpers<IRating>) => {
		editRating(values)
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(AppRoutes.LIST_RATINGS);
			})
			.catch(({ response }) => {
				addToast({ message: response.data.message, error: true });
			}).finally(() => formikHelpers.setSubmitting(false));
	};

	return (
		<CreateRatingPage
			initialValues={initialValues}
			onValidate={validate}
			onEditRating={onEditRating}
			disable={id === '1'}
		/>
	);
};

export default CreateRating;
