import {
	Container, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

const PageNotFound = () => {
	const { t } = useTranslation(['translations']);
	return (
		<Container component="main" maxWidth="xl">
			<Typography variant="h1" component="h2">
				404 -
				{' '}
				{t('notFound')}
			</Typography>
		</Container>
	);
};

export default PageNotFound;
