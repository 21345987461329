import { useFeedback, useTitleBar } from '@Providers';
import { getOrdersCouponTransactions, getOrganizations, exportExcelCoupanReport } from '@Requests';
import { useRequest } from '@Api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICouponQuantityReport, ICouponQuantityReportReq } from 'data/models/transaction.model';
import { useEffectCustom } from '@Hooks';
import { IOrganization } from '@Types';
import CouponsQuantityReportPage from './CouponsQuantityReportPage';

const FIRST_DAY_OF_CURRENT_MONTH = new Date(new Date().setDate(1)); // get 1st day of the month

const CouponQuantityReport = () => {
	const { setTitle } = useTitleBar();
	const { t } = useTranslation('translations');
	const abortCtrl = new AbortController();
	const companies = useRequest(([params]) => getOrganizations(params, abortCtrl.signal));
	const [couponReport, setCouponReport] = useState([] as ICouponQuantityReport[]);
	const [loading, setLoading] = useState(false);

	const [couponRequest, setCouponRequest] = useState<ICouponQuantityReportReq>({
		startDate: FIRST_DAY_OF_CURRENT_MONTH,
		endDate: new Date(),
		organizationIds: [] as IOrganization[],
	});
	const { addDialog, addToast } = useFeedback();

	useEffect(() => {
		setTitle(`${t('couponSummary')}`);
	}, [setTitle, t]);

	useEffectCustom(() => {
		const getData = () => {
			const listDataControl = {
				page: 1,
				size: 10000,
				search: '',
			};
			companies.execute(listDataControl);
		};
		getData();
		return () => {
			abortCtrl.abort();
		};
	}, []);

	const handleChange = (key: string, value: Date | IOrganization[]) => {
		setCouponRequest({ ...couponRequest, [key]: value });
	};

	const handleFilterClick = () => {
		setLoading(true);
		getOrdersCouponTransactions(couponRequest)
			.then((res) => {
				setCouponReport(res.data);
			})
			.catch(({ response }) => {
				setCouponReport([]);
				addToast({ message: response.data.message, error: true });
			})
			.finally(() => setLoading(false));
	};

	const handleExportClick = () => {
		exportExcelCoupanReport(couponRequest).then((res) => {
			const url = URL.createObjectURL(res);
			const a = document.createElement('a');
			a.href = url;
			a.download = `${t('coupons')} - ${t('quantityPerType')} - ${new Date().getTime()}.xlsx`;
			document.body.appendChild(a);
			a.click();
			a.remove();
		}).catch((err) => {
			addDialog({
				title: err?.response?.data?.title,
				message: err?.response?.data?.message,
				error: true,
			});
		});
	};

	return (
		<CouponsQuantityReportPage
			couponReport={couponReport}
			organization={companies.data?.data}
			couponRequest={couponRequest}
			onChange={handleChange}
			onFilterClick={handleFilterClick}
			loading={loading}
			onExportClick={handleExportClick}
		/>
	);
};

export default CouponQuantityReport;
