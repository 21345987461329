import {
	IQuestion,
	IListDataControl,
	IQuestionReq,
	ApiResponse,
	IPagination,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';

export const getQuestions = async (props: IListDataControl):
   Promise<ApiResponse<IPagination<IQuestion[]>>> => {
	const { page, size, search } = props;

	const resp = await reqGet({ url: Endpoint.GET_QUESTIONS.format(page, size, search) });
	const data = resp.data as ApiResponse<IPagination<IQuestion[]>>;
	data.statusCode = resp.status;
	return data;
};

export const changeQuestionStatus = async (questionId: number): Promise<ApiResponse<IQuestion>> => {
	const resp = await reqPost({ url: Endpoint.CHANGE_QUESTION_STATUS.format(questionId) });
	const data = resp.data as ApiResponse<IQuestion>;
	data.statusCode = resp.status;
	return data;
};

export const createQuestion = async (question: IQuestionReq): Promise<ApiResponse<void>> => {
	const resp = await reqPost({ url: Endpoint.CREATE_QUESTION, data: { ...question } });
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};
export const editQuestion = async (questionId: string, question: IQuestionReq):
Promise<ApiResponse<void>> => {
	const resp = await reqPost({
		url: Endpoint.QUESTION_BY_ID.format(questionId),
		data: { ...question },
	});
	const data = resp.data as ApiResponse<void>;
	data.statusCode = resp.status;
	return data;
};

export const getQuestionById = async (questionId: string): Promise<ApiResponse<IQuestion>> => {
	const resp = await reqGet({
		url: Endpoint.QUESTION_BY_ID.format(questionId),
	});
	const data = resp.data as ApiResponse<IQuestion>;
	data.statusCode = resp.status;
	return data;
};
