import { ApiResponse } from '@Types';
import axios from 'axios';
import Endpoint from '../endpoints.api';

interface ConfirnEmail {
	email: string;
	name: string
}
const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
const CONFIG_HEADERS = {
	'Access-Control-Allow-Origin': BASE_URL,
	Lang: 'pt',
	Accept: 'application/json',
};

export const confirmEmail = async (Token?: string):
	Promise<ApiResponse<ConfirnEmail>> => {
	const resp = await axios({
		url: BASE_URL + Endpoint.CONFIRM_EMAIL.format(encodeURIComponent(Token ?? '')),
		method: 'GET',
		headers: CONFIG_HEADERS,
	});
	const data = resp.data as ApiResponse<ConfirnEmail>;
	data.statusCode = resp.status;
	return data;
};
