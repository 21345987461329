import * as React from 'react';
import Box from '@mui/material/Box';
import { Stepper as MuiStepper } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface StepperProps {
	steps: string[];
	activeStep: number
}

const Stepper: React.FC<StepperProps> = (props) => {
	const { steps, activeStep } = props;

	return (
		<Box sx={{ width: '100%' }}>
			<MuiStepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</MuiStepper>
		</Box>
	);
};

export default Stepper;
