import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getCouponRequests, deleteCouponRequest } from '@Requests';
import { ICouponRequest, IPagination, IListDataControl } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RequiredCouponsListPage from './RequiredCouponsListPage';

const RequestedCoupons = () => {
	const [coupons, setCoupons] = useState<IPagination<ICouponRequest[]>>();
	const [pagination, setPagination] = useState({ page: 1, size: 10 });
	const [search, setSearch] = useState('');

	const { setTitle, setGlobalLoading } = useTitleBar();
	const { t } = useTranslation('translations');
	const { addToast } = useFeedback();

	useEffect(() => {
		setTitle(t('couponRequests'));
	});

	const deleteCoupon = (couponUserId: number) => {
		setGlobalLoading(true);
		deleteCouponRequest(couponUserId)
			.then((res) => {
				addToast({ message: res.message, error: false });
				setCoupons((prev) => {
					const mPrev = prev;
					const index = coupons?.data?.findIndex((el) => el.id === res?.data?.id);
					if (index !== undefined) {
						mPrev?.data.splice(index, 1);
					}
					return mPrev;
				});
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setGlobalLoading(false));
	};

	useEffectCustom(() => {
		const getData = () => {
			setGlobalLoading(true);
			const listControl: IListDataControl = {
				page: pagination.page,
				search,
				size: pagination.size,
			};
			getCouponRequests(listControl)
				.then((res) => {
					setCoupons(res.data);
				})
				.catch(({ response }) => {
					addToast({ message: response.data.message, error: true });
				})
				.finally(() => setGlobalLoading(false));
		};
		getData();
	}, [search, pagination]);

	const handleListAction = (action: string, coupon?: ICouponRequest) => {
		switch (action) { // MOVE TO PARENT
		case t('undoReq'): {
			if (coupon?.id) {
				deleteCoupon(coupon.id);
			}
			break;
		}
		default: {
			break;
		}
		}
	};

	return (
		<RequiredCouponsListPage
			data={coupons}
			onPageChange={setPagination}
			onSearch={setSearch}
			handleListAction={handleListAction}
		/>
	);
};

export default RequestedCoupons;
