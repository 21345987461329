/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ApiResponse, IApiResponseBase } from '@Types';
import { AxiosError } from 'axios';
import { useTitleBar } from '@Providers';

// type CacheOptions = {
//    cache?: boolean;
//    cacheKey?: string;
// };

export const useRequest = <T>(request: (...params: any) =>
Promise<ApiResponse<T>>,
) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<T>();
	const [info, setInfo] = useState<IApiResponseBase>();
	const [error, setError] = useState<boolean>();
	const { setGlobalLoading } = useTitleBar();

	/**
    * Execute Api Request
    */

	const execute = async (...params: any) => {
		setGlobalLoading(true);
		setLoading(true);
		try {
			const response = await request(params);
			setData(response.data);
			setInfo(response as IApiResponseBase);
			setError(!!response.errorType);
			return !!response.errorType;
		} catch (err) {
			const axiosResponse = err as AxiosError;
			const resp = axiosResponse?.response?.data as IApiResponseBase;
			setInfo({
				message: resp.message, title: resp.title || '', errorType: resp.errorType, statusCode: axiosResponse.response?.status,
			});
			setError(true);
			return true;
		} finally {
			setLoading(false);
			setGlobalLoading(false);
		}
	};

	return {
		execute,
		loading,
		info,
		data,
		error,
		setData,
	};
};
