import { PaperContainer } from '@Molecules';
import { Grid, useTheme, Box } from '@mui/material';
import { TextField } from 'formik-mui';
import { IRating } from '@Types';
import {
	Formik, Form, Field, FormikHelpers, FormikErrors,
} from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { DefaultButton } from '@Atoms';

export interface CreateRatingPageProps {
   initialValues: IRating;
   onValidate: (values: IRating) => FormikErrors<IRating>;
   onEditRating: (values: IRating, formikHelpers: FormikHelpers<IRating>) => void;
   disable?: boolean
}

const CreateRatingPage = (props: CreateRatingPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const {
		initialValues, onValidate, onEditRating, disable,
	} = props;
	return (
		<Box>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xs={12} md={6} lg={4}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							validateOnMount={false}
							validateOnBlur={false}
							enableReinitialize
							validateOnChange={false}
							validate={onValidate}
							onSubmit={onEditRating}
						>
							{({ isSubmitting }) => (
								<Form autoComplete="off">
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Field component={TextField} name="name" label={t('title')} type="text" size="small" fullWidth />
										</Grid>
										<Grid item xs={12}>
											<Field disabled={disable} component={TextField} name="minScore" label={t('points')} type="number" size="small" fullWidth />
										</Grid>
										<Grid item xs={12} md={1}>
											<DefaultButton
												title={t('save')}
												loading={isSubmitting}
												type="submit"
											/>
										</Grid>
									</Grid>
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
				<Grid item xs={12} md={12} />
			</Grid>
		</Box>
	);
};

export default CreateRatingPage;
