import { useRequest } from '@Api';
import { getUserById, promoteToAdm } from '@Requests';
import { IUser } from '@Types';
import { AppRoutes } from '@Routes';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFeedback } from '@Providers';
import { useEffectCustom } from '@Hooks';
import PromoteToAdmPage, { ADMIN_ACTION } from './PromoteToAdmPage';

const PromoteToAdm = () => {
	const { id } = useParams<{ id: string }>();
	const { addToast } = useFeedback();

	const getUser = useRequest(([params]) => getUserById(params));
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	useEffectCustom(() => {
		if (id) {
			getUser.execute(id);
		}
	}, [id]);

	const getAdmin = (): IUser[] | [] | undefined => {
		if (getUser.data) {
			return [getUser.data];
		}
		return [];
	};

	const promoteToAdmin = () => {
		setLoading(true);
		promoteToAdm(id)
			.then((res) => {
				addToast({ message: res.message, error: false });
				navigate(AppRoutes.ADMINISTRATORS);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }))
			.finally(() => setLoading(false));
	};

	const onButtonClick = (action: string) => {
		switch (action) {
		case ADMIN_ACTION.YES:
			promoteToAdmin();
			break;
		case ADMIN_ACTION.NO:
			navigate(AppRoutes.LIST_USERS);
			break;
		default:
			break;
		}
	};

	return (
		<PromoteToAdmPage
			user={getAdmin()}
			handleClick={onButtonClick}
			loading={loading}
		/>
	);
};

export default PromoteToAdm;
