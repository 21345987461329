/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';

type Paddings = 'none' | 'normal' | 'checkbox'
type AlignTypes = 'inherit' | 'left' | 'center' | 'right' | 'justify'

export interface TableHeaderOptions {
   align?: AlignTypes;
   padding?: Paddings;
   render?: (index: number) => string | React.ReactNode;
}

export interface TableColumnProps {
   align?: AlignTypes;
   padding?: Paddings;
   render: (item: any) => string | React.ReactNode;
   footer?: string | React.ReactNode;
   headerText?: string;
   headerOptions?: TableHeaderOptions;
}

const defaultOptions = {
	align: 'inherit' as const,
	padding: 'normal' as Paddings,
	headerText: '',
	headerOptions: {
		align: 'inherit' as const,
		padding: 'normal' as Paddings,
	} as TableHeaderOptions,
};

const TableColumn: React.FC<TableColumnProps> = (props) => {
	props = { ...defaultOptions, ...props };
	return <></>;
};
export default TableColumn;
