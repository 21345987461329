import {
	DateLabel, DefaultButton, Row, Space,
} from '@Atoms';
import { PaperContainer } from '@Molecules';
import {
	Grid, Typography, useTheme,
} from '@mui/material';
import { DataTable, TableColumn } from '@Organisms';
import { IUser } from '@Types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export enum ADMIN_ACTION {
   YES = 'YES',
   NO = 'NO',
}

interface PromoteToAdmProps {
   user?: IUser[] | [];
   handleClick: (action: string) => void;
   loading?: boolean
}

const PromoteToAdmPage: FC<PromoteToAdmProps> = (props) => {
	const { user, handleClick, loading } = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<div>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xs={12} md={12}>
					<PaperContainer>
						<DataTable data={user || []} search={false}>
							<TableColumn headerText="ID" render={(item) => item.id} />
							<TableColumn headerText={t('name')} render={(item) => item.name} />
							<TableColumn headerText={t('email')} render={(item) => item.email} />
							<TableColumn headerText={t('contactNumber')} render={(item) => item.phone} />
							<TableColumn headerText={t('registration')} render={(item) => <DateLabel date={item.registration} showTime />} />
						</DataTable>
						<Space height={2} />
						<Typography sx={{ textAlign: 'center' }}>
							{t('adminQuestionStart')}
							{' '}
							{t('adminQuestionEnd')}
							?
						</Typography>
						<Space height={2} />
						<Row justify="center">
							<DefaultButton title={t('yes')} loading={loading} onClick={() => handleClick(ADMIN_ACTION.YES)} />
							<DefaultButton sx={{ marginLeft: theme.spacing(3), textTransform: 'none' }} disabled={loading} title={t('no')} color="secondary" onClick={() => handleClick(ADMIN_ACTION.NO)} />
						</Row>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default PromoteToAdmPage;
