import { Grid, useTheme } from '@mui/material';
import { SummaryCard } from '@Molecules';
import { IDashboardSummary } from '@Types';
import { useTranslation } from 'react-i18next';
import React from 'react';

export interface DashboardPageProps {
   data?: IDashboardSummary;
}

const DashboardPage = (props: DashboardPageProps) => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const { data } = props;
	return (
		<div>
			<Grid
				sx={{ padding: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12}>
					<Grid container rowSpacing={3} columnSpacing={3}>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('totalUsers')} value={data?.users.total} badge={t('total')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('activeUsers')} value={data?.users.active} badge={t('active')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('inactiveUsers')} value={data?.users.inactive} badge={t('inActive')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('totalOrganizations')} value={data?.organizations.total} badge={t('total')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('activeOrganizations')} value={data?.organizations.active} badge={t('active')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('inactiveOrganizations')} value={data?.organizations.inactive} badge={t('inActive')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('totalAdministrators')} value={data?.administrators.total} badge={t('total')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('activeAdministrators')} value={data?.administrators.active} badge={t('active')} />
						</Grid>
						<Grid item xs={12} md={12} xl={4} lg={4}>
							<SummaryCard title={t('inactiveAdministrators')} value={data?.administrators.inactive} badge={t('inActive')} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default DashboardPage;
