export const LANGUAGES = [
	{
		id: 2,
		isoCode: '620',
		name: 'Portuguese',
		phoneCode: '+351',
		shortName: 'PT',
		language: 'pt',
	},
	{
		id: 1, isoCode: '620', name: 'English', phoneCode: '44', shortName: 'GB', language: 'en',
	},
];
