import { ApiResponse, IDashboardSummary } from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet } from '../request.api';

export const getDashboardData = async (signal: AbortSignal):
	Promise<ApiResponse<IDashboardSummary>> => {
	const resp = await reqGet({
		url: Endpoint.DASHBOARD_SUMMARY,
		signal,
	});
	const data = resp.data as ApiResponse<IDashboardSummary>;
	data.statusCode = resp?.status;
	return data;
};
