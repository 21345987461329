/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ApiResponse, IListDataControl, IPagination, ITicket, ITicketMessageReq,
} from '@Types';
import Endpoint from '../endpoints.api';
import { reqGet, reqPost } from '../request.api';
import { uploadFile } from './file.service';

export const getAllTickets = async (params: IListDataControl):
	Promise<ApiResponse<IPagination<ITicket[]>>> => {
	const { page, size, search } = params;
	const resp = await reqGet({
		url: Endpoint.TICKETS.format(page, size, search),
	});
	const data = resp.data as ApiResponse<IPagination<ITicket[]>>;
	data.statusCode = resp.status;
	return data;
};

export const getTicketById = async (ticketId: string): Promise<ApiResponse<ITicket>> => {
	const resp = await reqGet({
		url: Endpoint.TICKET_BY_ID.format(ticketId),
	});
	const data = resp.data as ApiResponse<ITicket>;
	data.statusCode = resp.status;
	return data;
};

export const addTicketMessage = async (message: ITicketMessageReq, ticketId: string):
	Promise<ApiResponse<ITicket>> => {
	const { fileAttachment, fileAttachmentDescription } = { ...message };
	let dataToUpload: any = { message: message.message };
	if (fileAttachment) {
		if (fileAttachment.size) {
			const data: any = new FormData();
			data.append('file', fileAttachment);
			const res = await uploadFile(data);
			dataToUpload = { ...dataToUpload, fileAttachment: res.data.file };
			dataToUpload = { ...dataToUpload, fileAttachmentDescription };
		}
	}
	const resp = await reqPost({
		url: Endpoint.ADD_TICKET_MESSAGE.format(ticketId),
		data: { ...dataToUpload },
	});
	const data = resp.data as ApiResponse<ITicket>;
	data.statusCode = resp.status;
	return data;
};

export const changeTicketStatus = async (statusId: number, ticketId: string):
	Promise<ApiResponse<ITicket>> => {
	const resp = await reqPost({
		url: Endpoint.CHANGE_TICKET_STATUS.format(ticketId),
		data: { statusId },
	});
	const data = resp.data as ApiResponse<ITicket>;
	data.statusCode = resp.status;
	return data;
};
