import React from 'react';

export const printReport = (printRef: React.RefObject<HTMLDivElement>) => {
	const w = window.open();
	if (printRef.current?.innerHTML && w) {
		w.document.write(printRef.current.innerHTML);
		const tables = Array.from(w.document.getElementsByTagName('table'));
		tables?.forEach((mTable) => {
			const table = mTable;
			table.style.border = '1px solid #000000';
			table.style.borderSpacing = '0';
			table.style.width = '100%';
			const rows = Array.from(w.document.getElementsByTagName('td'));
			rows.forEach((mRow) => {
				const row = mRow;
				row.style.border = '1px solid #999999';
			});
		});
		w?.print();
	}
	w?.close();
};
