/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
import { useRequest } from '@Api';
import { useEffectCustom } from '@Hooks';
import { useFeedback, useTitleBar } from '@Providers';
import { getOrganizations, changeOrganizationStatus, getOrganizationBarcode } from '@Requests';
import { AppRoutes } from '@Routes';
import { IListDataControl, IOrganization, IPaginationTable } from '@Types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ListOrganizationsPage from './ListOrganizationsPage';

const ListOrganizations = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { setTitle, setGlobalLoading } = useTitleBar();
	const { addToast } = useFeedback();
	const location = useLocation();
	const [searchParam, setSearchParam] = useSearchParams();

	const organizations = useRequest(([params, signal]) => getOrganizations(params, signal));
	const organizationStatus = useRequest(([params]) => changeOrganizationStatus(params));

	const [pagination, setPagination] = useState({
		page: parseInt(new URLSearchParams(location.search).get('page') || '1', 10),
		size: parseInt(new URLSearchParams(location.search).get('size') || '10', 10),
	});
	const [search, setSearch] = useState(new URLSearchParams(location.search).get('search') || '');

	useEffect(() => {
		setTitle(t('organizations'));
	}, [setTitle, t]);

	useEffectCustom(() => {
		const abortCtrl = new AbortController();
		const getData = () => {
			const listDataControl: IListDataControl = {
				page: pagination.page,
				size: pagination.size,
				search,
			};
			organizations.execute(listDataControl, abortCtrl.signal);
		};
		getData();
		return () => abortCtrl.abort();
	}, [search, pagination]);

	useEffect(() => {
		if (organizationStatus.data) {
			const index = organizations.data?.data.findIndex(
				(el) => el.id === organizationStatus.data?.id,
			);
			organizations.setData((prev) => {
				const mPrev = prev;
				if (mPrev) {
					if (index !== undefined && organizationStatus.data) {
						mPrev.data[index] = organizationStatus.data;
					}
				}
				return mPrev;
			});
		}
	}, [organizationStatus.data, organizations]);

	function base64toBlob(base64Data: string) {
		const sliceSize = 1024;
		const byteCharacters = atob(base64Data);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);

		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);

			const bytes = new Array(end - begin);
			for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}
		return new Blob(byteArrays, { type: 'image/png' });
	}

	const getBarcode = (organizationId?: number) => {
		setGlobalLoading(true);
		getOrganizationBarcode(organizationId ?? 0)
			.then(({ data }) => {
				const blob = base64toBlob(data.qrCode);
				if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
					(window.navigator as any).msSaveOrOpenBlob(blob, 'imgBase64.png');
				} else {
					const blobUrl = URL.createObjectURL(blob);
					window.open(blobUrl);
				}
				setGlobalLoading(false);
			})
			.catch(({ response }) => addToast({ message: response.data.message, error: true }));
	};

	const handleListAction = (action: string, item?: IOrganization) => {
		switch (action) {
		case t('new'):
			navigate(AppRoutes.CREATE_ORGANIZATION);
			break;
		case t('details'):
			navigate(AppRoutes.EDIT_ORGANIZATION.formatMap({ id: item?.id }));
			break;
		case t('changeStatus'):
			organizationStatus.execute(item?.id);
			break;
		case t('services'):
			navigate(AppRoutes.SERVICES_ORGANIZATION.formatMap({ organizationId: item?.id }));
			break;
		case t('costCenter'):
			navigate(AppRoutes.COST_CENTERS_ORGANIZATION.formatMap({ organizationId: item?.id }));
			break;
		case t('qrCode'):
			getBarcode(item?.id);
			break;

		default:
			break;
		}
	};
	const setTableSearch = (searchQuery: string) => {
		setSearch(searchQuery);
		searchParam.set('search', searchQuery);
		setSearchParam(searchParam);
	};

	const setTablePagination = (paginationTable: IPaginationTable) => {
		setPagination(paginationTable);
		searchParam.set('page', paginationTable.page.toString());
		searchParam.set('size', paginationTable.size.toString());
		setSearchParam(searchParam);
	};

	return (
		<ListOrganizationsPage
			organizations={organizations.data}
			setPagination={setTablePagination}
			setSearch={setTableSearch}
			handleListAction={handleListAction}
		/>
	);
};

export default ListOrganizations;
