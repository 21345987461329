import { Divider, Grid, useTheme } from '@mui/material';
import React from 'react';
import { PaperContainer } from '@Molecules';
import {
	Form, Formik, FormikErrors, FormikHelpers,
} from 'formik';
import {
	Autocomplete,
	DefaultButton,
	DefaultSelect,
	Space,
	TextField,
} from '@Atoms';
import { useTranslation } from 'react-i18next';
import { Save, ArrowBack, Edit } from '@mui/icons-material';
import { IOrganization, IServiceReq } from '@Types';
import { currency, acceptNumbers } from '@Masks';

export enum PRIZE_TYPE {
	ABSOLUTE = 'ABSOLUTE',
	PERCENTAGE = 'PERCENTAGE',
}

interface CreateServiceProps {
	organizations?: IOrganization[];
	reward: {name: string, id: string}[];
	initialValues: IServiceReq;
	validate: (values: IServiceReq) => FormikErrors<IServiceReq>;
	onSubmit: (values: IServiceReq, helpers: FormikHelpers<IServiceReq>) => void;
	isEdit: boolean;
	handleEditClick: () => void;
handleBackClick: () => void
}

const CreateServicePage: React.FC<CreateServiceProps> = (props) => {
	const {
		organizations,
		reward,
		initialValues,
		validate,
		onSubmit,
		isEdit,
		handleEditClick,
		handleBackClick,
	} = props;
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const REWARD_OPTIONS = [
		{ name: t('absoluteValue'), id: PRIZE_TYPE.ABSOLUTE }, { name: t('percentageValue'), id: PRIZE_TYPE.PERCENTAGE },
	];
	return (
		<div>
			<Grid
				sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
				container
				spacing={3}
			>
				<Grid item xs={12} md={12} />
				<Grid item xs={12} md={12}>
					<DefaultButton title={t('back')} startIcon={<ArrowBack />} onClick={handleBackClick} />
				</Grid>
				<Grid item xs={12} md={9}>
					<PaperContainer>
						<Formik
							initialValues={initialValues}
							validate={validate}
							onSubmit={onSubmit}
							enableReinitialize
						>
							{({
								values, handleChange, errors, setFieldValue, isSubmitting,
							}) => (
								<Form>
									<Grid container spacing={3}>
										<Grid item xs={12} md={8}>
											<TextField
												label={t('name')}
												disabled={isSubmitting || !isEdit}
												name="name"
												onChange={handleChange}
												inputProps={{ maxLength: 35 }}
												value={values.name}
												error={!!errors.name}
												helperText={errors.name}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<TextField
												label={t('baseValue')}
												disabled={isSubmitting || !isEdit}
												name="cost"
												onChange={(event) => setFieldValue('cost', currency(event).target.value)}
												value={values.cost}
												error={!!errors.cost}
												helperText={errors.cost}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={3}>
										<Grid item xs={12} md={2}>
											<TextField
												label={t('reward')}
												disabled={isSubmitting || !isEdit}
												name="prize"
												onChange={(event) => setFieldValue('prize', values.prizeType?.id === PRIZE_TYPE.ABSOLUTE ? currency(event).target.value : acceptNumbers(event).target.value)}
												value={values.prize}
												error={!!errors.prize}
												helperText={errors.prize}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<DefaultSelect
												options={reward || []}
												disabled={isSubmitting || !isEdit}
												renderItem={(item) => ({ optionValue: item.id, optionLabel: item.name })}
												label={t('typeOfReward')}
												value={values.prizeType?.id}
												onChange={(event) => {
													const mReward = REWARD_OPTIONS.find((el) => el.id === event.target.value);
													setFieldValue('prizeType', mReward);
												}}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={3}>
										<Grid item xs={12} md={12}>
											<TextField
												label={t('description')}
												multiline
												disabled={isSubmitting || !isEdit}
												minRows={4}
												name="description"
												onChange={handleChange}
												value={values.description}
												error={!!errors.description}
												helperText={errors.description}
											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Grid container spacing={3}>
										<Grid item xs={12} md={8}>
											<Autocomplete
												label={t('belongingOrganization')}
												disabled={isSubmitting || !isEdit}
												placeholder=""
												multiple
												options={organizations || []}
												renderOption={(inputprops, item) => (
													<li {...inputprops} key={item.id}>
														{item.officialName}
													</li>
												)}
												getOptionLabel={(item) => item.officialName}
												defaultValue={values.organizations}
												onChange={(_, items) => {
													if (items) {
														setFieldValue('organizations', items);
													}
												}}

											/>
										</Grid>
									</Grid>
									<Space height={2} />
									<Divider />
									<Space height={2} />
									{isEdit && <DefaultButton startIcon={<Save />} title={t('Save')} type="submit" loading={isSubmitting} />}
									{!isEdit && <DefaultButton startIcon={<Edit />} title={t('edit')} onClick={handleEditClick} />}
								</Form>
							)}
						</Formik>
					</PaperContainer>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateServicePage;
